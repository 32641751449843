import React from 'react'
import Layout from '../components/Layouts/Layout.jsx'
import Modal from 'react-modal'
import AxiosConfigs from '../configs.js'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'

const NewGroups = () => {
    const navigate = useNavigate()

    //-------------------------------------------------------------------------------------------------------------------------

    const [modalIsOpen2, setIsOpen2] = React.useState(false)

    function openModal2() {
        setIsOpen2(true)
    }

    function afterOpenModal2() {
        // references are now sync'd and can be accessed.
    }

    function closeModal2() {
        setIsOpen2(false)
    }

    //===============================================================================================================================================================
    const [usuarios, setUsuarios] = useState([])
    const [search, setSearch] = useState('')

    const filteredUsuarios = search.length > 0 ? usuarios.filter((cliente) => cliente.name && cliente.name.toLowerCase().includes(search.toLowerCase())) : usuarios

    //PESQUISA USUARIOS
    useEffect(() => {
        AxiosConfigs.get('/users')
            .then((response) => {
                setUsuarios(response.data)
            })
            .catch((error) => {
                console.error('There was an error fetching the data!', error)
            })
    }, [])

    const [, setValorNovoNome] = useState('')

    //=============================================================================================================================================================================================
    const [tenants, setTenants] = useState([])
    const [searchTenant, setSearchTenant] = useState('')

    useEffect(() => {
        const tenants = JSON.parse(localStorage.getItem('tenants'))
        if (tenants) {
            setTenants(tenants)
        }
    }, [])

    const filteredTenants = searchTenant.length > 0 ? tenants.filter((cliente) => cliente.name && cliente.name.toLowerCase().includes(searchTenant.toLowerCase())) : tenants

    //==============================================================================================================================================================================================

    const [dadosDoNovoGrupo, setDadosDoNovoGrupo] = useState({
        group_name: '',
        emails: [],
        tenants: [],
        report_hour: ''
    })(dadosDoNovoGrupo.tenants)

    const [selectedUsers, setSelectedUsers] = useState([]) // Alteração aqui

    const grupo = {
        group_name: dadosDoNovoGrupo.group_name,
        emails: selectedUsers.map((user) => ({ email: user.email, name: user.name })),
        tenants: JSON.stringify(dadosDoNovoGrupo.tenants), // Convertendo para string JSON
        report_hour: dadosDoNovoGrupo.report_hour
    }
    const [loading, setLoading] = useState([])
    const loadSpinner = (color, size, key) => (
        <div
            key={key}
            className={loading[0] ? '' : 'hidden'}>
            <svg
                className={`animate-spin ${size} ${color}`}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                />
                <path
                    className="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"
                />
            </svg>
        </div>
    )
    const criarGrupo = () => {
        setLoading([true, 'createGroup'])
        AxiosConfigs.post('/groups', grupo)
            .then((response) => {
                // setUsuarios(response.data)
                setLoading([false, 'createGroup'])
                toast.success('Grupo criado!')(response.data)
                window.location.reload()
            })
            .catch((error) => {
                console.error('There was an error fetching the data!', error)
            })
    }

    const [isAllTenantChecked, setIsAllTenantChecked] = useState(false)
    // Função para verificar se todos os checkboxes estão selecionados
    const allTenantsIsChecked = () => {
        if (isAllTenantChecked) {
            // Desmarcar todos
            dadosDoNovoGrupo.tenants = []
        } else {
            // Marcar todos
            dadosDoNovoGrupo.tenants = tenants.map((tenant) => tenant.id)
        }
        setIsAllTenantChecked(!isAllTenantChecked)
    }
    const allIsCheckedTenants = () => {
        setIsAllUsersChecked(!isAllUsersChecked)
    }
    // Função para selecionar/desselecionar um tenant
    const selectTenants = (tenant) => {
        if (dadosDoNovoGrupo.tenants.includes(tenant.id)) {
            dadosDoNovoGrupo.tenants = dadosDoNovoGrupo.tenants.filter((id) => id !== tenant.id)
        } else {
            dadosDoNovoGrupo.tenants.push(tenant.id)
        }
        setIsAllTenantChecked(dadosDoNovoGrupo.tenants.length === tenants.length)
    }

    const [isAllUsersChecked, setIsAllUsersChecked] = useState(false)

    // (selectedUsers)
    // Atualiza o estado do checkbox "Marcar todos" e os emails do `dadosDoNovoGrupo`
    const allUsersIsChecked = () => {
        const allChecked = !isAllUsersChecked
        setIsAllUsersChecked(allChecked)
        setSelectedUsers(allChecked ? usuarios.map((cliente) => ({ email: cliente.email, name: cliente.name })) : [])
    }

    // Seleciona ou desmarca um usuário individualmente
    const selectUsers = (cliente) => {
        const foundIndex = selectedUsers.findIndex((user) => user.email === cliente.email)
        if (foundIndex !== -1) {
            const newSelectedUsers = [...selectedUsers]
            newSelectedUsers.splice(foundIndex, 1)
            setSelectedUsers(newSelectedUsers)
        } else {
            setSelectedUsers([...selectedUsers, { email: cliente.email, name: cliente.name }])
        }
    }

    // Atualiza o estado do checkbox "Marcar todos" quando `selectedEmails` muda
    useEffect(() => {
        setIsAllUsersChecked(selectedUsers.length === usuarios.length)
    }, [selectedUsers, usuarios])

    // Atualiza o estado `dadosDoNovoGrupo.emails` e `dadosDoNovoGrupo.selectedNames` quando `selectedUsers` muda
    useEffect(() => {
        setDadosDoNovoGrupo((prevState) => ({
            ...prevState,
            emails: selectedUsers.map((user) => user.email),
            selectedNames: selectedUsers.map((user) => user.name) // Adição aqui
        }))
    }, [selectedUsers])

    //=================================================================================================================================================

    return (
        <Layout active="groups">
            <div className="h-full p-6">
                <div className="p-1 pb-4">
                    <p className=" font-sans text-md font-medium tracking-tight">Cadastrar novo grupo</p>
                    <form className="flex items-center justify-start rounded-lg bg-white size-full p-3 shadow-lg">
                        <div className="w-4/12">
                            <label
                                className="block tracking-tighter font-medium"
                                htmlFor="name">
                                Nome do grupo
                            </label>
                            <input
                                className="p-2 w-3/6 font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                                id="name"
                                required={true}
                                type="text"
                                onChange={(e) => {
                                    setDadosDoNovoGrupo({
                                        ...dadosDoNovoGrupo,
                                        group_name: e.target.value
                                    })
                                }}
                            />
                        </div>

                        <div className="w-4/12">
                            <label
                                className="block tracking-tighter font-medium"
                                htmlFor="email">
                                Notificações as
                            </label>
                            <input
                                className=" w-3/6 p-2 font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                                id="reporthour"
                                required={true}
                                type="time"
                                onChange={(e) => {
                                    setDadosDoNovoGrupo({
                                        ...dadosDoNovoGrupo,
                                        report_hour: e.target.value
                                    })
                                }}
                            />
                        </div>
                        <div>
                            <button
                                className="text-nowrap shadow-md shadow-blue-200  p-2 ml-56 bg-[#35208f] text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                                type="button"
                                onClick={openModal2}>
                                Adicionar usuario
                            </button>
                        </div>
                    </form>
                </div>

                <div className="h-[calc(80%-40px)] grid grid-rows-12 ">
                    <div className="row-span-10  size-full ">
                        <div className="rounded-lg bg-white h-full ">
                            <div className="h-full overflow-auto border rounded-lg border-gray-300 shadow-md ">
                                <table className="table-auto w-full">
                                    <thead className="border-b-2 border-black h-10 -top-0 sticky bg-white ">
                                        <tr className="px-2 text-start">
                                            <th
                                                className="text-start px-2"
                                                onClick={() => {
                                                    allTenantsIsChecked()
                                                }}>
                                                <p className="flex flex-row items-center">
                                                    {/* eslint-disable-next-line react/jsx-max-depth */}
                                                    <input
                                                        checked={isAllTenantChecked}
                                                        className="size-4 mx-auto cursor-pointer"
                                                        id="selectall"
                                                        type="checkbox"
                                                        onChange={allTenantsIsChecked}
                                                    />
                                                </p>
                                            </th>
                                            <th className="text-start px-2 ">
                                                {/* eslint-disable-next-line react/jsx-max-depth */}
                                                <label
                                                    className="cursor-pointer"
                                                    htmlFor="selectall">
                                                    Adicionar todos
                                                </label>
                                            </th>
                                            <th className="text-start px-2">
                                                <div className="flex items-center justify-end sticky top mr-6">
                                                    {/* eslint-disable-next-line react/jsx-max-depth */}
                                                    <input
                                                        className=" px-2 rounded-md font-thin border border-gray-500"
                                                        name="search"
                                                        placeholder="Pesquisar"
                                                        type="text"
                                                        value={searchTenant}
                                                        onChange={(e) => setSearchTenant(e.target.value)}
                                                    />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-md">
                                        {filteredTenants
                                            .filter((group) => group.name !== null) // Filtrar usuários com nome não nulo
                                            .map((tenant, index) => (
                                                <tr
                                                    key={index}
                                                    className="h-12 border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight"
                                                    onClick={() => {
                                                        // document.getElementById(tenant.name).checked = !document.getElementById(tenant.name).checked
                                                    }}>
                                                    <th
                                                        className="text-start px-2"
                                                        onClick={() => {
                                                            allIsCheckedTenants()
                                                        }}>
                                                        <p className="flex flex-row items-center">
                                                            <input
                                                                key={index}
                                                                checked={dadosDoNovoGrupo.tenants.includes(tenant.id)}
                                                                className="size-4 mx-auto cursor-pointer"
                                                                id={tenant.name}
                                                                type="checkbox"
                                                                onChange={() => selectTenants(tenant)}
                                                            />
                                                        </p>
                                                    </th>
                                                    <td className="px-2">
                                                        <label
                                                            className="w-full cursor-pointer"
                                                            htmlFor={tenant.name}>
                                                            {tenant.name}
                                                        </label>
                                                    </td>
                                                    <td className="px-2 space-x-3" />
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row-start-12 flex items-center justify-between">
                        <button
                            className="ml-16 p-2 shadow-md shadow-rose-200  flex bg-rose-600 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-rose-700"
                            type="submit"
                            onClick={() => {
                                navigate('/groups')
                            }}>
                            Voltar
                        </button>

                        <button
                            className=" mr-16 p-2 shadow-md shadow-blue-200  flex bg-[#35208f] text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                            type="submit"
                            onClick={() => {
                                if (dadosDoNovoGrupo.group_name.trim() === '') {
                                    toast.error('Preencha o nome do grupo')
                                } else if (dadosDoNovoGrupo.report_hour.trim() === '') {
                                    toast.error('Preencha o horario das notificações')
                                } else if (dadosDoNovoGrupo.emails.length === 0) {
                                    toast.error('Selecione um usuário')
                                } else if (dadosDoNovoGrupo.tenants.length === 0) {
                                    toast.error('Selecione um cliente')
                                } else {
                                    criarGrupo()
                                }
                            }}>
                            Cadastrar
                            {loading[0] && loading[1] === 'createGroup' ? loadSpinner('text-white', 'size-6', 'create') : null}
                        </button>
                    </div>
                </div>

                <Modal
                    ariaHideApp={false}
                    className="grid grid-rows-12 border rounded-lg border-blue-700 w-10/12 h-5/6 absolute top-2/4 left-2/4 right-auto bottom-auto -mr-2/4 -translate-x-2/4 -translate-y-2/4 bg-white shadow-lg shadow-blue-200"
                    contentLabel="Example Modal"
                    isOpen={modalIsOpen2}
                    onAfterOpen={afterOpenModal2}
                    onRequestClose={closeModal2}>
                    <div className=" rounded-t-lg mt-5  row-start-1 row-end-11 ">
                        <div className="flex items-center justify-end mr-8 place-content-center mb-3">
                            <input
                                className="h-6 px-2 rounded-md font-thin border border-gray-500"
                                name="search"
                                placeholder="Pesquisar"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <div className="overflow-auto size-full border-2 ">
                            <table className="table-auto w-full ">
                                <thead className="sticky top-0 bg-white">
                                    <tr className="px-2 text-start border-b-2 border-black ">
                                        <th
                                            className="text-start px-2"
                                            onClick={allUsersIsChecked}>
                                            <p className="flex flex-row items-center">
                                                {/* eslint-disable-next-line react/jsx-max-depth */}
                                                <input
                                                    checked={isAllUsersChecked}
                                                    className="size-4 mx-auto"
                                                    id="selctallusers"
                                                    type="checkbox"
                                                    onChange={allUsersIsChecked}
                                                />
                                            </p>
                                        </th>
                                        <th className="text-start h-12 px-2">
                                            <label
                                                className="cursor-pointer"
                                                htmlFor="selctallusers">
                                                Marcar todos
                                            </label>
                                        </th>
                                        <th className="text-start px-2">Email</th>
                                        <th className="text-start px-2">Ultimo acesso</th>
                                        <th className="text-start px-2" />
                                    </tr>
                                </thead>

                                <tbody className="w-full">
                                    {filteredUsuarios
                                        .filter((cliente) => cliente.name !== null) // Filtrar usuários com nome não nulo
                                        .sort((a, b) => a.name.localeCompare(b.name)) // Ordenar usuários pelo nome
                                        .map((cliente, index) => (
                                            <tr
                                                key={index}
                                                className="h-12 border  even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight">
                                                <td
                                                    className="text-start px-2"
                                                    onClick={() => selectUsers(cliente)}>
                                                    <p className="flex flex-row items-center">
                                                        <input
                                                            checked={selectedUsers.some((user) => user.email === cliente.email)} // Correção aqui
                                                            className="size-4 mx-auto"
                                                            id={cliente.id}
                                                            type="checkbox"
                                                            onChange={() => selectUsers(cliente)}
                                                        />
                                                    </p>
                                                </td>
                                                <td className="px-2 w-2/5">
                                                    <label
                                                        className="cursor-pointer"
                                                        htmlFor={cliente.id}>
                                                        {cliente.name}
                                                    </label>
                                                </td>
                                                <td className="px-2">{cliente.email}</td>
                                                <td className="px-2 ">{cliente.last_login ?? '-'}</td>
                                                <td className="px-2 space-x-3" />
                                                <td className="px-2" />
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className=" row-start-12 rounded-md flex items-center ">
                        <div className=" w-full flex items-center justify-between">
                            <div>
                                <button
                                    className="p-1.5 w-16 ml-10 tracking-tight font-medium rounded-lg duration-300 bg-rose-600 text-white shadow-md shadow-rose-200 hover:bg-rose-700 hover:shadow-md"
                                    type="button"
                                    onClick={() => {
                                        setValorNovoNome('')
                                        closeModal2()
                                        setDadosDoNovoGrupo({
                                            ...dadosDoNovoGrupo, // Mantém os outros campos inalterados
                                            emails: []
                                            // Zera os emails
                                        })
                                    }}>
                                    Voltar
                                </button>
                            </div>

                            <div>
                                <button
                                    className="p-2  mr-10 tracking-tight font-medium rounded-lg duration-300 bg-[#35208f] text-white shadow-md shadow-blue-200 hover:bg-blue-950 hover:shadow-md"
                                    type="button"
                                    onClick={() => {
                                        closeModal2()
                                    }}>
                                    Próximo
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </Layout>
    )
}

export default NewGroups
