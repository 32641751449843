import React from 'react'
import Layout from '../components/Layouts/Layout.jsx'
import { ExclamationTriangleIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline/index.js'
import Modal from 'react-modal'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AxiosConfigs from '../configs.js'
import { toast } from 'react-hot-toast'
import PermissoesContext from '../context/PermisoesContext.jsx'
import { useContext } from 'react'

const Groups = () => {
  const { permissoes }             = useContext(PermissoesContext)
  const navigate                   = useNavigate()
  const [togLoad, setTogLoad]      = useState(true)
  const [modalIsOpen2, setIsOpen2] = React.useState(false)

  function openModal2 () {
    setIsOpen2(true)
  }

  function afterOpenModal2 () {}

  function closeModal2 () {
    setIsOpen2(false)
  }

  //===============================================================================================================================================================
  const [usuarios, setUsuarios] = useState([])
  const [search]                = useState('')

  const filteredUsuarios = search.length > 0 ? usuarios.filter((cliente) => cliente.name && cliente.name.toLowerCase().includes(search.toLowerCase())) : usuarios

  //PESQUISA USUARIOS
  useEffect(() => {
    AxiosConfigs.get('/users').then((response) => {
      setUsuarios(response.data)
    }).catch((error) => {
      toast.error('There was an error fetching the data! ' + error)
    })
  }, [])

  //=============================================================================================================================================================================================
  const [tenants, setTenants] = useState([])
  const searchTenant          = ''

  useEffect(() => {
    AxiosConfigs.get('/tenants').then((response) => {
      setTenants(response.data)
      setTogLoad(false)
    }).catch(function (error) {
      error
    })
  }, [])

  const namedTenant                                       = tenants.filter((tenant) => Object.prototype.hasOwnProperty.call(tenant, 'name'))
  const filteredTenants                                   = searchTenant.length > 0
    ? namedTenant.filter((cliente) => cliente.name && cliente.name.toLowerCase().includes(searchTenant.toLowerCase()))
    : namedTenant
  //==============================================================================================================================================================================================
  const [selectedUsers, setSelectedUsers]                 = useState([])
  const [valorEditarNome, setEditarNome]                  = useState('')
  const [valorEditarReportHour, setValorEditarReportHour] = useState('')
  const [valorEditarId, setValorEditarId]                 = useState('')
  const [valorEditarGroupId, setValorEditarGroupId]       = useState('')
  const [dadosDoNovoGrupo, setDadosDoNovoGrupo]           = useState({
    // id: '',
    name:        '',
    report_hour: '',
    tenants:     [],
    users:       [
      {
        id:   '',
        name: '',
      },
    ],
  })

  const [tenantsGroup, setTenantsGroup] = useState([])
  const atualizaTenants                 = () => {
    //prevTenantsGroup é o estado anterior de tenantsGroup
    setTenantsGroup((prevTenantsGroup) => {
      //mapeia prevTenantsGroup para extrair apenas os IDs dos (tenant)
      const tenantIds = prevTenantsGroup.map((ten) => ten.tenant)
      // Atualiza o estado de dadosDoNovoGrupo, mantendo o estado anterior (prevState) e substituindo o campo tenants pelo array de tenantIds
      setDadosDoNovoGrupo((prevState) => ({ ...prevState, tenants: tenantIds }))
      // Garante que o valor de tenantsGroup está atualizado
      return prevTenantsGroup
    })
  }

  useEffect(() => {
    atualizaTenants()
  }, [tenantsGroup])

  const [isAllTenantChecked, setIsAllTenantChecked] = useState(false)
  const allTenantsIsChecked                         = () => {
    if (isAllTenantChecked) {
      //se todos os tenants estão marcados, desmarcar todos
      setDadosDoNovoGrupo((prevState) => ({
        ...prevState,
        // Define tenants como um array vazio para desmarcar todos
        tenants: [],
      }))
    } else {
      //se não todos estão marcados, marcar todos
      setDadosDoNovoGrupo((prevState) => ({
        ...prevState,
        //define tenants com os IDs de todos para marcá-los
        tenants: tenants.map((tenant) => tenant.id),
      }))
    }
    //inverte o estado de isAllTenantChecked
    setIsAllTenantChecked(!isAllTenantChecked)
  }

  const allIsCheckedTenants = () => {
    setIsAllUsersChecked(!isAllUsersChecked)
  }
  const selectTenants       = (tenant) => {
    setDadosDoNovoGrupo((prevState) => {
      // Cria uma cópia atualizada do array de tenants do estado anterior
      const updatedTenants = Array.isArray(prevState.tenants) ? [...prevState.tenants] : []
      if (updatedTenants.includes(tenant.id)) {
        // Remove o tenant se já estiver selecionado
        return {
          ...prevState,
          tenants: updatedTenants.filter((id) => id !== tenant.id),
        }
      } else {
        // Adiciona o tenant se não estiver selecionado
        updatedTenants.push(tenant.id)
        return {
          ...prevState,
          tenants: updatedTenants,
        }
      }
    })
    //Verifica se todos os tenants estão marcados após a atualização
    setIsAllTenantChecked(dadosDoNovoGrupo.tenants.length === tenants.length)
  }
  //=====================================================================================================================================
  selectedUsers
  const [isAllUsersChecked, setIsAllUsersChecked] = useState(false)
  const allUsersIsChecked                         = () => {
    // Inverte o estado de isAllUsersChecked
    const allChecked = !isAllUsersChecked
    // Atualiza o estado isAllUsersChecked
    setIsAllUsersChecked(allChecked)
    // Define os usuários selecionados com base no estado invertido
    setSelectedUsers(allChecked ? filteredUsuarios.map((cliente) => ({ id: cliente.id, email: cliente.email, name: cliente.name })) : [])
  }

  const selectUsers = (cliente) => {
    // Encontra o índice do cliente selecionado dentro de selectedUsers
    const foundIndex = selectedUsers.findIndex((user) => user.id === cliente.id)
    if (foundIndex !== -1) {
      // Remove o usuário se já estiver selecionado
      const newSelectedUsers = [...selectedUsers]
      // Remove o cliente do array newSelectedUsers
      newSelectedUsers.splice(foundIndex, 1)
      // Atualiza selectedUsers com o novo array sem o cliente
      setSelectedUsers(newSelectedUsers)
    } else {
      // Adiciona o usuário se não estiver selecionado
      setSelectedUsers([...selectedUsers, cliente])
    }
  }
  useEffect(() => {
    // Verifica se todos os usuários estão marcados após cada atualização de selectedUsers ou filteredUsuarios
    setIsAllUsersChecked(selectedUsers === filteredUsuarios)
  }, [selectedUsers, filteredUsuarios])

  const [usersGroup, setUsersGroup] = useState([])
  const userNames                   = usersGroup.map((group) => {
    return group.id
  })

  useEffect(() => {
    // Inicializa selectedUsers com os IDs presentes em userNames
    const initialSelectedUsers = usersGroup.filter((group) => userNames.includes(group.id))
    setSelectedUsers(initialSelectedUsers)
  }, [usersGroup])

  // Atualiza o estado `dadosDoNovoGrupo.users` quando `selectedUsers` muda
  useEffect(() => {
    setDadosDoNovoGrupo((prevState) => ({
      ...prevState,
      users: selectedUsers.map((user) => ({
        id:   parseInt(user.id),
        name: user.name,
      })),
    }))
  }, [selectedUsers, valorEditarNome, valorEditarReportHour, valorEditarId, valorEditarGroupId])

  useEffect(() => {
    setDadosDoNovoGrupo({
      // id: valorEditarId,
      name:        valorEditarNome,
      report_hour: valorEditarReportHour,
    })
  }, [valorEditarNome, valorEditarReportHour])

  const handleClick                        = (group) => {
    setDadosDoNovoGrupo((prevState) => ({
      ...prevState,
      // id: group.id,
      name:        group.name,
      report_hour: group.report_hour,
    }))
    openModal2()
  }
  //=================================================================================================================================================
  const [nomeExcluir, setGroupNameExcluir] = useState('')
  const [idExcluir, setIdGroupExcluir]     = useState('')
  const [modalIsOpen3, setIsOpen3]         = React.useState(false)

  function openModal3 () {
    setIsOpen3(true)
  }

  function afterOpenModal3 () {}

  function closeModal3 () {
    setIsOpen3(false)
  }

  const excluir           = () => {
    ;('excluir')
    AxiosConfigs.delete(`/groups/${idExcluir}`) //  incluir o ID
      .then((response) => {
        response.data
        toast.success('Grupo excluido')
        closeModal3()
        AxiosConfigs.get(`/groups`).then((response) => {
          setGroup(response.data)
        }).catch((error) => {
          toast.error('There was an error fetching the data! ' + error)
        })
      }).catch((error) => {
      toast.error('There was an error fetching the data! ' + error)
    })
  }
  //================================================================================================================================
  const [group, setGroup] = useState([])
  useEffect(() => {
    AxiosConfigs.get(`/groups`).then((response) => {
      setGroup(response.data)
      // (response.data)
    }).catch((error) => {
      toast.error('There was an error fetching the data! ' + error)
    })
  }, [])

  const [searchGroup, setSearchGroup] = useState('')

  const filteredGroups = searchGroup.length > 0 ? group.filter((grp) => grp.name && grp.name.toLowerCase().includes(searchGroup.toLowerCase())) : group

  const editar = () => {
    if (dadosDoNovoGrupo.tenants.length === 0) {
      toast.error('Selecione ao menos um cliente')
    } else if (dadosDoNovoGrupo.users.length === 0) {
      toast.error('Selecione ao menos um usuário')
    } else {
      AxiosConfigs.put(`/groups/${valorEditarId}`, dadosDoNovoGrupo, {}).then(() => {
        toast.success('Grupo editado com sucesso')
        AxiosConfigs.get('/groups').then((response) => {
          setGroup(response.data)
        }).catch((error) => {
          toast.error('There was an error fetching the data! ' + error)
        })
        closeModal2()
        setDadosDoNovoGrupo({
          // id: '',
          name:        '',
          report_hour: '',
          tenants:     [],
          users:       [
            {
              id:   '',
              name: '',
            },
          ],
        })
      })
      AxiosConfigs.get(`/groups`).then((response) => {
        setGroup(response.data)
      }).catch((error) => {
        toast.error('There was an error fetching the data! ' + error)
      })
      setValorEditarGroupId('').catch((error) => {
        toast.error('Houve um erro ao enviar os dados! ' + error)
      })
    }
  }

  const [userTenant, setUserTenant] = useState(false)
  const togUserTenant               = () => {
    setUserTenant(!userTenant)
  }
  // const [togGroupUser, setTogGroupUser] = useState(false)
  const [users, setUsers]           = useState([])
  const [searchs, setSearchs]       = useState('')
  //PESQUISA USUARIOS
  useEffect(() => {
    AxiosConfigs.get('/users').then((response) => {
      setUsers(response.data)(response.data)
    }).catch((error) => {
      toast.error('There was an error fetching the data! ' + error)
    })
  }, [])

  let filteredUsers = searchs.length > 0 ? users.filter((cliente) => cliente.name && cliente.name.toLowerCase().includes(searchs.toLowerCase())) : users

  return (
    <Layout active="groups">
      {/*{togGroupUser === false ? (*/}
      <div className="h-full p-6">
        <div className="p-5 flex justify-between ">
          <p className=" font-sans text-md font-medium tracking-tight">Grupos</p>
        </div>
        <div className="h-[calc(90%-40px)] grid grid-rows-12 ">
          <div className="row-span-10  size-full ">
            <div className="rounded-lg bg-white h-full ">
              <div className="h-full overflow-auto border rounded-lg border-gray-300 shadow-md ">
                {togLoad === false ? (
                  <table className="table-auto w-full">
                    <thead className="border-b-2 border-black h-10 -top-0 sticky ">
                    <tr className="px-2 text-start">
                      <th className="px-2 text-start">Nome do Grupo</th>
                      <th className="text-start px-2">
                        <div className="flex items-center justify-end sticky top p-2">
                          <input
                            className="p-2 rounded-md font-thin border border-gray-500 focus:outline-none focus:border-2 focus:border-blue-700"
                            name="search"
                            placeholder="Pesquisar"
                            type="text"
                            value={searchGroup}
                            onChange={(e) => setSearchGroup(e.target.value)}
                          />
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="text-md">
                    {filteredGroups.filter((group) => group.name !== null) // Filtrar usuários com nome não nulo
                      .map((group, index) => (
                        <tr
                          key={index}
                          className="h-12 border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight">
                          <td className="px-2 pl-12">{group.name}</td>
                          <td className="px-2 space-x-3 ">
                            <div className="w-full flex justify-end pr-12 gap-4">
                              {permissoes && permissoes.groups && permissoes.groups.update ? (
                                <button
                                  className=" p-1.5 text-white bg-[#35208f] tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                                  type="button"
                                  onClick={() => {
                                    handleClick(group)
                                    setTenantsGroup(group.tenants)
                                    setValorEditarId(group.id)
                                    setEditarNome(group.name)
                                    setValorEditarReportHour(group.report_hour)
                                    setUsersGroup(group.users)
                                    //atualizaTenants()
                                    openModal2()
                                  }}>
                                  <PencilSquareIcon className="size-5" />
                                </button>
                              ) : null}
                              {permissoes && permissoes.groups && permissoes.groups.delete ? (
                                <button
                                  className="p-1.5 text-rose-600 tracking-tight font-medium rounded-lg duration-300 hover:bg-rose-600 hover:text-white"
                                  type="button"
                                  onClick={() => {
                                    setGroupNameExcluir(group.name)
                                    setIdGroupExcluir(group.id)
                                    openModal3()
                                  }}>
                                  <TrashIcon className="size-5" />
                                </button>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="h-full flex items-center justify-center">
                    <svg
                      className="animate-spin size-36 text-blue-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-start-12 flex items-center justify-between">
            {permissoes && permissoes.groups && permissoes.groups.create ? (
              <button
                className=" ml-16 p-2 flex shadow-md shadow-blue-200 bg-[#35208f] text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                type="button"
                onClick={() => {
                  navigate('/newgroups')
                }}>
                Novo grupo
              </button>
            ) : null}
          </div>
        </div>

        <Modal
          ariaHideApp={false}
          className="grid grid-rows-12 border rounded-lg border-blue-700 w-10/12 h-5/6 absolute top-2/4 left-2/4 right-auto bottom-auto -mr-2/4 -translate-x-2/4 -translate-y-2/4 bg-white shadow-lg shadow-blue-200"
          contentLabel="Example Modal"
          isOpen={modalIsOpen2}
          onAfterOpen={afterOpenModal2}
          onRequestClose={closeModal2}>
          <div className="flex items-center  w-full row-start-2 ">
            <div className="w-full">
              <h2 className="text-gray-500 font-bold rounded-lg ml-6">Editar</h2>
              <form className="flex items-center justify-start rounded-lg bg-white size-full p-3 shadow-lg">
                <div className="w-4/12">
                  <label
                    className="block tracking-tighter font-medium"
                    htmlFor="name">
                    Nome do grupo
                  </label>
                  <input
                    className="p-2 w-3/6 font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                    id="name"
                    required={true}
                    type="text"
                    value={dadosDoNovoGrupo.name}
                    onChange={(e) => {
                      setDadosDoNovoGrupo({
                        ...dadosDoNovoGrupo,
                        name: e.target.value,
                      })
                    }}
                  />
                </div>

                <div className="w-4/12">
                  <label
                    className="block tracking-tighter font-medium"
                    htmlFor="email">
                    Notificações as
                  </label>
                  <input
                    className=" w-3/6 p-2 font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                    id="reporthour"
                    required={true}
                    type="time"
                    value={dadosDoNovoGrupo.report_hour}
                    onChange={(e) => {
                      setDadosDoNovoGrupo({
                        ...dadosDoNovoGrupo,
                        report_hour: e.target.value, // Alterado para 'name' em vez de 'group_name'
                      })
                    }}
                  />
                </div>
                <div>
                  <button
                    className="text-nowrap p-2 ml-56 bg-[#35208f] text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                    type="button"
                    onClick={togUserTenant}>
                    {userTenant === false ? <div>Usuario</div> : <div>Cliente</div>}
                  </button>
                </div>
              </form>
            </div>
          </div>

          {userTenant === false ? (
            <div className=" rounded-t-lg mt-5  row-start-4 row-end-11 ">
              <div className="flex items-center justify-end mr-6">
                <input
                  className="h-6 p-2 rounded-md font-thin border border-gray-500 mt-1"
                  name="search"
                  placeholder="Pesquisar"
                  type="text"
                  value={searchs}
                  onChange={(e) => setSearchs(e.target.value)}
                />
              </div>

              <div className="overflow-auto size-full border-2 ">
                <table className="table-auto w-full">
                  <thead className="sticky top-0 bg-white">
                  <tr className="px-2 text-start border-b-2 border-black">
                    <th className="text-start px-2">
                      <p className="flex flex-row items-center">
                        <input
                          checked={isAllTenantChecked}
                          className="size-4 mx-auto"
                          id="checkAll"
                          type="checkbox"
                          onChange={allTenantsIsChecked}
                        />
                      </p>
                    </th>
                    <th className="text-start h-12 px-2">
                      <label htmlFor="checkAll">Marcar todos</label>
                    </th>
                    <th className="text-start h-12 px-2" />
                    <th className="text-start h-12 px-2" />
                  </tr>
                  </thead>

                  <tbody className="text-md">
                  {filteredTenants.filter((group) => group.name !== null) // Filtrar usuários com nome não nulo
                    .map((tenant, index) => (
                      <tr
                        key={index}
                        className="h-12 border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight">
                        <th
                          key={tenant.tenant}
                          className="text-start px-2"
                          onClick={() => {
                            allIsCheckedTenants()
                          }}>
                          <p className="flex flex-row items-center">
                            <input
                              key={index}
                              checked={dadosDoNovoGrupo?.tenants?.includes(tenant.id)}
                              className="size-4 mx-auto"
                              type="checkbox"
                              onChange={() => selectTenants(tenant)}
                            />
                          </p>
                        </th>
                        <td className="px-2 w-2/5">{tenant.name}</td>
                        <td className="px-2 w-2/5" />
                        <td className="px-2 w-2/5" />
                        <td className="px-2 w-2/5" />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className=" rounded-t-lg mt-5  row-start-4 row-end-11 ">
              <div className="flex items-center justify-end mr-8">
                <input
                  className="h-6 p-2 rounded-md font-thin border border-gray-500 mt-1"
                  name="search"
                  placeholder="Pesquisar"
                  type="text"
                  value={searchs}
                  onChange={(e) => setSearchs(e.target.value)}
                />
              </div>

              <div className="overflow-auto size-full border-2 ">
                <table className="table-auto w-full">
                  <thead className="sticky top-0 bg-white">
                  <tr className="px-2 text-start border-b-2 border-black">
                    <th
                      className="text-start px-2"
                      onClick={allUsersIsChecked}>
                      <p className="flex flex-row items-center">
                        <input
                          checked={isAllUsersChecked}
                          className="size-4 mx-auto"
                          type="checkbox"
                          onChange={allUsersIsChecked}
                        />
                      </p>
                    </th>
                    <th className="text-start h-12 px-2">Marcar todos</th>
                    <th className="text-start px-2">Email</th>
                    <th className="text-start px-2" />
                    <th className="text-start px-2" />
                  </tr>
                  </thead>

                  <tbody className="w-full">
                  {filteredUsers.filter((cliente) => cliente.name !== null) // Filtrar usuários com nome não nulo
                    .sort((a, b) => a.name.localeCompare(b.name)) // Ordenar usuários pelo nome
                    .map((cliente) => (
                      <tr
                        key={cliente.id}
                        className="h-12 border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight">
                        <td
                          className="text-start px-2"
                          onClick={() => selectUsers(cliente)}>
                          <p className="flex flex-row items-center">
                            <input
                              checked={selectedUsers.some((user) => user.id === cliente.id)}
                              className="size-4 mx-auto"
                              type="checkbox"
                              onChange={() => selectUsers(cliente)}
                            />
                          </p>
                        </td>
                        <td className="px-2 w-2/5">{cliente.name}</td>
                        <td className="px-2">{cliente.email}</td>
                        <td className="px-2" />
                        <td className="px-2 space-x-3" />
                        <td className="px-2" />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className=" row-start-12 rounded-md flex items-center ">
            <div className=" w-full flex items-center justify-between">
              <div>
                <button
                  className="p-1.5 w-16 ml-10 tracking-tight font-medium rounded-lg duration-300 bg-rose-600 text-white shadow-md shadow-red-200 hover:bg-rose-700 hover:shadow-md"
                  type="button"
                  onClick={() => {
                    // setValorNovoNome('')
                    closeModal2()
                    setDadosDoNovoGrupo({
                      ...dadosDoNovoGrupo,
                      emails: [],
                    })
                  }}>
                  Voltar
                </button>
              </div>

              <div>
                <button
                  className="p-1.5 w-16 mr-10 tracking-tight font-medium rounded-lg duration-300 bg-[#35208f] text-white shadow-md shadow-blue-200 hover:bg-blue-950 hover:shadow-md"
                  type="button"
                  onClick={() => {
                    editar()
                    closeModal2()
                  }}>
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          ariaHideApp={false}
          className="border rounded-lg border-blue-700 h-60 w-96 m-auto mt-[18%] bg-white shadow-lg shadow-blue-200"
          contentLabel="Example Modal"
          isOpen={modalIsOpen3}
          onAfterOpen={afterOpenModal3}
          onRequestClose={closeModal3}>
          <h2 className="mt-6 text-center text-gray-500 font-bold text-lg">Excluir : {nomeExcluir}</h2>

          <div className=" flex items-center justify-center h-32">
            <ExclamationTriangleIcon className="size-20 text-red-600 border-2 rounded-full p-2 bg-red-100 hover:bg-white transform duration-500" />
          </div>

          <div className="flex flex-row justify-evenly">
            <button
              className="p-1.5 text-blue-900 tracking-tight font-medium rounded-lg duration-300 hover:bg-[#35208f] hover:text-white"
              type="button"
              onClick={closeModal3}>
              Voltar
            </button>

            <button
              className="p-1.5 text-rose-600 tracking-tight font-medium rounded-lg duration-300 hover:bg-rose-600 hover:text-white"
              type="button"
              onClick={() => {
                excluir()
              }}>
              Excluir
            </button>
          </div>
        </Modal>
      </div>
    </Layout>
  )
}
export default Groups
