import { React, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import AxiosConfigs from '../../configs';
import { toast } from 'react-hot-toast';
import { ChevronDownIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { FunnelIcon as SolidFunnel } from '@heroicons/react/24/solid';

const InventoryList = () => {
  const [inventories, setInventories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [clients, setClients] = useState([]);
  const [expanded, setExpanded] = useState('');
  const [filters, setFilters] = useState({
    client: [],
    machineType: [],
    soFamily: [],
  });
  const [haveFilters, setHaveFilters] = useState(false);
  const open = Boolean(anchor);
  const [pagination, setPagination] = useState({
    meta: { current_page: 1, last_page: 1 },
    links: {},
  });
  const machineOptions = [
    { value: 'vm', label: 'Virtual' },
    { value: 'fisica', label: 'Física' },
    { value: 'other', label: 'Outro' },
  ];

  const soFamilyOptions = [
    { value: 'centos', label: 'Centos' },
    { value: 'debian', label: 'Debian' },
    { value: 'fedora', label: 'Fedora' },
    { value: 'ubuntu', label: 'Ubuntu' },
    { value: 'catalina', label: 'Catalina' },
    { value: 'mojave', label: 'Mojave' },
    { value: 'big sur', label: 'Big sur' },
    { value: 'esxi', label: 'Esxi' },
    { value: 'vCenter', label: 'VCenter' },
    { value: '11', label: 'Windows 11' },
    { value: '10', label: 'Windows 10' },
    { value: '8.1', label: 'Windows 8.1' },
    { value: '7', label: 'Windows 7' },
    { value: 'server 2022', label: 'Windwos erver 2022' },
    { value: 'server 2019', label: 'Windwos erver 2019' },
    { value: 'server 2016', label: 'Windwos erver 2016' },
    { value: 'server 2012', label: 'Windwos erver 2012' },
    { value: 'server 2008 r2', label: 'Windows Server 2008 r2' },
    {
      value: 'server 2008 r2 standard',
      label: 'WindowsServer 2008 R2 Standard',
    },
    { value: 'server 2008', label: 'Windows Server 2008' },
    { value: 'server 2003', label: 'Windows Server 2003' },
  ];
  const getInventory = async () => {
    setIsLoading(true);
    await AxiosConfigs.get(`/inventory`, {
      params: {
        page: pagination.meta.current_page,
        client: filters.client.join(','),
        machineType: filters.machineType.join(','),
        soFamily: filters.soFamily.join(','),
      },
    })
      .then((response) => {
        setInventories(response.data.data);
        setPagination({
          ...pagination,
          meta: { ...response.data.meta },
          links: { ...response.data.links },
        });
      })
      .catch(function (error) {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getClients = () => {
    AxiosConfigs.get('/new_clients').then((response) => {
      setClients(response.data);
    });
  };

  // const [inventoriesData, setInventoriesData] = useState([]);
  const navigate = useNavigate();
  const backupTool = {
    acronisCyberBackup: 'Acronis Cyber Backup',
    veeamBackupReplication: 'Veeam Backup & Replication',
    commvaultCompleteBackupRecovery: 'Commvault Complete Backup & Recovery',
    veritasBackupExec: 'Veritas Backup Exec',
    ibmSpectrumProtect: 'IBM Spectrum Protect',
    dellEmcAvamar: 'Dell EMC Avamar',
    rubrik: 'Rubrik',
    cohesityDataProtect: 'Cohesity DataProtect',
    bacula: 'Bacula',
    amanda: 'Amanda (Advanced Maryland Automatic Network Disk Archiver)',
    duplicity: 'Duplicity',
    restic: 'Restic',
    naquivo: 'Naquivo',
    borgBackup: 'BorgBackup',
    urBackup: 'UrBackup',
    other: 'Outro',
  };

  const toolTipInfo = (machine) => {
    return (
      <div>
        <p>
          <span className="font-bold">Nome:</span> {machine.name}
        </p>
        <p>
          <span className="font-bold">Tipo:</span>{' '}
          {machine.machineType === 'other'
            ? machine.otherMachineType
            : machine.machineType.length === 2
              ? machine.machineType.toUpperCase()
              : machine.machineType.charAt(0).toUpperCase() + machine.machineType.slice(1)}
        </p>
        <p>
          <span className="font-bold">CPU: </span>
          {machine.cpu}
        </p>
        <p>
          <span className="font-bold">RAM: </span>
          {machine.ram}
        </p>
        <p>
          <span className="font-bold">Possui backup: </span>
          {machine.itHasBackup ? 'Sim' : 'Não'}
        </p>
        <p>
          <span className="font-bold">RTO: </span>
          {machine.backupRTO ?? ''}
        </p>
        <p>
          <span className="font-bold">Ferramenta do Backup: </span>
          {machine.backupTool === 'other' ? machine.otherBackupTool : backupTool[machine.backupTool]}
        </p>
        <p>
          <span className="font-bold">Retenção Diária: </span>
          {machine.dailyRetention.time.join(' • ')}
        </p>
        <p>
          <span className="font-bold">Retenção Semanal: </span>
          {Object.entries(machine.weeklyRetention.time).map(([key, value]) => {
            if (key === 'time' && key !== null) {
              console.log(value);

              // value.map((time) => console.log(time));
            }
          })}
        </p>
        {/* <p> */}
        {/* <span className="font-bold">Retenção Mensal: </span> */}
        {/* {...machine.monthlyRetention['time'].time} */}
        {/* </p> */}
        <p>
          <span className="font-bold">Retenção Anual: </span>
          {Object.entries(machine.yearlyRetention.time).map(([key, value]) => {
            console.log(machine.id, key, value.time);
          })}
        </p>
        <p>
          <span className="font-bold">IPs: </span> {machine.ip.join(' • ') ?? ''}
        </p>
        <p>
          <span className="font-bold">Familia SO: </span> {machine.soFamily.charAt(0).toUpperCase() + machine.soFamily.slice(1)}
        </p>
        <p>
          <span className="font-bold">Versão SO: </span>{' '}
          {machine.soModel.toLowerCase().replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
          })}
        </p>
        <p>
          <span className="font-bold">Data cadastro: </span>
          {dayjs(machine.created_at).format('DD/M/YY')}
        </p>
      </div>
    );
  };

  useEffect(() => {
    getInventory().then();
    getClients();
  }, [pagination.meta.current_page]);

  useEffect(() => {
    setHaveFilters(filters.client.length > 0 || filters.machineType.length > 0 || filters.soFamily.length > 0);
  }, [filters]);

  return (
    <div>
      <div className="pb-5 flex gap-3">
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            navigate('/inventory/form');
          }}
        >
          Adicionar
        </Button>
        <div>
          <IconButton
            color="primary"
            onClick={(event) => {
              setAnchor(event.currentTarget);
            }}
          >
            {haveFilters ? <SolidFunnel className="size-5" /> : <FunnelIcon className="size-5" />}
          </IconButton>
          <div hidden={!haveFilters} className="inline">
            <Button color="error" variant="text" size="small" onClick={() => setFilters({ client: [], machineType: [], soFamily: [] })}>
              <Typography variant="caption">Limpar filtros</Typography>
            </Button>
          </div>
          <Menu
            anchorEl={anchor}
            open={open}
            onClose={() => setAnchor(null)}
            sx={{
              width: 500,
              p: 0,
            }}
            variant="menu"
          >
            <MenuList
              dense={true}
              sx={{
                p: 0,
              }}
            >
              <MenuItem disableRipple={true} sx={{ py: 0 }}>
                <Accordion
                  expanded={expanded === 'panel1'}
                  onChange={() => setExpanded('panel1')}
                  square={true}
                  disableGutters={true}
                  sx={{
                    width: 500,
                    p: 0,
                  }}
                >
                  <AccordionSummary expandIcon={<ChevronDownIcon className="size-4" />}>
                    <Typography className="flex items-center gap-2">Clientes {filters.client.length > 0 && <div className="size-2 bg-blue-300 rounded-full" />}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Autocomplete
                      multiple={true}
                      disableCloseOnSelect={true}
                      size="small"
                      autoHighlight={true}
                      options={clients}
                      value={clients.filter((client) => filters.client.includes(client.fantasy_name))}
                      onChange={(_, value) => {
                        setFilters({
                          ...filters,
                          client: value.map((client) => client.fantasy_name),
                        });
                      }}
                      renderInput={(params) => <TextField {...params} label="Cliente" variant="outlined" size="small" />}
                      getOptionLabel={(option) => option.fantasy_name}
                      filterOptions={(options, params) => {
                        const filtered = options.filter((option) => {
                          return option.fantasy_name.toLowerCase().includes(params.inputValue.toLowerCase());
                        });
                        return filtered;
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </MenuItem>
              <MenuItem disableRipple={true} sx={{ py: 0 }}>
                <Accordion
                  expanded={expanded === 'panel2'}
                  onChange={() => setExpanded('panel2')}
                  square={true}
                  disableGutters={true}
                  sx={{
                    width: 500,
                    p: 0,
                  }}
                >
                  <AccordionSummary expandIcon={<ChevronDownIcon className="size-4" />}>
                    <Typography className="flex items-center gap-2">
                      Tipo de máquina
                      {filters.machineType.length > 0 && <div className="size-2 bg-blue-300 rounded-full" />}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Autocomplete
                      autoHighlight={true}
                      disableCloseOnSelect={true}
                      size="small"
                      multiple={true}
                      value={machineOptions.filter((machineType) => filters.machineType.includes(machineType.value))}
                      onChange={(_, value) => {
                        setFilters({
                          ...filters,
                          machineType: value.map((machineType) => machineType.value),
                        });
                      }}
                      options={machineOptions}
                      renderInput={(params) => <TextField {...params} label="Tipo de máquina" variant="outlined" size="small" />}
                      getOptionLabel={(option) => option.label}
                    />
                  </AccordionDetails>
                </Accordion>
              </MenuItem>
              <MenuItem disableRipple={true} sx={{ py: 0 }}>
                <Accordion
                  expanded={expanded === 'panel3'}
                  onChange={() => setExpanded('panel3')}
                  square={true}
                  disableGutters={true}
                  sx={{
                    width: 500,
                    p: 0,
                  }}
                >
                  <AccordionSummary expandIcon={<ChevronDownIcon className="size-4" />}>
                    <Typography className="flex items-center gap-2">
                      Familia do SO
                      {filters.soFamily.length > 0 && <div className="size-2 bg-blue-300 rounded-full" />}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Autocomplete
                      autoHighlight={true}
                      disableCloseOnSelect={true}
                      size="small"
                      multiple={true}
                      value={soFamilyOptions.filter((soFamily) => filters.soFamily.includes(soFamily.value))}
                      onChange={(_, value) => {
                        setFilters({
                          ...filters,
                          soFamily: value.map((soFamily) => soFamily.value),
                        });
                      }}
                      options={soFamilyOptions}
                      renderInput={(params) => <TextField {...params} label="Familia do SO" variant="outlined" size="small" />}
                      getOptionLabel={(option) => option.label}
                    />
                  </AccordionDetails>
                </Accordion>
              </MenuItem>
              {/* <MenuItem> */}
              <div className="w-full flex justify-end pt-4 px-4">
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  className="mr-auto"
                  onClick={() => {
                    getInventory().then();
                    setAnchor(null);
                  }}
                >
                  <Typography variant="caption">Aplicar</Typography>
                </Button>
              </div>
              {/* </MenuItem> */}
            </MenuList>
          </Menu>
        </div>
      </div>
      <div className="bg-white rounded-lg">
        {isLoading ? (
          <Skeleton variant="rectangular" height={'calc(80vh)'} animation="wave" />
        ) : (
          <Table size="small" className="shadow bg-white">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>Nome</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Cliente</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Tipo de maquina</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Ferramenta do backup</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Familia SO</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Versão SO</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Comentário</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="text-md">
              {inventories.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} className="text-center py-4 ">
                    Nenhuma máquina cadastrada.
                  </TableCell>
                </TableRow>
              ) : (
                inventories
                  .sort((a, b) => b.created_at.localeCompare(a.created_at))
                  .map((machine) => (
                    <Tooltip
                      followCursor={true}
                      title={toolTipInfo(machine)}
                      key={machine.id}
                      sx={{
                        width: 1000,
                      }}
                    >
                      <TableRow
                        key={machine.id}
                        className="border-b even:bg-neutral-50 hover:bg-neutral-200 duration-300 tracking-tight cursor-pointer"
                        onClick={() => {
                          navigate(`/inventory/form/${machine.id}`);
                        }}
                      >
                        <TableCell className="text-start px-2">
                          <div className="max-w-72 overflow-hidden text-nowrap text-ellipsis">{machine.name}</div>
                        </TableCell>
                        <TableCell className="text-start px-2">
                          <div className="max-w-48 overflow-hidden text-nowrap text-ellipsis">{machine.client.fantasy_name}</div>
                        </TableCell>
                        <TableCell className="text-center px-2">
                          <div className="max-w-48 overflow-hidden text-nowrap text-ellipsis">
                            {machine.machineType.length === 2 ? machine.machineType.toUpperCase() : machine.machineType.charAt(0).toUpperCase() + machine.machineType.slice(1)}
                          </div>
                        </TableCell>
                        <TableCell className="text-start px-2">
                          <div className="max-w-48 overflow-hidden text-nowrap text-ellipsis">{backupTool[machine.backupTool]}</div>
                        </TableCell>
                        <TableCell className="text-start px-2">
                          <div className="max-w-72 overflow-hidden text-nowrap text-ellipsis">{machine.soFamily.charAt(0).toUpperCase() + machine.soFamily.slice(1)}</div>
                        </TableCell>
                        <TableCell className="text-start px-2">
                          <div className="max-w-72 overflow-hidden text-nowrap text-ellipsis">
                            {machine.soModel.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                              return letter.toUpperCase();
                            })}
                          </div>
                        </TableCell>
                        <TableCell className="text-start px-2">
                          <div className="max-w-48 overflow-hidden text-nowrap text-ellipsis">{machine.comment}</div>
                        </TableCell>
                      </TableRow>
                    </Tooltip>
                  ))
              )}
            </TableBody>
            <TableFooter className="flex">
              <Box className="py-5 flex items-center">
                <Pagination
                  color="primary"
                  count={pagination.meta.last_page}
                  size="small"
                  page={pagination.meta.current_page}
                  onChange={(_, value) => {
                    setPagination({
                      ...pagination,
                      meta: { ...pagination.meta, current_page: value },
                    });
                  }}
                />
                <Typography
                  variant="caption"
                  className="text-center"
                  sx={{
                    marginTop: '3px',
                  }}
                >
                  Items cadastrados: {pagination.meta.total}
                </Typography>
              </Box>
            </TableFooter>
          </Table>
        )}
      </div>
    </div>
  );
};

export default InventoryList;
