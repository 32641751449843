import React, { useContext } from 'react'
import Layout from '../components/Layouts/Layout.jsx'
import { useState } from 'react'
import { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import AxiosConfigs from '../configs.js'
import DatePicker from 'react-datepicker'
import { pt } from 'date-fns/locale/pt'
import { toast } from 'react-hot-toast'
import Relatorio from './Reports.jsx'
import PermissoesContext from '../context/PermisoesContext.jsx'
import { useCookies } from 'react-cookie'

const Report = () => {
  const { permissoes }                  = useContext(PermissoesContext)
  const [cookies]                       = useCookies('is_admin')
  const isAdmin                         = cookies.is_admin
  //=============================================================================================================================================================================================
  const [tenants, setTenants]           = useState([])
  const [searchTenant, setSearchTenant] = useState('')
  const [togLoad, setTogLoad]           = useState(true)
  const namedTenant                     = tenants.filter((tenant) => Object.prototype.hasOwnProperty.call(tenant, 'name'))

  useEffect(() => {
    const tenantsFromLocalStorage = JSON.parse(localStorage.getItem('tenants'))

    if (tenantsFromLocalStorage) {
      setTenants(tenantsFromLocalStorage)
      setTogLoad(false)
    } else {
      AxiosConfigs.get('/tenants').then((response) => {
        setTenants(response.data)
        setTogLoad(false)
        localStorage.setItem('tenants', JSON.stringify(response.data))
      }).catch((error) => {

      })
    }
  }, [])

  // useEffect(() => {
  //     const tenants = JSON.parse(localStorage.getItem('tenants'))
  //     if (tenants) {
  //         setTenants(tenants)
  //     }
  // }, [])
  //
  // useEffect(() => {
  //     AxiosConfigs.get('/tenants')
  //         .then((response) => {
  //             setTenants(response.data)
  //             setTogLoad(false)
  //         })
  //         .catch(function (error) {
  //         })
  // }, [])

  const filteredTenants = searchTenant.length > 0 ? namedTenant.filter((cliente) => cliente.name && cliente.name.toLowerCase().includes(searchTenant.toLowerCase())) : namedTenant
  //==============================================================================================================================================================================================

  const [dadosDoNovoGrupo, setDadosDoNovoGrupo] = useState({
    group_name:  '',
    emails:      [],
    tenants:     [],
    report_hour: '',
  })

  const selectedIds = dadosDoNovoGrupo.tenants

  const [isAllTenantChecked, setIsAllTenantChecked] = useState(false)
  // Função para verificar se todos os checkboxes estão selecionados
  const allTenantsIsChecked                         = () => {
    if (isAllTenantChecked) {
      // Desmarcar todos
      dadosDoNovoGrupo.tenants = []
    } else {
      // Marcar todos
      dadosDoNovoGrupo.tenants = filteredTenants.map((tenant) => tenant.id)
    }
    setIsAllTenantChecked(!isAllTenantChecked)
  }

  const allIsCheckedTenants = () => {
    setIsAllUsersChecked(!isAllUsersChecked)
  }
  // Função para selecionar/desselecionar um tenant

  const selectTenants                             = (tenant) => {

    // Cria uma nova cópia do array de IDs de inquilinos
    const updatedTenants = dadosDoNovoGrupo.tenants.includes(tenant.id)
      ? dadosDoNovoGrupo.tenants.filter((id) => id !== tenant.id) // Remove o ID se ele já estiver na lista
      : [...dadosDoNovoGrupo.tenants, tenant.id] // Adiciona o ID se ele não estiver na lista

    // Atualiza o estado com a nova lista de IDs
    setDadosDoNovoGrupo({ tenants: updatedTenants })

    // Atualiza o estado para refletir se todos os inquilinos estão selecionados
    setIsAllTenantChecked(updatedTenants.length === tenants.length)
  }
  const [isAllUsersChecked, setIsAllUsersChecked] = useState(false)
  //------------------------------------------------------------------------------------------------------------------
  const [dataInicial, setDataInicial]             = useState(null)
  const [dataFinal, setDataFinal]                 = useState(null)

  let dataFormatadaInicial
  let dataFormatadaFinal
  if (dataInicial !== null && dataFinal !== null) {
    // Obter dia, mês e ano da data original
    let diaInicical      = dataInicial.getDate()
    let mesInicial       = dataInicial.getMonth() + 1
    let anoInicical      = dataInicial.getFullYear()
    // Formatando a data no formato dd/mm/aaaa
    dataFormatadaInicial = anoInicical + '/' + (mesInicial < 10 ? '0' : '') + mesInicial + '/' + (diaInicical < 10 ? '0' : '') + diaInicical
    // Obter dia, mês e ano da data original
    let diaFinal         = dataFinal.getDate()
    // Os meses em JavaScript são indexados a partir de 0, então somamos 1 para obter o mês real
    let mesFinal         = dataFinal.getMonth() + 1
    let anoFinal         = dataFinal.getFullYear()
    // Formatando a data no formato dd/mm/aaaa
    dataFormatadaFinal   = anoFinal + '/' + (mesFinal < 10 ? '0' : '') + mesFinal + '/' + (diaFinal < 10 ? '0' : '') + diaFinal
  }
  const [btnVoltar, setBtnVoltar]                       = useState(false)
  //==================================================================================================================
  const [respMaquinas, setRespMaquinas]                 = useState([])
  const [maquinasSelecionadas, setMaquinasSelecionadas] = useState([])
  const [showTenants, setShowTenants]                   = useState(true)
  const [showMachines, setShowMachines]                 = useState(false)
  const [mostraRelatorio, setMostraRelatorio]           = useState(false)
  const [isPressedActive, setIsPressedActive]           = useState(false)
  const [isPressedCritic, setIsPressedCritic]           = useState(false)

  const maquinasRelatorio                                   = () => {
    setTogLoad(true)
    const selectedIds = dadosDoNovoGrupo.tenants // Assumindo que dadosDoNovoGrupo.tenants é a variável que contém os IDs selecionados
    AxiosConfigs.get(`/reports/machines?tenants=${selectedIds}`).then((response) => {
      setRespMaquinas(response.data)
      setBtnVoltar(true)
      setShowMachines(true)
      setShowTenants(false)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setTogLoad(false)
    })
  }
  const [isCheckedTodasMaquinas, setIsCheckedTodasMaquinas] = useState(false)
  const handleRowClick                                      = (maquinaId) => {
    handleCheckboxMaquinasClick(maquinaId) // Chamando a função que manipula o clique na checkbox
  }
  const handleCheckboxMaquinasClick                         = (name) => {
    // setIsCheckedMaquina(!isCheckedMaquina)
    if (maquinasSelecionadas.includes(name)) {
      // Remover máquina selecionada se já estiver presente
      setMaquinasSelecionadas((prevState) => prevState.filter((nomeMaquina) => nomeMaquina !== name))
    } else {
      // Adicionar máquina selecionada se não estiver presente
      setMaquinasSelecionadas((prevState) => [...prevState, name])
    }
  }
  const handleSelectAllMaquinas                             = (clienteNome) => {
    setIsCheckedTodasMaquinas(!isCheckedTodasMaquinas)
    const currentSelectedMachines = new Set(maquinasSelecionadas)
    const machinesToSelect        = Object.keys(respMaquinas[clienteNome])
    const allMachinesSelected     = machinesToSelect.every((machine) => currentSelectedMachines.has(machine))
    if (allMachinesSelected) {
      machinesToSelect.forEach((machine) => currentSelectedMachines.delete(machine))
    } else {
      machinesToSelect.forEach((machine) => currentSelectedMachines.add(machine))
    }
    setMaquinasSelecionadas(Array.from(currentSelectedMachines))
  }
  const todasMaquinasSelecionadas                           = verificarTodasMaquinasSelecionadas(respMaquinas, maquinasSelecionadas)

  function verificarTodasMaquinasSelecionadas (respMaquinas, maquinasSelecionadas) {
    const todasMaquinasSelecionadas = {}
    // Iterar sobre os clientes
    Object.entries(respMaquinas).forEach(([clienteNome, maquinasCliente]) => {
      // Verificar se todas as máquinas do cliente estão selecionadas
      todasMaquinasSelecionadas[clienteNome] = Object.keys(maquinasCliente).every((maquinaId) => maquinasSelecionadas.includes(maquinaId))
    })
    return todasMaquinasSelecionadas
  }

  const cancel = () => {
    setRespMaquinas([])
    setMaquinasSelecionadas([])
    setShowTenants(true)
    setShowMachines(false)
    setIsPressedActive(false)
    setIsPressedCritic(false)
    setMostraRelatorio(false)
    setBtnVoltar(false)
    setLoadToReport(false)
  }

  const [resultRelatorio, setResultRelatorio] = useState([])
  const [loadToReport, setLoadToReport]       = useState(false)

  const report = async() => {
    setTogLoad(true)
    setMostraRelatorio(false)
    setLoadToReport(true)
    await AxiosConfigs.get(
      `/reports?tenants=${selectedIds}&start=${dataFormatadaInicial}&end=${dataFormatadaFinal}&resources=${maquinasSelecionadas}&Critico=${isPressedCritic}&Aviso=${isPressedActive}`).
      then((response) => {
        setResultRelatorio(response.data)
      }).
      catch((error) => {
        toast.error(error.message)
        setMostraRelatorio(false)
      }).
      finally(() => {
        setTogLoad(false)
        setMostraRelatorio(true)
      })
  }

  return (
    <Layout active="reports">
      {mostraRelatorio === false ? (
        <div className="h-full p-6">
          <div className="p-1 pb-4 w-full ">
            <p className=" font-sans text-md font-medium tracking-tight">Relatórios</p>
            <form className=" rounded-lg bg-white h-28 p-3 shadow-lg flex gap-10">
              <div className="">
                <label
                  className="block tracking-tighter font-medium"
                  htmlFor="dataInicial">
                  Data inicial
                </label>
                <DatePicker
                  className="p-2 font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                  dateFormat="dd/MM/yyyy"
                  locale={pt}
                  placeholderText="Data inicial"
                  selected={dataInicial}
                  onChange={(date) => setDataInicial(date)}
                />
              </div>
              <div className="">
                <label
                  className="block tracking-tighter font-medium"
                  htmlFor="dataFinal">
                  Data final
                </label>
                <DatePicker
                  className="p-2 font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                  dateFormat="dd/MM/yyyy"
                  locale={pt}
                  placeholderText="Data final"
                  selected={dataFinal}
                  onChange={(date) => setDataFinal(date)}
                />
              </div>

              <div className="flex place-items-center gap-5 w-3/12">
                <div>
                  <p className="tracking-tighter font-medium">Erros:</p>
                </div>
                <div
                  className="w-full"
                  onChange={() => {
                    setIsPressedActive(!isPressedActive)
                  }}>
                  <input
                    className="size-4"
                    id="aviso"
                    type="checkbox"
                  />
                  <label
                    className="text-lg hover:cursor-pointer"
                    htmlFor="aviso">
                    &nbsp;Avisos
                  </label>
                </div>

                <div
                  className="w-full"
                  onChange={() => {
                    setIsPressedCritic(!isPressedCritic)
                  }}>
                  <input
                    className="size-4"
                    id="critico"
                    type="checkbox"
                  />
                  <label
                    className="text-lg hover:cursor-pointer"
                    htmlFor="critico">
                    &nbsp;Críticos
                  </label>
                </div>
              </div>
            </form>
          </div>

          <div className="h-[calc(80%-40px)] grid grid-rows-12">
            <div className="row-span-10  size-full ">
              <div className="rounded-lg bg-white h-full">
                {showTenants ? (
                  <div className="h-full overflow-auto border rounded-lg border-gray-300 shadow-md">
                    {togLoad === false ? (
                      <table className="table-auto w-full">
                        <thead className=" h-10 -top-0 sticky bg-white ">
                        <tr className="px-2 text-start ">
                          <th
                            className="text-start px-2"
                            onClick={() => {
                              allTenantsIsChecked()
                            }}>
                            <p className="flex flex-row items-center ">
                              <input
                                checked={isAllTenantChecked}
                                className="size-4 mx-auto"
                                type="checkbox"
                                onChange={allTenantsIsChecked}
                              />
                            </p>
                          </th>
                          <th
                            className="text-start px-2 hover:cursor-pointer"
                            onClick={() => {
                              allTenantsIsChecked()
                            }}>
                            Adicionar todos
                          </th>
                          <th className="text-start px-2">
                            <div className="flex items-center justify-end sticky top mr-6">
                              <input
                                className=" px-2 rounded-md font-thin border border-gray-500 focus:outline-none focus:border-2 focus:border-blue-700"
                                name="search"
                                placeholder="Pesquisar"
                                type="text"
                                value={searchTenant}
                                onChange={(e) => setSearchTenant(e.target.value)}
                              />
                            </div>
                          </th>
                        </tr>
                        </thead>
                        <tbody className="text-md">
                        {filteredTenants.filter((group) => group.name !== null) // Filtra usuários com nome não nulo
                          .sort((a, b) => a.name.localeCompare(b.name)) // Ordena alfabeticamente pelo nome
                          .map((tenant, index) => {
                            if (isAdmin === true) {
                              return (
                                <tr
                                  key={index}
                                  className="h-12 border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight">
                                  <th className="text-start px-2">
                                    <p className="flex flex-row items-center">
                                      <input
                                        checked={dadosDoNovoGrupo.tenants.includes(tenant.id)}
                                        className="size-4 mx-auto"
                                        id={tenant.id}
                                        type="checkbox"
                                        onChange={() => selectTenants(tenant)}
                                      />
                                    </p>
                                  </th>
                                  <td
                                    className="px-2"
                                    colSpan="2">
                                    <label
                                      className="hover:cursor-pointer"
                                      htmlFor={tenant.id}>
                                      {tenant.name}
                                    </label>
                                  </td>
                                </tr>
                              )
                            } else {
                              // EXIBE OU NÃO CONFORME AS PERMISSÕES DO CLIENTE
                              // Verifica se o usuário tem as permissões necessárias
                              const hasPermissionView   = permissoes.tenants.some((userpermissions) => userpermissions.tenant === tenant.id) &&
                                permissoes.tenants.find((userpermissions) => userpermissions.tenant === tenant.id)?.permissions?.reports?.view
                              const hasPermissionCreate = permissoes.tenants.some((userpermissions) => userpermissions.tenant === tenant.id) &&
                                permissoes.tenants.find((userpermissions) => userpermissions.tenant === tenant.id)?.permissions?.reports?.create
                              // Retorna a linha apenas se ambas as permissões forem verdadeiras
                              return hasPermissionView && hasPermissionCreate ? (
                                <tr
                                  key={index}
                                  className="h-12 border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight hover:cursor-pointer"
                                  onClick={() => {
                                    allIsCheckedTenants()
                                  }}>
                                  <th className="text-start px-2">
                                    <p className="flex flex-row items-center">
                                      <input
                                        checked={dadosDoNovoGrupo.tenants.includes(tenant.id)}
                                        className="size-4 mx-auto"
                                        id={tenant.id}
                                        type="checkbox"
                                        onChange={() => selectTenants(tenant)}
                                      />
                                    </p>
                                  </th>
                                  <td
                                    className="px-2"
                                    colSpan="2">
                                    <label
                                      className="hover:cursor-pointer"
                                      htmlFor={tenant.id}>
                                      {tenant.name}
                                    </label>
                                  </td>
                                </tr>
                              ) : null
                            }
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="h-full flex items-center justify-center">
                        <svg
                          className="animate-spin size-20 text-blue-700"
                          fill="none"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                ) : null}

                {showMachines ? (
                  <div className="h-full overflow-auto border rounded-lg border-gray-300 shadow-md">
                    <table className="table-auto w-full mb-8 shadow-md  ">
                      {Object.entries(respMaquinas).map(([clienteNome, maquinasCliente]) => (
                        <React.Fragment key={clienteNome}>
                          <thead className="border-b-2 border-black h-10 -top-0 sticky bg-white ">
                          <tr>
                            <th
                              className="text-lg text-center p-1 "
                              colSpan="3">
                              &nbsp; {clienteNome}
                            </th>
                          </tr>
                          <tr
                            className="px-2 text-start"
                            onClick={() => handleSelectAllMaquinas(clienteNome)}>
                            <th className="text-start px-2">
                              <p className="flex flex-row items-center hover:cursor-pointer">
                                <input
                                  checked={todasMaquinasSelecionadas[clienteNome]}
                                  type="checkbox"
                                  onClick={() => handleSelectAllMaquinas(clienteNome)}
                                />
                                &nbsp; &nbsp; Selecionar todas
                              </p>
                            </th>
                          </tr>
                          </thead>
                          <tbody className="text-md">
                          {Object.entries(maquinasCliente).map(([maquinaId, nomeMaquina], index) => (
                            <tr
                              key={maquinaId}
                              className="h-12 border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight ">
                              <td
                                className="text-start px-2"
                                onClick={() => {
                                  handleRowClick(maquinaId)
                                }}>
                                <p className="flex flex-row items-center">
                                  <input
                                    key={index}
                                    checked={maquinasSelecionadas.includes(maquinaId)}
                                    type="checkbox"
                                    onChange={() => handleRowClick(maquinaId)}
                                  />
                                  &nbsp; &nbsp; {nomeMaquina}
                                </p>
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </React.Fragment>
                      ))}
                    </table>
                  </div>
                ) : (
                  <div className="h-full w-full">
                    {loadToReport === true ? (
                      <div className="h-full flex items-center justify-center shadow-lg">
                        <svg
                          className="animate-spin size-20 text-blue-700"
                          fill="none"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>

            <div className="row-start-12 flex items-center justify-between">
              <div>
                {btnVoltar ? (
                  <button
                    className="ml-16 p-2 shadow-md shadow-rose-200 flex bg-rose-600 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-rose-700"
                    type="submit"
                    onClick={() => {
                      setBtnVoltar(false)
                      setShowMachines(false)
                      setShowTenants(true)
                    }}>
                    Voltar
                  </button>
                ) : null}
              </div>

              {btnVoltar === true ? (
                <button
                  className=" mr-16 p-2  shadow-md shadow-blue-200 flex bg-blue-900 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                  type="submit"
                  onClick={() => {
                    if (dataInicial === null && dataFinal === null) {
                      toast.error('Selecione as datas')
                    } else if (dataInicial >= dataFinal) {
                      toast.error('A data inicial precisa ser anterior à data final')
                    } else if (maquinasSelecionadas.length === 0) {
                      toast.error('Escolha ao menos uma maquina ')
                    } else if (isPressedActive === false && isPressedCritic === false) {
                      toast.error('Escolha algum tipo de erro')
                    } else {
                      setShowMachines(false)
                      report().then(() => {
                        setTogLoad(true)
                        setMostraRelatorio(true)
                        setTogLoad(false)
                      })
                    }
                  }}>
                  {/*ferlin*/}
                  Próximo
                </button>
              ) : (
                <button
                  className="mr-16 p-2  shadow-md shadow-blue-200 flex bg-blue-900 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                  type="submit"
                  onClick={() => {
                    if (selectedIds.length === 0) {
                      toast.error('Escolha ao menos um cliente')
                    } else {
                      maquinasRelatorio()
                    }
                  }}>
                  Maquinas
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Relatorio
          cancel={cancel}
          dataFinal={dataFinal}
          dataInicial={dataInicial}
          isPressedAvisos={isPressedActive}
          isPressedCriticos={isPressedCritic}
          resultRelatorio={resultRelatorio}
        />
      )}
    </Layout>
  )
}
export default Report
