import styles from './Tab.module.css'
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid/index.js'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ArrowUpTrayIcon, CheckIcon, ClockIcon, InformationCircleIcon } from '@heroicons/react/24/outline/index.js'
import AxiosConfigs from '../../configs.js'
import { toast } from 'react-hot-toast'
import Modal from 'react-modal'
// import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import * as CryptoJS from 'crypto-js'
import { ArrowRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid/index.js'
import { useCookies } from 'react-cookie'
import PermissoesContext from '../../context/PermisoesContext.jsx'
import { Button, IconButton, TextField, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
// import { Accordion } from 'react-accessible-accordion'

const Tab = ({ items, title, setClientToCheckPermissions, loading, request, type, requestData, refresh, reset }) => {
  const { permissoes }                          = useContext(PermissoesContext)
  const [cookies]                               = useCookies('is_admin')
  const isAdmin                                 = cookies.is_admin
  const [newClientHidden, setNewClientHidden]   = useState(true)
  const [newItemHidden, setNewItemHidden]       = useState(true)
  const [itemName, setItemName]                 = useState({
    name:        '',
    responsible: '',
    email:       '',
    phone:       '',
    info:        '',
  })
  const [historyItems, setHistoryItems]         = useState([])
  const [openModal, setOpenModal]               = useState(false)
  const [openInfoModal, setOpenInfoModal]       = useState(false)
  const [openModalChanges, setOpenModalChanges] = useState(false)
  const [loadingItems, setLoadingItems]         = useState({})
  const [itemId, setItemId]                     = useState(null)
  const [expanded, setExpanded]                 = useState('panel0')
  const [clientInfo, setClientInfo]             = useState({
    responsible: '',
    email:       '',
    phone:       '',
    info:        '',
  })
  const [decryptedCreds, setDecryptedCreds]     = useState({
    old_values: [],
    new_values: [],
  })
  const [changes, setChanges]                   = useState({
    old_values: [],
    new_values: [],
    date:       '',
    paths:      '',
  })
  const tabRef                                  = useRef(null)
  const translate                               = {
    name:          'Nome',
    info:          'Informações',
    external_port: 'Porta externa',
    internal_port: 'Porta Interna',
    credentials:   'Credenciais',
    password:      'Senha',
    ips:           'Ips',
    imagePath:     'Imagens',
  }
  const modalStyles                             = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      backgroundColor: 'white',
      borderRadius:    '0.5rem',
      overflow:        'hidden',
      border:          'none',
      width:           '40%',
      height:          '80%',
      margin:          'auto',
      display:         'flex',
      flexDirection:   'column',
      justifyContent:  'center',
      alignItems:      'center',
    },
  }

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const setLoadingItem = (itemId, isLoading) => {
    setLoadingItems((prev) => ({ ...prev, [itemId]: isLoading }))
  }

  const updateClientInfo = (info, client) => {
    AxiosConfigs.put(`/clients/${client}`, { [info]: clientInfo[info] }).then((res) => {
      if (res.status === 200) {
        toast.success('Informações atualizadas com sucesso')
      }
    }).catch((err) => {
      toast.error(err.message, { id: err.message })
    }).finally(() => {
      refresh((prev) => !prev)
    })
  }

  const decryptPwd = async(item, type) => {
    setLoadingItem(item.id, true)
    for (const [, creds] of Object.entries(item.credentials)) {
      let enc = atob(creds.password)
      enc     = JSON.parse(enc)
      let iv  = CryptoJS.enc.Base64.parse(enc.iv)
      let val = enc.value
      let key = CryptoJS.enc.Base64.parse(import.meta.env.VITE_ENC_KEY)
      let dec = await CryptoJS.AES.decrypt(val, key, { iv: iv })
      dec     = dec.toString(CryptoJS.enc.Utf8)

      setDecryptedCreds((prev) => ({
        ...prev,
        [type]: [...prev[type], { user: creds.user, password: dec }],
      }))
    }
    setLoadingItem(item.id, false)
  }

  const uploadSheet = (input) => {
    const form = new FormData()
    form.append('sheet', input.target.files[0])
    form.append('sublist_id', itemId)
    form.append('client_id', requestData)

    AxiosConfigs.post('/sheets', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      if (response.status === 201) {
        toast.success(response.data)
      }
    }).finally(() => {
      input.target.reset()
      refresh((prev) => !prev)
    })
  }

  const registerNewClient = () => {
    AxiosConfigs.post('/clients', { ...itemName }).then((res) => {
      if (res.status === 201) {
        setNewClientHidden(true)
        setNewItemHidden(true)
        setItemName({ name: '', responsible: '', email: '', phone: '' })
        toast.success('Cliente cadastrado com sucesso', { id: 'success' })
        refresh(true)
      }
    }).catch((err) => {
      if (err.response.status === 422) toast.error('Cliente já cadastrado', { id: 'error' })
      toast.error(err.message, { id: err.message })
    }).finally(() => {
      refresh((prev) => !prev)
    })
  }

  const resetActive = () => {
    const tabItems = document.querySelectorAll(`.CategoriasItem`)
    tabItems.forEach((item) => {
      item.classList.remove(styles.active)
    })
  }

  const setActive = (id) => {
    const tabItems = document.querySelectorAll(`.${title}Item`)
    tabItems.forEach((item) => {
      item.classList.remove(styles.active)
    })
    document.getElementById(id).classList.add(styles.active)
  }

  const getItemHistory = async(client_id, item_id = null) => {
    setLoadingItem(client_id, true)
    const url = item_id !== null ? `/items/update/history/${client_id}/${item_id}` : `/items/update/history/${client_id}`

    await AxiosConfigs.get(url).then((res) => {
      if (res.status === 200) {
        let filteredHistory = res.data.reduce((acc, item) => {
          if (!acc[item.item]) {
            acc[item.item] = []
          }

          acc[item.item].push(item)
          return acc
        }, {})
        setHistoryItems(filteredHistory)
      }
    })
    setLoadingItem(client_id, false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tabRef.current && !tabRef.current.contains(event.target)) {
        setNewClientHidden(true)
        setItemName({ name: '', responsible: '', email: '', phone: '' })
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setLoadingItem(itemId, loading)
  }, [itemId, loading])

  return (
    <div className="shrink-0 h-full w-3/12 bg-neutral-100 border border-gray-300 py-8 px-5 overflow-auto duration-300 transition-all first:border-none">
      <div className="relative text-end mb-5 flex justify-between place-items-center">
        <p className="text-start text-xl font-medium tracking-tight">{title}</p>
        {isAdmin ? (
          <div hidden={type !== 'clients'}>
            <IconButton
              // className="size-8 rounded-lg bg-blue-800 hover:bg-[#35208f] duration-300 text-white "
              color="primary"
              // type="button"
              onClick={() => {
                setNewClientHidden(!newClientHidden)
                setNewItemHidden(false)
              }}>
              <PlusIcon className="size-6 text-center mx-auto" />
            </IconButton>
          </div>
        ) : (
          <div />
        )}

        <form
          className={`top-12 absolute z-50 w-full p-5 bg-white rounded-lg border border-blue-700 ${newItemHidden || newClientHidden ? 'hidden' : ''}`}
          onSubmit={(e) => {
            e.preventDefault()
            type === 'clients' && registerNewClient()
            e.currentTarget.reset()
            refresh(true)
          }}>
          <label
            className="text-start font-medium"
            htmlFor={`newClient_${type}`}>
            Adicionar {title.slice(0, -1)}
          </label>
          <div className="grid gap-2">
            <div className="grid gap-2">
              <TextField
                // ref={(input) => input && input.focus()}
                // className={`border border-neutral-400 rounded-lg p-2 w-full focus:outline-none focus:border-2 focus:border-blue-700 ${newItemHidden ? 'hidden' : ''}`}
                id={`newClient_${type}`}
                label="Nome"
                required={true}
                size="small"
                onInput={(e) => setItemName({ ...itemName, name: e.target.value })}
              />

              <TextField
                // className={`border border-neutral-400 rounded-lg p-2 w-full focus:outline-none focus:border-2 focus:border-blue-700 ${newItemHidden ? 'hidden' : ''}`}
                label="Responsável"
                required={true}
                size="small"
                type="text"
                onInput={(e) => setItemName({ ...itemName, responsible: e.target.value })}
              />

              <TextField
                // className={`border border-neutral-400 rounded-lg p-2 w-full focus:outline-none focus:border-2 focus:border-blue-700 ${newItemHidden ? 'hidden' : ''}`}
                label="Email"
                required={true}
                size="small"
                type="email"
                onInput={(e) => setItemName({ ...itemName, email: e.target.value })}
              />

              <TextField
                // className={`border border-neutral-400 rounded-lg p-2 w-full focus:outline-none focus:border-2 focus:border-blue-700 ${newItemHidden ? 'hidden' : ''}`}
                label="Telefone"
                required={true}
                size="small"
                type="tel"
                onInput={(e) => setItemName({ ...itemName, phone: e.target.value })}
              />

              <TextField
                required={true}
                rows={4}
                onInput={(e) => setItemName({ ...itemName, info: e.target.value })}
                multiline
                // className={`border border-neutral-400 rounded-lg p-2 w-full max -h-28 min-h-28 focus:outline-none focus:border-2 focus:border-blue-700 ${newItemHidden ? 'hidden' : ''}`}
                label="Informações adicionais"
              />
            </div>
            <Button
              // className="rounded-lg bg-green-700 text-white p-2 text-center"
              color="success"
              type="submit"
              variant="outlined">
              <CheckIcon className="size-6 mx-auto" />
            </Button>
          </div>
        </form>
      </div>
      <div>
        <ul className="grid gap-1">
          {items.map((item) => (
            <li
              key={`${item.id}_${item.name}`}
              className="flex gap-3"
              onClick={() => {
                setActive(`${item.id}_${item.name}`)
              }}>
              <div
                className={`cursor-pointer w-full flex rounded duration-300 tabItem ${styles.tabItem} ${title + 'Item'}`}
                id={`${item.id}_${item.name}`}
                onClick={() => {
                  if (type === 'clients') {
                    requestData(item.id)
                    setItemId(item.id)
                    setClientToCheckPermissions(item)
                    reset(true)
                    resetActive()
                  }
                  if (type === 'sublist') {
                    setItemId(item.id)
                    request(item.id)

                    reset(true)
                  }
                }}>
                <div
                  className="size-2.5 my-auto rounded-full"
                  style={{ backgroundColor: `${item.color}` }}
                />
                <p className="truncate">{item.name}</p>
              </div>
              <div className="my-auto w-24">
                <div
                  className="p-2 mx-auto"
                  hidden={!loadingItems[item.id]}>
                  <svg
                    className="animate-spin size-5 mx-auto"
                    fill="none"
                    style={{ color: `${item.color}` }}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="flex gap-2">
                  {isAdmin && type === 'sublist' ? (
                    <div className="flex ml-auto my-auto">
                      <input
                        className="rounded-lg p-1 text-blue-700 duration-300 hover:bg-[#35208f] hover:text-white cursor-pointer hidden"
                        id={`file-upload-${item.id}`}
                        type="file"
                        onChange={(e) => {
                          uploadSheet(e)
                        }}
                        onClick={() => {
                          setItemId(item.id)
                        }}
                      />
                      <label
                        className="rounded-lg p-1 text-blue-700 duration-300 hover:bg-[#35208f] hover:text-white cursor-pointer"
                        hidden={loadingItems[item.id]}
                        htmlFor={`file-upload-${item.id}`}
                        title="Importar Planilha">
                        <ArrowUpTrayIcon className="size-5" />
                      </label>
                    </div>
                  ) : null}
                  {type !== 'sublist' && (isAdmin || (permissoes.clients?.some((userpermissions) => userpermissions.client === item.id) &&
                    permissoes.clients.find((userpermissions) => userpermissions.client === item.id).permissions.update === true)) ? (
                    <div hidden={loadingItems[item.id]}>
                      <IconButton
                        color="success"
                        onClick={() => {
                          getItemHistory(item.id).then(() => {
                            setOpenModal(!openModal)
                          })
                        }}>
                        <ClockIcon className="size-6 " />
                      </IconButton>
                    </div>
                  ) : null}

                  {isAdmin && type !== 'sublist' ? (
                    <div hidden={loadingItems[item.id]}>
                      <IconButton
                        color="primary"
                        hidden={loadingItems[item.id]}
                        onClick={() => {
                          setOpenInfoModal(!openInfoModal)
                          setClientInfo({ ...item })
                          setItemId(item.id)
                        }}>
                        <InformationCircleIcon className="size-6  " />
                      </IconButton>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Modal
        isOpen={openModal}
        shouldCloseOnOverlayClick={true}
        style={modalStyles}
        onRequestClose={() => {
          setOpenModal(!openModal)
        }}>
        <div className="size-full">
          <div className="flex justify-between py-2">
            <div className="my-auto">
              <p className="font-medium text-lg">Histórico de alterações</p>
            </div>
            <div>
              <button
                className="p-1.5 rounded-lg bg-rose-600 shadow-md shadow-rose-500 text-white hover:bg-rose-700 duration-300"
                title="Fechar modal"
                type="button"
                onClick={() => {
                  setOpenModal(!openModal)
                }}>
                <XMarkIcon className="size-6" />
              </button>
            </div>
          </div>
          <div className="px-3 overflow-auto h-full">
            {Object.keys(historyItems).map((itemName, index) => (
              <Accordion
                disableGutters={true}
                expanded={expanded === `panel${index}`}
                onChange={handleAccordionChange(`panel${index}`)}
                key={index}>
                <AccordionSummary className="bg-neutral-200 p-2 border-b border-neutral-300" expandIcon={<ChevronDownIcon className="size-6" />}>
                  {itemName}
                </AccordionSummary>
                <AccordionDetails className="padding-0">
                  <div className="size-full">
                    <table className="size-full table-auto tracking-tight">
                      <thead>
                      <tr className="border-b">
                        <th className="p-2 text-start">Alterado por</th>
                        <th className="p-2 text-start">Data</th>
                        <th className="p-2 text-start">Status</th>
                        <th className="p-2 text-start">Alterações</th>
                      </tr>
                      </thead>
                      <tbody>
                      {Object.values(historyItems[itemName]).map((item) => (
                        <tr
                          key={`${item.id}_${item.name}`}
                          className="odd:border-y">
                          <td className="p-2 text-start">{item.user}</td>
                          <td className="p-2 text-start">
                            {new Date(item.created_at).toLocaleDateString('pt-BR', {
                              year:   'numeric',
                              month:  'numeric',
                              day:    'numeric',
                              hour:   'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                            })}
                          </td>
                          <td className="p-2 text-start">
                            {Object.values(item.old_values).length === 0 ? (
                              <span className="bg-green-200 p-0.5 text-[10px] tracking-wide font-bold text-green-600 border border-green-600 rounded">Criado</span>
                            ) : (
                              <span className="bg-rose-200 p-0.5 text-[10px] tracking-wide font-bold text-rose-500 border border-rose-500 rounded">Alterado</span>
                            )}
                          </td>
                          <td>
                            <button
                              className="duration-300 bg-green-700 p-2 py-1 rounded-lg text-white font-medium hover:bg-green-800"
                              type="button"
                              onClick={() => {
                                setChanges({
                                  ...changes,
                                  old_values: { ...item.old_values },
                                  new_values: { ...item.new_values },
                                  date:       new Date(item.created_at).toLocaleDateString('pt-BR', {
                                    year:   'numeric',
                                    month:  'numeric',
                                    day:    'numeric',
                                    hour:   'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                  }),
                                  paths:      item.paths,
                                })

                                if (item.old_values.credentials) {
                                  decryptPwd(item.old_values, 'old_values').then()
                                  if (item.new_values?.credentials) {
                                    decryptPwd(item.new_values, 'new_values').then(() => {
                                      setOpenModalChanges(!openModalChanges)
                                    })
                                  }
                                } else {
                                  decryptPwd(item.new_values, 'new_values').then(() => {
                                    setOpenModalChanges(!openModalChanges)
                                  })
                                }
                              }}>
                              Visualizar
                            </button>
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openModalChanges}
        shouldCloseOnOverlayClick={true}
        style={modalStyles}
        onRequestClose={() => {
          setOpenModalChanges(!openModalChanges)
          setChanges({
            old_values: [],
            new_values: [],
            date:       '',
            paths:      '',
          })
          setDecryptedCreds({
            old_values: [],
            new_values: [],
          })
        }}>
        <div className="size-full">
          <div className="flex justify-between h-fit pb-6">
            <div className="my-auto">
              <p className="font-medium text-lg">Alterações do dia {changes.date}</p>
            </div>
            <div>
              <button
                className="p-1.5 rounded-lg bg-rose-600 shadow-md shadow-rose-500 text-white hover:bg-rose-700 duration-300"
                title="Fechar modal"
                type="button"
                onClick={() => {
                  setOpenModalChanges(!openModal)
                }}>
                <XMarkIcon className="size-6" />
              </button>
            </div>
          </div>
          <div className="h-[calc(100%-70px)] overflow-auto space-y-3 tracking-tight">
            {Object.values(changes.old_values).length > 0 ? (
              Object.keys(changes.old_values).map((values, index) => {
                if (values !== 'updated_by') {
                  return values === 'credentials' && changes.old_values[values] !== null ? (
                    <div
                      key={index}
                      className="w-full border border-neutral-300 rounded-lg">
                      <p className="p-2 font-medium border-b border-neutral-300">{translate[values]}</p>
                      <div className="flex w-full p-2">
                        <div className="w-2/5">
                          {decryptedCreds.old_values.map((item, index) => {
                            return (
                              <div key={index}>
                                <p>
                                  {item.user} - {item.password}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                        <div className="w-1/5 m-auto">
                          <ArrowRightIcon className="size-5 m-auto" />
                        </div>
                        <div className="w-2/5">
                          {decryptedCreds.new_values.map((item, index) => {
                            return (
                              <div key={index}>
                                <p>
                                  {item.user} - {item.password}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  ) : values === 'imagePath' ? (
                    <div
                      key={index}
                      className="w-full border border-neutral-300 rounded-lg">
                      <p className="p-2 font-medium border-b border-neutral-300">{translate[values]}</p>
                      <div className="flex w-full p-2">
                        <div className="w-2/5">{changes.old_values[values] ? changes.old_values[values].replaceAll(changes['paths'], '').replaceAll(',', '\r\n') : ''}</div>
                        <div className="w-1/5 m-auto">
                          <ArrowRightIcon className="size-5 m-auto" />
                        </div>
                        <p className="w-2/5">{changes.new_values[values] ? changes.new_values[values].replaceAll(changes['paths'], '\r\n').replaceAll(',', '\r\n') : ''}</p>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="w-full border border-neutral-300 rounded-lg">
                      <p className="p-2 font-medium border-b border-neutral-300">{translate[values]}</p>
                      <div className="flex w-full p-2">
                        <div className="w-2/5">{changes.old_values[values]}</div>
                        <div className="w-1/5 m-auto">
                          <ArrowRightIcon className="size-5 m-auto" />
                        </div>
                        <div className="w-2/5">{changes.new_values[values]}</div>
                      </div>
                    </div>
                  )
                }
              })
            ) : (
              <div className="w-full border border-neutral-300 rounded-lg">
                <p className="p-2 font-medium border-b border-neutral-300">
                  Item: <span className="font-normal">{changes.new_values['name']}</span>
                </p>
                <p className="p-2 font-medium border-b border-neutral-300">
                  Descrição: <span className="font-normal">{changes.new_values['info']}</span>
                </p>
                {changes.new_values['credentials'] && (
                  <div className="p-2 font-medium border-b border-neutral-300">
                    Credenciais:
                    <div className="flex w-full p-2">
                      <div className="w-2/5">
                        {changes.new_values['credentials'].map((item, index) => {
                          return (
                            <div key={index}>
                              {decryptedCreds.new_values.map((cred, index) => {
                                return (
                                  <div key={index}>
                                    <p className="font-normal">
                                      {cred.user} - {cred.password}
                                    </p>
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )}

                {changes.new_values['imagePath'] && (
                  <div className="p-2 font-medium border-b border-neutral-300">
                    Imagens:
                    <div className="flex w-full p-2">
                      <div className="w-2/5 font-normal">{changes.new_values['imagePath'].replaceAll(changes['paths'], '').replaceAll(',', '\r\n')}</div>
                    </div>
                  </div>
                )}
                {changes.new_values['external_port'] && (
                  <div className="p-2 font-medium border-b border-neutral-300">
                    Porta Externa: <span className="font-normal">{changes.new_values['external_port']}</span>
                  </div>
                )}
                {changes.new_values['internal_port'] && (
                  <div className="p-2 font-medium border-b border-neutral-300">
                    Porta Interna: <span className="font-normal">{changes.new_values['internal_port']}</span>
                  </div>
                )}
                {changes.new_values['ips'] && (
                  <div className="p-2 font-medium border-b border-neutral-300">
                    Ips:{' '}
                    {changes.new_values['ips'].map((ip, index) => {
                      return (
                        <div
                          className="font-normal px-2"
                          key={index}>
                          {ip}{' '}
                        </div>
                      )
                    })}
                  </div>
                )}

                {changes.new_values['password'] && <div className="p-2 font-medium border-b border-neutral-300">Senha: {changes.new_values['password']}</div>}
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openInfoModal}
        shouldCloseOnOverlayClick={true}
        style={modalStyles}
        onRequestClose={() => {
          setOpenInfoModal(!openInfoModal)
        }}>
        <div className="size-full">
          <div className="flex justify-between h-fit pb-6">
            <div className="my-auto">
              <p className="font-medium text-lg">Informações do cliente</p>
            </div>
            <div>
              <button
                className="p-1.5 rounded-lg bg-rose-600 shadow-md shadow-rose-500 text-white hover:bg-rose-700 duration-300"
                title="Fechar modal"
                type="button"
                onClick={() => {
                  setOpenInfoModal(!openInfoModal)
                }}>
                <XMarkIcon className="size-6" />
              </button>
            </div>
          </div>
          <div>
            <div className="w-full border border-neutral-300 rounded-lg">
              <p className="p-2 font-medium border-b border-neutral-300">Informações sobre o responsável</p>
              <div className="flex w-7/12 p-2">
                <div className="tracking-tight w-full grid gap-2">
                  <div className="flex justify-between">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        updateClientInfo('responsible', itemId)
                      }}>
                      <label htmlFor="info">
                        <b className="font-medium">Responsável: </b>
                      </label>
                      <input
                        className="w-auto p-1 border-b border-black/30"
                        id="info"
                        type="text"
                        value={clientInfo.responsible}
                        onInput={(e) => setClientInfo({ ...clientInfo, responsible: e.target.value })}
                      />
                    </form>
                    {/*<button*/}
                    {/*    className="inline-flex ml-2 size-5 border border-blue-700 rounded hover:bg-blue-700 duration-300"*/}
                    {/*    type="button">*/}
                    {/*    <PencilIcon className="size-full p-0.5 text-blue-800 flex hover:text-white" />*/}
                    {/*</button>*/}
                  </div>
                  <div className="flex justify-between">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        updateClientInfo('email', itemId)
                      }}>
                      <label htmlFor="info">
                        <b className="font-medium">Email: </b>
                      </label>
                      <input
                        className="w-auto p-1 border-b border-black/30"
                        id="info"
                        type="text"
                        value={clientInfo.email}
                        onInput={(e) => setClientInfo({ ...clientInfo, email: e.target.value })}
                      />
                    </form>
                    {/*<button*/}
                    {/*    className="inline-flex ml-2 size-5 border border-blue-700 rounded hover:bg-blue-700 duration-300"*/}
                    {/*    type="button">*/}
                    {/*    <PencilIcon className="size-full p-0.5 text-blue-800 flex hover:text-white" />*/}
                    {/*</button>*/}
                  </div>
                  <div className="flex justify-between">
                    {/*<b className="font-medium">Telefone:</b> {clientInfo.phone}*/}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        updateClientInfo('phone', itemId)
                      }}>
                      <label htmlFor="info">
                        <b className="font-medium">Telefone: </b>
                      </label>
                      <input
                        className="w-auto p-1 border-b border-black/30"
                        id="info"
                        type="text"
                        onInput={(e) => setClientInfo({ ...clientInfo, phone: e.target.value })}
                        value={clientInfo.phone}
                      />
                    </form>
                    {/*<button*/}
                    {/*    className="inline-flex ml-2 size-5 border border-blue-700 rounded hover:bg-blue-700 duration-300"*/}
                    {/*    type="button">*/}
                    {/*    <PencilIcon className="size-full p-0.5 text-blue-800 flex hover:text-white" />*/}
                    {/*</button>*/}
                  </div>
                  <div className="flex justify-between">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        updateClientInfo('info', itemId)
                      }}>
                      <label htmlFor="info">
                        <b className="font-medium">Informações adicionais: </b>
                      </label>
                      <input
                        className="w-auto p-1 border-b border-black/30"
                        id="info"
                        type="text"
                        value={clientInfo.info}
                        onInput={(e) => setClientInfo({ ...clientInfo, info: e.target.value })}
                      />
                    </form>
                    {/*<button*/}
                    {/*    className="inline-flex ml-2 size-5 border border-blue-700 rounded hover:bg-blue-700 duration-300"*/}
                    {/*    type="button">*/}
                    {/*    <PencilIcon className="size-full p-0.5 text-blue-800 flex hover:text-white" />*/}
                    {/*</button>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Tab
