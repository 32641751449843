import { Breadcrumbs, Container } from "@mui/material";
import Layout from "/src/components/Layouts/Layout.jsx";
import InventoryList from "../components/InventoryList/InventoryList.jsx";

const Inventory = () => {
  return (
    <Layout active="inventories">
      <Container maxWidth="xl">
        <div className="py-5 flex justify-between">
          <Breadcrumbs aria-label="breadcrumb">
            <span>Inventario</span>
          </Breadcrumbs>
        </div>
        <div>
          <InventoryList />
        </div>
      </Container>
    </Layout>
  );
};

export default Inventory;
