import React, { useState, useEffect, useContext } from 'react'
import { UsuarioContext } from '../../routes/Users.jsx'

const PermissionsTab = ({ tenant, tenantToEdit, switchCofre, nameTenantPermissions, setselectStatus, edit }) => {
    const { novoUsuario, setNovoUsuario } = useContext(UsuarioContext)
    const tenantId = tenantToEdit ?? tenant.id // tenant escolhido para receber permissoes
    const [permissions, setPermissions] = useState({
        vault: { view: true, create: false, update: false, delete: false },
        config: { view: true, update: false },
        table: { view: true, create: false, update: false, delete: false },
        evidences: { view: false, create: false, update: false, delete: false },
        inventory: { view: false, create: false, update: false, delete: false },
        reports: { view: true, create: false, update: false, delete: false },
        users: { view: true, create: false, update: false, delete: false }
    })
    //----------------------------------------------------------------------------------------------------------------------------------------------
    //EXIBE OS CHECKBOX MARCADOS CONFORME AS PERMISSOES JA EXISTENTES DO USUARIO
    const [checkBoxValue, setCheckBoxValue] = useState({})

    useEffect(() => {
        if (switchCofre) {
            const user = novoUsuario.clients.find((t) => t.client === tenantToEdit)
            if (user) {
                setCheckBoxValue(user.permissions)
            }
        } else {
            const user = novoUsuario.tenants.find((t) => t.tenant === tenantToEdit)
            if (user) {
                setCheckBoxValue(user.permissions)
            }
        }
    }, [switchCofre])

    const [userInfo, setUserInfo] = useState({
        permissions: {
            vault: { view: false, create: false, update: false, delete: false },
            config: { view: false, update: false },
            table: { view: false, create: false, update: false, delete: false },
            evidences: { view: false, create: false, update: false, delete: false },
            inventory: { view: false, create: false, update: false, delete: false },
            reports: { view: false, create: false, update: false, delete: false },
            users: { view: false, create: false, update: false, delete: false }
        }
    })

    useEffect(() => {
        //verifica switchCofre pra exibir apenas o necessario, cofre ou acronis
        if (switchCofre) {
            //Ao editar mostra o checkbox ja marcado ou nao conforme as permissoes ja existentes
            setUserInfo(() => ({
                permissions: {
                    vault: {
                        view: checkBoxValue?.view || false,
                        create: checkBoxValue?.create || false,
                        update: checkBoxValue?.update || false,
                        delete: checkBoxValue?.delete || false
                    }
                }
            }))
        } else {
            setUserInfo(() => ({
                permissions: {
                    vault: {
                        view: checkBoxValue.vault?.view || false,
                        create: checkBoxValue.vault?.create || false,
                        update: checkBoxValue.vault?.update || false,
                        delete: checkBoxValue.vault?.delete || false
                    },
                    config: {
                        view: checkBoxValue.config?.view || false,
                        update: checkBoxValue.config?.update || false
                    },
                    table: {
                        view: checkBoxValue.table?.view || false,
                        create: checkBoxValue.table?.create || false,
                        update: checkBoxValue.table?.update || false,
                        delete: checkBoxValue.table?.delete || false
                    },
                    evidences: {
                        view: checkBoxValue.evidences?.view || false,
                        create: checkBoxValue.evidences?.create || false,
                        update: checkBoxValue.evidences?.update || false,
                        delete: checkBoxValue.evidences?.delete || false
                    },
                    inventory: {
                        view: checkBoxValue.inventory?.view || false,
                        create: checkBoxValue.inventory?.create || false,
                        update: checkBoxValue.inventory?.update || false,
                        delete: checkBoxValue.inventory?.delete || false
                    },
                    reports: {
                        view: checkBoxValue.reports?.view || false,
                        create: checkBoxValue.reports?.create || false,
                        update: checkBoxValue.reports?.update || false,
                        delete: checkBoxValue.reports?.delete || false
                    },
                    users: {
                        view: checkBoxValue.users?.view || false,
                        create: checkBoxValue.users?.create || false,
                        update: checkBoxValue.users?.update || false,
                        delete: checkBoxValue.users?.delete || false
                    }
                }
            }))
        }
    }, [checkBoxValue])
    //----------------------------------------------------------------------------------------------------------------------------------------------
    // Verifica e marca os checkbox ao selecionar o cargo no select
    const handlePermissionChange = (event) => {
        const role = event.target.value
        const permissionsMap = {
            admin: {
                vault: { view: true, create: true, update: true, delete: true },
                config: { view: true, update: true },
                table: { view: true, create: true, update: true, delete: true },
                evidences: { view: true, create: true, update: true, delete: true },
                inventory: { view: true, create: true, update: true, delete: true },
                reports: { view: true, create: true, update: true, delete: true },
                users: { view: true, create: true, update: true, delete: true }
            },
            user: {
                vault: { view: true, create: false, update: false, delete: false },
                config: { view: true, update: false },
                table: { view: true, create: false, update: false, delete: false },
                evidences: { view: false, create: false, update: false, delete: false },
                inventory: { view: false, create: false, update: false, delete: false },
                reports: { view: true, create: false, update: false, delete: false },
                users: { view: true, create: false, update: false, delete: false }
            },
            'group-admin': {
                vault: { view: true, create: true, update: true, delete: true },
                config: { view: true, update: false },
                table: { view: true, create: false, update: false, delete: false },
                evidences: { view: true, create: true, update: false, delete: false },
                inventory: { view: true, create: true, update: true, delete: false },
                reports: { view: false, create: false, update: false, delete: false },
                users: { view: true, create: true, update: true, delete: true }
            },
            evidencer: {
                vault: { view: false, create: false, update: false, delete: false },
                config: { view: true, update: false },
                table: { view: false, create: false, update: false, delete: false },
                evidences: { view: true, create: true, update: true, delete: false },
                inventory: { view: false, create: false, update: false, delete: false },
                reports: { view: false, create: false, update: false, delete: false },
                users: { view: false, create: false, update: false, delete: false }
            },
            none: {
                vault: { view: false, create: false, update: false, delete: false },
                config: { view: false, update: false },
                table: { view: false, create: false, update: false, delete: false },
                evidences: { view: false, create: false, update: false, delete: false },
                inventory: { view: false, create: false, update: false, delete: false },
                reports: { view: false, create: false, update: false, delete: false },
                users: { view: false, create: false, update: false, delete: false }
            }
        }

        //se o select for 'none' nao exibe o botao em "Permissions"
        if (!edit) {
            if (role === 'none') {
                setselectStatus(false)
            } else {
                setselectStatus(true)
            }
        }

        //----------------------------------------------------------------------------------------------------------------------------------------------
        // Atualiza 'novoUsuario' no componente "Users" com tenants e suas permissoes
        const newPermissions = permissionsMap[role] || {}

        setNovoUsuario((prevState) => {
            const updatedTenants = !switchCofre
                ? prevState.tenants.map((permission) =>
                      permission.tenant === tenantId
                          ? {
                                ...permission,
                                permissions: newPermissions // Atualiza as permissões para o tenant específico
                            }
                          : permission
                  )
                : prevState.tenants // Mantém a lista de tenants inalterada se switchCofre for true

            const updatedClients = switchCofre
                ? prevState.clients?.map((permission) =>
                      permission.client === tenantId
                          ? {
                                ...permission,
                                permissions: newPermissions.vault // Atualiza as permissões para o cliente específico
                            }
                          : permission
                  )
                : prevState.clients // Mantém a lista de clients inalterada se switchCofre for false

            return {
                ...prevState,
                tenants: updatedTenants,
                clients: updatedClients
            }
        })

        //----------------------------------------------------------------------------------------------------------------------------------------------

        setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            permissions: newPermissions
        }))
    }

    //ATUALIZA OS ESTADOS PELOS CHECKBOX DE FORMA INDIVIDUAL
    const handleCheckboxChange = (section, permType) => {
        // Atualiza o estado do usuário, usando a função setUserInfo e passando uma função de callback
        setUserInfo((prevUserInfo) => {
            // Cria um novo objeto de permissões atualizado
            const updatedPermissions = {
                // Mantém as permissões existentes
                ...prevUserInfo.permissions,
                // Atualiza a permissão para a seção específica
                [section]: {
                    // Mantém as permissões existentes para a seção
                    ...prevUserInfo.permissions[section],
                    // Inverte o valor da permissão específica (liga/desliga)
                    [permType]: !prevUserInfo.permissions[section]?.[permType]
                }
            }

            // Verifica e marca os checkbox ao selecionar checkbox individalmente
            setNovoUsuario((prevState) => {
                const updatedTenants = !switchCofre
                    ? prevState.tenants.map((permission) =>
                          permission.tenant === tenantId
                              ? {
                                    ...permission,
                                    permissions: updatedPermissions // Atualiza as permissões para o tenant específico
                                }
                              : permission
                      )
                    : prevState.tenants // Mantém a lista de tenants inalterada se switchCofre for true

                const updatedClients = switchCofre
                    ? prevState.clients.map((permission) =>
                          permission.client === tenantId
                              ? {
                                    ...permission,
                                    permissions: updatedPermissions.vault // Atualiza as permissões para o cliente específico
                                }
                              : permission
                      )
                    : prevState.clients // Mantém a lista de clients inalterada se switchCofre for false

                return {
                    ...prevState,
                    tenants: updatedTenants,
                    clients: updatedClients
                }
            })

            return {
                ...prevUserInfo,
                permissions: updatedPermissions
            }
        })
    }

    //ATUALIZA PERMISSOES
    useEffect(() => {
        setPermissions(userInfo.permissions)
    }, [userInfo.permissions])

    // variavel para exibir quem estou criando ou editando
    const Name = tenant && tenant.name ? tenant.name : tenant && tenant.tenant_name ? tenant.tenant_name : nameTenantPermissions

    return (
        <div className="h-full rounded-t-lg grid grid-rows-12 grid-cols-12">
            <h1 className="font-semibold col-span-12 flex items-center justify-center">Permissões para {Name}</h1>
            <div className="row-start-2 row-span-11 col-span-12 border">
                <div className="border-t-2 border-b-2 border bg-white row-start-2 row-span-10 col-span-12 text-gray-600 font-semibold">
                    <div className="p-1 bg-neutral-100">
                        <select
                            className="w-52 h-10 px-2 font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                            defaultValue="none"
                            onChange={handlePermissionChange}>
                            <option value="none">Selecione</option>
                            <option value="user">Usuário</option>
                            <option value="admin">Administrador</option>
                            {!switchCofre ? (
                                <>
                                    <option value="group-admin">Administrador do grupo</option>
                                    <option value="evidencer">Evidenciador</option>
                                </>
                            ) : null}
                        </select>
                    </div>

                    {switchCofre ? (
                        <div className="grid grid-cols-12 h-8 bg-white">
                            <div className="p-2 col-start-1">
                                <p>Cofre:</p>
                            </div>
                            <div className="flex flex-row col-start-3">
                                <div className="col-start-3 p-2 ml-8">
                                    <input
                                        checked={permissions.vault?.view || false}
                                        className="cursor-pointer"
                                        id="checkboxCofreView"
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange('vault', 'view')}
                                    />
                                </div>
                                <div className="py-2 col-start-4">
                                    <label
                                        className="hover:cursor-pointer"
                                        htmlFor="checkboxCofreView">
                                        ver
                                    </label>
                                </div>
                                <div className="col-start-4 p-2 ml-6">
                                    <input
                                        checked={permissions.vault?.create || false}
                                        className="ml-6 cursor-pointer"
                                        id="checkboxCofreCreate"
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange('vault', 'create')}
                                    />
                                </div>
                                <div className="py-2 col-start-5">
                                    <label
                                        className="hover:cursor-pointer"
                                        htmlFor="checkboxCofreCreate">
                                        criar
                                    </label>
                                </div>
                                <div className="col-start-4 p-2 ml-6">
                                    <input
                                        checked={permissions.vault?.update || false}
                                        className="ml-6 cursor-pointer"
                                        id="checkboxCofreEdit"
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange('vault', 'update')}
                                    />
                                </div>
                                <div className="py-2 col-start-5">
                                    <label
                                        className="hover:cursor-pointer"
                                        htmlFor="checkboxCofreEdit">
                                        editar
                                    </label>
                                </div>
                                <div className="col-start-4 p-2 ml-6">
                                    <input
                                        checked={permissions.vault?.delete || false}
                                        className="ml-6 cursor-pointer"
                                        id="checkboxCofreDelete"
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange('vault', 'delete')}
                                    />
                                </div>
                                <div className="py-2 col-start-5">
                                    <label
                                        className="hover:cursor-pointer"
                                        htmlFor="checkboxCofreDelete">
                                        apagar
                                    </label>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {!switchCofre === true ? (
                        <>
                            <div className="grid grid-cols-12 h-8 bg-neutral-100">
                                <div className="p-2 col-start-1">
                                    <p>Dashboard:</p>
                                </div>
                                <div className="flex flex-row col-start-3">
                                    <div className="col-start-3 p-2 ml-8">
                                        <input
                                            checked={permissions.table?.view || false}
                                            className="cursor-pointer"
                                            id="checkboxDashboardView"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('table', 'view')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-4">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxDashboardView">
                                            ver
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.table?.create || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxDashboardCreate"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('table', 'create')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxDashboardCreate">
                                            criar
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.table?.update || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxDashboardUpdate"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('table', 'update')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxDashboardUpdate">
                                            editar
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.table?.delete || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxDashboardDelete"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('table', 'delete')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxDashboardDelete">
                                            apagar
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 h-8 bg-white">
                                <div className="p-2 col-start-1">
                                    <p>Usuários:</p>
                                </div>
                                <div className="flex flex-row col-start-3">
                                    <div className="col-start-3 p-2 ml-8">
                                        <input
                                            checked={permissions.users?.view || false}
                                            className="cursor-pointer"
                                            id="checkboxUserView"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('users', 'view')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-4">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxUserView">
                                            ver
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.users?.create || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxUsersCreate"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('users', 'create')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxUsersCreate">
                                            criar
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.users?.update || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxUsersEdit"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('users', 'update')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxUsersEdit">
                                            editar
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.users?.delete || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxUsersDelete"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('users', 'delete')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxUsersDelete">
                                            apagar
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 h-8 bg-neutral-100">
                                <div className="p-2 col-start-1">
                                    <p>Relatórios:</p>
                                </div>
                                <div className="flex flex-row col-start-3">
                                    <div className="col-start-3 p-2 ml-8">
                                        <input
                                            checked={permissions.reports?.view || false}
                                            className="cursor-pointer"
                                            id="checkboxreportsView"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('reports', 'view')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-4">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxreportsView">
                                            ver
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.reports?.create || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxreportsCreate"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('reports', 'create')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxreportsCreate">
                                            criar
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.reports?.update || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxreportsEdit"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('reports', 'update')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxreportsEdit">
                                            editar
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.reports?.delete || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxreportsDelete"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('reports', 'delete')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxreportsDelete">
                                            apagar
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 h-8 bg-neutral-100">
                                <div className="p-2 col-start-1">
                                    <p>Evidências:</p>
                                </div>
                                <div className="flex flex-row col-start-3">
                                    <div className="col-start-3 p-2 ml-8">
                                        <input
                                            checked={permissions.evidences?.view || false}
                                            className="cursor-pointer"
                                            id="checkboxEvidenceView"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('evidences', 'view')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-4">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxEvidenceView">
                                            ver
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.evidences?.create || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxEvidenseCreate"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('evidences', 'create')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxEvidenseCreate">
                                            criar
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.evidences?.update || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxEvidenceEdit"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('evidences', 'update')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxEvidenceEdit">
                                            editar
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.evidences?.delete || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxEvidenceDelete"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('evidences', 'delete')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxEvidenceDelete">
                                            apagar
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 h-8 bg-white">
                                <div className="p-2 col-start-1">
                                    <p>Inventários:</p>
                                </div>
                                <div className="flex flex-row col-start-3">
                                    <div className="col-start-3 p-2 ml-8">
                                        <input
                                            checked={permissions.inventory?.view || false}
                                            className="cursor-pointer"
                                            id="checkboxInventoriesView"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('inventory', 'view')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-4">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxInventoriesView">
                                            ver
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.inventory?.create || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxInventoriesCreate"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('inventory', 'create')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxInventoriesCreate">
                                            criar
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.inventory?.update || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxInventoriesEdit"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('inventory', 'update')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxInventoriesEdit">
                                            editar
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.inventory?.delete || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxInventoriesDelete"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('inventory', 'delete')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxInventoriesDelete">
                                            apagar
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 h-8 bg-neutral-100">
                                <div className="p-2 col-start-1">
                                    <p>Configuração:</p>
                                </div>
                                <div className="flex flex-row col-start-3">
                                    <div className="col-start-3 p-2 ml-8">
                                        <input
                                            checked={permissions.config?.view || false}
                                            className="cursor-pointer"
                                            id="checkboxConfigView"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('config', 'view')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-4">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxConfigView">
                                            ver
                                        </label>
                                    </div>
                                    <div className="col-start-4 p-2 ml-6">
                                        <input
                                            checked={permissions.config?.update || false}
                                            className="ml-6 cursor-pointer"
                                            id="checkboxConfigEdit"
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange('config', 'update')}
                                        />
                                    </div>
                                    <div className="py-2 col-start-5">
                                        <label
                                            className="hover:cursor-pointer"
                                            htmlFor="checkboxConfigEdit">
                                            editar
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default PermissionsTab
