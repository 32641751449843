import Sidebar from "/src/components/Sidebar/Sidebar.jsx";

const Layout = ({ children, active }) => {
  return (
    <div className="flex w-dvw h-dvh overflow-hidden tracking-tighter">
      <aside className="w-24 bg-gray-100 ">
        <Sidebar active={active} />
      </aside>
      <div className="w-full bg-gray-50">{children}</div>
    </div>
  );
};

export default Layout;
