import React, { useContext, useEffect, useRef, useState } from "react";
import {
  TextField,
  IconButton,
  Button,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  Paper,
  TableBody,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AxiosConfigs from "../configs.js";
import { toast } from "react-hot-toast";
import { TrashIcon } from "@heroicons/react/24/outline/index.js";
import { InventoryClient } from "../context/InventoryClient.jsx";
import { motion } from "framer-motion";

const ClientList = ({ clients }) => {
  // const [search, setSearch]               = useState('')
  // const [cliente, setCliente]             = useState([])
  const [credentials, setCredentials] = useState();
  const [emails, setEmails] = useState([""]);
  const [openCadastrar, setOpenCadastrar] = useState(false);
  const [acronisClients, setAcronisClients] = useState([]);
  const modalRef = useRef(null);
  const { setInventoryClient } = useContext(InventoryClient);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
  };

  //-------------------------------------------------------------MODAL CRIAR USUARIO-----------------------------------------------------------------------------

  // const handleClose = () => {
  //   setCredentials(null);
  //   setEmails([""]);
  //   setOpenCadastrar(!openCadastrar);
  // };
  //------------------------------------------------------------------------------------------------------------------------------------------------------------

  // const crateUser = () => {
  //   AxiosConfigs.post('/inventory/client', credentials).then((r) => {
  //     if (r.status === 201) {
  //       toast.success('Cliente Adicionado')
  //       getClients().then(() => {
  //         handleClose()
  //       })
  //     } else {
  //       toast.error('Erro ao adicionar cliente')
  //     }
  //   }).catch(function (error) {
  //     toast.error(error.message)
  //   })
  // }

  // useEffect(() => {
  //   setCredentials((prevState) => ({
  //     ...prevState,
  //     emails: emails.length > 0 ? emails : [],
  //   }))
  // }, [emails])

  // const handleEmailChange = (index, value) => {
  //   const updatedEmails  = [...emails]
  //   updatedEmails[index] = value
  //   setEmails(updatedEmails)
  // }

  // const addEmailField = () => {
  //   setEmails([...emails, '']) // Adiciona um novo campo de e-mail
  // }

  // const removeEmailField = (index) => {
  //   const updatedEmails = emails.filter((_, i) => i !== index)
  //   setEmails(updatedEmails)
  // }

  // const getAcronisClients = async() => {
  //   AxiosConfigs.get('/tenants').then((response) => {
  //     let alphabethicalSorted = response.data.sort((a, b) => {
  //       if (a.name) return a.name.localeCompare(b.name)
  //     })
  //     setAcronisClients(alphabethicalSorted)
  //     console.log(alphabethicalSorted)
  //   }).catch(function (error) {
  //     toast.error(error.message)
  //   })
  // }

  return (
    <div>
      <div className="pb-5">
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            getAcronisClients().then(() => {
              setOpenCadastrar(!openCadastrar);
            });
          }}
        >
          Adicionar
        </Button>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Table size="small" className="shadow bg-white">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 600,
                }}
              >
                Nome
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client, index) => {
              return (
                <TableRow
                  className="odd:bg-neutral-50 hover:bg-neutral-100 cursor-pointer"
                  key={index}
                  onClick={() => setInventoryClient(client)}
                >
                  <TableCell>{client.company_name}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </motion.div>
    </div>
  );
};

export default ClientList;
