import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AxiosConfigs from "../configs.js";
import { toast } from "react-hot-toast";
import { Button, TextField } from "@mui/material";

const RecuperaSenha = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [credential, setCredential] = useState({
    email: "",
  });

  const recuperar = () => {
    setLoading(true);
    AxiosConfigs.post(`/forgot-password/`, credential)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div className="flex items-center justify-center bg-neutral-50 min-h-screen">
      <div className="flex items-center flex-col justify-start bg-white border border-blue-200 h-auto min-h-[60vh] w-11/12 sm:w-3/4 md:w-2/3 lg:w-2/4 rounded-2xl shadow-lg shadow-blue-200 p-6">
        <h1 className="mt-12 font-semibold text-2xl">Recuperar Senha</h1>
        <form
          className="mt-12 flex flex-col w-full sm:w-5/6 md:w-4/6 lg:w-3/6"
          onSubmit={(e) => {
            e.preventDefault();
            recuperar();
          }}
        >
          <div className="flex flex-col my-auto size-full">
            <TextField
              className="my-auto"
              id="email"
              required={true}
              label="Email"
              size="medium"
              type="email"
              value={credential.email}
              onChange={(e) =>
                setCredential({ ...credential, email: e.target.value })
              }
            />
          </div>

          <div className="mt-16">
            <div className="flex items-center justify-center mt-8">
              <Button
                // className="flex gap-5 items-center bg-[#35208f] py-3 px-16 rounded-xl text-white hover:shadow-lg hover:shadow-blue-200 font-semibold hover:bg-blue-800 transition-all duration-200"
                type="submit"
                variant="contained"
                size="large"
              >
                Enviar
                <div className={loading ? "" : "hidden"}>
                  <svg
                    className="animate-spin size-5 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </Button>
            </div>
            <div className="mt-8 flex items-center justify-center text-blue-900">
              <Button
                variant="text"
                onClick={() => {
                  navigate("/");
                }}
              >
                Voltar
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default RecuperaSenha;
