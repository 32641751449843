import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'

const Grafico = ({ dados }) => {
    const graficoCanvasRef = useRef(null)
    const chartInstanceRef = useRef(null)

    useEffect(() => {
        drawChart()
        return () => {
            // Limpa o gráfico ao desmontar o componente
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy()
                chartInstanceRef.current = null
            }
        }
    }, [dados])

    const drawChart = () => {
        if (graficoCanvasRef.current && dados) {
            const ctx = graficoCanvasRef.current.getContext('2d')

            // Dados específicos para o gráfico (exemplo: gráfico de pizza)
            const labels = dados.maquinas.map((maquina) => maquina.nome)
            const data = dados.maquinas.map((maquina) => maquina.quantidadeErros)
            const backgroundColors = dados.maquinas.map(() => randomColor())

            // Configuração do gráfico
            const chartConfig = {
                type: 'pie', // Tipo de gráfico (substitua conforme necessário)
                data: {
                    labels: labels,
                    datasets: [
                        {
                            data: data,
                            backgroundColor: backgroundColors
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            }

            // Limpa o canvas antes de desenhar o novo gráfico
            ctx.clearRect(0, 0, graficoCanvasRef.current.width, graficoCanvasRef.current.height)

            // Cria um novo gráfico ou atualiza o existente
            if (chartInstanceRef.current) {
                chartInstanceRef.current.data = chartConfig.data
                chartInstanceRef.current.update()
            } else {
                chartInstanceRef.current = new Chart(ctx, chartConfig)
            }
        }
    }

    // Função para gerar uma cor hexadecimal aleatória
    const randomColor = () => {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)]
        }
        return color
    }

    return (
        <div>
            <canvas
                ref={graficoCanvasRef}
                height="400"
                width="400"></canvas>
        </div>
    )
}

export default Grafico
