import axios from 'axios'
import Cookies from 'universal-cookie'

const cookie = new Cookies()
// const pendingRequests = new Map()

const AxiosConfigs = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_URL,
    // withXSRFToken: true,
    // withCredentials: true,
    headers: {
        Authorization: cookie.get('user')
    }
})

// AxiosConfigs.interceptors.request.use(
//     (config) => {
//         const requestIdentifier = `${config.url}_${config.method}`

//         // Check if there is already a pending request with the same identifier
//         if (pendingRequests.has(requestIdentifier)) {
//             // Cancel the new request by returning a rejected promise
//             return Promise.reject(new axios.Cancel('Request cancelled: already pending request exists.'))
//         }

//         // Create a new CancelToken
//         const newCancelTokenSource = axios.CancelToken.source()
//         config.cancelToken = newCancelTokenSource.token

//         // Store the new cancel token source in the map
//         pendingRequests.set(requestIdentifier, newCancelTokenSource)
//         return config
//     },
//     (error) => {
//         // Return the error if the request fails
//         return Promise.reject(error)
//     }
// )

// AxiosConfigs.interceptors.response.use(
//     (response) => {
//         const requestIdentifier = `${response.config.url}_${response.config.method}`
//         // Remove the request from the pending requests map
//         pendingRequests.delete(requestIdentifier)
//         return response
//     },
//     (error) => {
//         if (error.config) {
//             const requestIdentifier = `${error.config.url}_${error.config.method}`
//             // Remove the request from the pending requests map
//             pendingRequests.delete(requestIdentifier)
//         }
//         return Promise.reject(error)
//     }
// )

export default AxiosConfigs
