import Layout from '/src/components/Layouts/Layout.jsx'
import { PencilSquareIcon, TrashIcon, ExclamationTriangleIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline/index.js'
import Modal from 'react-modal'
import React, { createContext, Fragment, useEffect, useState } from 'react'
import AxiosConfigs from '../configs.js'
import { toast } from 'react-hot-toast'
import Permissions from '../components/Permissions/Permissions.jsx'
import PermissionsTab from '../components/Permissions/PermissionsTab.jsx'
import { useCookies } from 'react-cookie'

// contexo "Variavel GLOBAL" pra usar "novoUsuario" em Users, Permissions e permissionsTab
export const UsuarioContext = createContext({})

const Users = () => {
  //permissoes recebidas ao fazer o login
  const permissoes = JSON.parse(localStorage.getItem('localPermissions'))
  //verifica se é usuario nagix para mostrar conteudo
  const [cookies]  = useCookies('is_admin')
  const isAdmin    = cookies.is_admin

  const [modalPermissionsIsOpen, setlPermissionsIsOpen] = useState(false)
  const [edit, setEdit]                                 = useState(false)
  const [userInfo, setUserInfo]                         = useState({})
  const [users, setUsers]                               = useState([])
  const [loading, setLoading]                           = useState([])
  const [userEmail, setUserEmail]                       = useState('')

  //USUARIO MONTADO TANTO PARA CRIAÇÃO E EDIÇAO, usado para criar editar e criar permissoes
  const [novoUsuario, setNovoUsuario] = useState({
    email:   '',
    name:    '',
    tenants: [],
  })

  //----------------------------------------------------------------------------------------------------------------------------------------------
  //verifica se é usuario nagix para criar sem as permissoes "mostra o btn cadastrar"
  const nagixEmail = novoUsuario.email?.includes('@nagix.com.br') ? true : false

  useEffect(() => {
    AxiosConfigs.get('/users/info').then((response) => {
      setUserEmail(response.data)
    }).catch(function (error) {

    })
  }, [])
  //----------------------------------------------------------------------------------------------------------------------------------------------
  // componente de LOAD
  const loadSpinner                            = (color, size, key) => (
    <div
      key={key}
      className={loading[0] ? '' : 'hidden'}>
      <svg
        className={`animate-spin ${size} ${color}`}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
  //----------------------------------------------------------------------------------------------------------------------------------------------
  const [modalIsOpenExcluir, setIsOpenExcluir] = useState(false)

  function openModal () {
    setIsOpenExcluir(true)
  }

  function closeModal () {
    setIsOpenExcluir(false)
  }

  function openPermissionsModal () {
    setlPermissionsIsOpen(true)
  }

  function closePermissionsModal () {
    setlPermissionsIsOpen(false)
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------
  const [modalIsOpenConfirmDelete, setIsOpenConfirmDelete] = React.useState(false)

  function openModal4 () {
    setIsOpenConfirmDelete(true)
  }

  function closeModal4 () {
    setIsOpenConfirmDelete(false)
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------
  const [modalIsOpenEditUser, setIsOpenEditUser] = React.useState(false)

  function openModal2 () {
    setIsOpenEditUser(true)
  }

  function afterOpenModal2 () {
    setLoading([true, 'userEdit'])
  }

  function closeModal2 () {
    setIsOpenEditUser(false)
    setNovoUsuario({
      email:   '',
      name:    '',
      tenants: [],
    })
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------
  const [modalIsOpenCheckPermissions, setIsOpenCheckPermissions] = React.useState(false)

  function openModal3 () {
    setIsOpenCheckPermissions(true)
  }

  function closeModal3 () {
    setIsOpenCheckPermissions(false)
    setPermissoesEditadas([])
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------

  const [tenantToDelete, setTenantToDelete] = useState(null)
  const deleteTenant                        = () => {
    //Encontro o tenant nos meus tenants e o remove da lista
    setNovoUsuario((prevState) => {
      const updatedTenants = prevState.tenants.filter((tenant) => tenant.tenant !== tenantToDelete.tenant)
      return {
        ...prevState,
        tenants: updatedTenants,
      }
    })
    closeModal4()
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------
  const getUser = async(user) => {
    setLoading([true, 'getUser'])
    try {
      const response = await AxiosConfigs.get(`/users/user/${user.id}`)
      setLoading([false, 'userEdit'])
      setNovoUsuario((prevState) => ({
        ...prevState,
        ...response.data, // Atualiza o estado com as novas informações
      }))
    } catch (err) {
      // Adiciona tratamento de erros, se necessário
      console.error('Erro ao obter usuário:', err)
      toast.error('Erro ao obter usuário')
    } finally {
      setLoading([false, 'getUser']) // Corrigido para refletir a operação correta
    }
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------
  const getUsers = async() => {
    setLoading([true, 'getUsers'])
    try {
      const response = await AxiosConfigs.get('/users')
      setUsers(response.data)
    } catch (err) {
      // Adiciona tratamento de erros, se necessário
      console.error('Erro ao obter usuários:', err)
      toast.error('Erro ao obter usuários')
    } finally {
      setLoading([false, 'getUsers'])
    }
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------
  const createUser = async() => {
    setLoading([true, 'createUser'])
    await AxiosConfigs.post('/users', novoUsuario).then(() => {
      toast.success('Usuario criado com sucesso!')
      setNovoUsuario({
        email:   '',
        name:    '',
        tenants: [],
      })
      getUsers().then()
    }).catch((err) => {
      toast.error(err.response.data.message)
      setLoading([false, 'createUser'])
    })
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------
  const idUser   = userInfo.id
  const editUser = async() => {
    setLoading([true, 'getUsers'])

    try {
      await AxiosConfigs.put(`/users/${idUser}`, novoUsuario)
      toast.success('Usuario editado com sucesso!')
      setNovoUsuario({
        email:   '',
        name:    '',
        tenants: [],
      })
      closeModal2()
      setTenantToEdit('')
      await getUsers()
    } catch (err) {
      console.error('Erro ao obter usuários:', err)
      toast.error('Erro ao obter usuário')
    } finally {
      setLoading([false, 'getUsers'])
    }
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------

  const [userToDelete, setUserToDelete] = useState('')
  const deleteUser                      = async() => {
    AxiosConfigs.delete(`/users/${userToDelete.id}`).then((res) => {

      if (res.status === 204) {
        toast.success('Usuario excluido com sucesso!')
      }
    }).catch((err) => {
      if (err.response.data.code === '23503') {
        toast.error('Erro ao excluir usuario, existem dados vinculados a ele')
      } else {
        toast.error('Erro ao excluir usuario')
      }
    }).finally(() => {
      getUsers().then(closeModal)
    })
  }

  useEffect(() => {
    getUsers().then()
  }, [])

  //----------------------------------------------------------------------------------------------------------------------------------------------

  const [userToEdit, setUserToEdit]   = useState(null)
  const [newUser, setNewUser]         = useState(true)
  const [userEditado, setUserEditado] = useState({})

  const salvarEdicao = () => {
    setUserToEdit((prevState) => {
      const updatedState = {
        ...prevState,
        permissions: userEditado,
      }
      closeModal3()
      return updatedState
    })
  }
  //----------------------------------------------------------------------------------------------------------------------------------------------
  //VARIAVEIS PARA EDICAO
  const [permissoesEditadas, setPermissoesEditadas]       = useState({})
  const [setAarrayPermissoesEditadas]                     = useState([])
  const [tenantToEdit, setTenantToEdit]                   = useState('')
  const [nameTenantPermissions, setNameTenantPermissions] = useState('')

  const permissoesTenantArray         = Array.isArray(permissoes.tenants) ? permissoes.tenants : []
  //Verifica se algum tem permissao em user para criar para exibir
  const userSomeCreate                = permissoesTenantArray.some((permissao) => permissao.permissions.users?.create === true)
  //Variavel para saber se estou trabalhando com acronis ou cofre
  const [switchCofre, setSwitchCofre] = useState(false)

  return (
    <Layout active="users">
      <Modal
        ariaHideApp={false}
        className=" grid grid-rows-12 grid-cols-12 border border-blue-300 rounded-lg w-5/6 h-5/6 absolute top-2/4 left-2/4 right-auto bottom-auto -mr-2/4 -translate-x-2/4 -translate-y-2/4 bg-white shadow-lg shadow-blue-200"
        contentLabel="Example Modal"
        isOpen={modalIsOpenEditUser}
        onAfterOpen={afterOpenModal2}
        onRequestClose={closeModal2}>
        {!newUser ? (
          loading[0] && loading[1] === 'userEdit' ? (
            <div className="row-start-1 row-span-11 col-start1 col-span-12 size-full grid place-content-center place-items-center">{loadSpinner('text-blue-900', 'size-16', 'users')}</div>
          ) : (
            <>
              <div className=" text-center text-gray-500 text-lg text-nowrap row-start-1 col-span-12 place-content-center pt-1">
                <div className=" h-full flex items-center justify-evenly row-start-1">
                  <div className="">
                    <input
                      className="w-ful h-10 p-2 font-sans text-gray-600 bg-white border border-gray-300 transition-colors
                                              duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                      type="text"
                      value={novoUsuario.name}
                      onChange={(e) => setNovoUsuario({ ...novoUsuario, name: e.target.value })}
                    />
                  </div>
                  <div>
                    <input
                      className="w-full h-10 p-2 font-sans text-gray-600 bg-white border border-gray-300 transition-colors
                                              duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                      type="text"
                      value={novoUsuario.email}
                      onChange={(e) => setNovoUsuario({ ...novoUsuario, email: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-white row-start-2 col-span-12 row-span-10 border grid grid-rows-12 grid-cols-12">
                <div className=" row-start-1 col-span-12 flex items-center justify-center">
                  {/*VERIFICA SE É ADM PARA ADD CLIENTES*/}
                  {isAdmin ? (
                    <button
                      className="px-16 justify-around place-items-center p-1 bg-blue-900 text-white tracking-tight font-medium rounded-lg  duration-300 hover:bg-blue-950"
                      type="button"
                      onClick={() => {
                        openPermissionsModal()
                      }}>
                      Adicionar clientes
                    </button>
                  ) : (
                    <div />
                  )}
                </div>
                <div className="row-start-2 row-end-13 overflow-y-auto col-span-12">
                  <table className="w-full overflow-y-auto bg-black">
                    <tbody>
                    {novoUsuario.tenants?.map((item, index) => {
                      if (isAdmin) {
                        return (
                          <td
                            key={index}
                            className="border w-full flex justify-between items-center  cursor-pointer even:bg-white odd:bg-neutral-100 hover:bg-blue-100 text-gray-500"
                            onClick={() => {
                              setSwitchCofre(false)
                              setTenantToEdit(item.tenant)
                              openModal3()
                              setNameTenantPermissions(item.tenant_name)
                            }}>
                            <p className="px-10 py-1">{item.tenant_name}</p>
                            <div
                              className="flex items-center mr-20 cursor-pointer p-1 px-3"
                              title="Permissoes">
                              <p className="text-blue-700 font-semibold text-sm mr-8">Acronis</p>
                              <ClipboardDocumentListIcon className="size-6 text-blue-900" />
                              <button
                                className="p-1.5 mx-6 text-rose-600 tracking-tight font-medium rounded-lg duration-300 hover:bg-rose-600 hover:text-white"
                                title="excluir"
                                type="button"
                                onClick={() => {
                                  openModal4()
                                  setTenantToDelete(item)
                                }}>
                                <TrashIcon className="size-5" />
                              </button>
                            </div>
                          </td>
                        )
                      } else {
                        const hasPermissionEdit = permissoes.tenants.some((userpermissions) => userpermissions.tenant === item.tenant) &&
                          permissoes.tenants.find((userpermissions) => userpermissions.tenant === item.tenant).permissions.users.update
                        return hasPermissionEdit ? (
                          <td
                            key={index}
                            className="border w-full flex justify-between items-center cursor-pointer even:bg-white odd:bg-neutral-100 hover:bg-blue-100 text-gray-500"
                            onClick={() => {
                              setSwitchCofre(false)
                              setTenantToEdit(item.tenant)
                              openModal3()
                              setNameTenantPermissions(item.tenant_name)
                            }}>
                            <p className="px-10 py-1">{item.tenant_name}</p>
                            <div
                              className="flex items-center mr-20 cursor-pointer p-1 px-3"
                              title="Permissoes">
                              <p className="text-blue-700 font-semibold text-sm mr-8">Acronis </p>
                              <ClipboardDocumentListIcon className="size-6 text-blue-900" />
                              {(() => {
                                const hasPermissionDelete =
                                        permissoes.tenants.some((userpermissions) => userpermissions.tenant === item.tenant) &&
                                        permissoes.tenants.find((userpermissions) => userpermissions.tenant === item.tenant)?.permissions?.users?.delete === true
                                return hasPermissionDelete ? (
                                  <button
                                    className="p-1.5 mx-6 text-rose-600 tracking-tight font-medium rounded-lg duration-300 hover:bg-rose-600 hover:text-white"
                                    title="excluir"
                                    type="button"
                                    onClick={() => {
                                      openModal4()
                                      setTenantToDelete(item)
                                    }}>
                                    <TrashIcon className="size-5" />
                                  </button>
                                ) : (
                                  <div className="mx-10 " />
                                )
                              })()}
                            </div>
                          </td>
                        ) : null
                      }
                    })}

                    {novoUsuario.clients?.map((item, index) => {
                      if (isAdmin) {
                        return (
                          <td
                            key={index}
                            className="border w-full flex justify-between items-center cursor-pointer even:bg-white odd:bg-neutral-100 hover:bg-blue-100 text-gray-500"
                            onClick={() => {
                              setSwitchCofre(true)
                              setTenantToEdit(item.client)
                              openModal3()
                              setNameTenantPermissions(item.client_name)
                            }}>
                            <p className="px-10 py-1">{item.client_name} </p>
                            <div
                              className="flex items-center mr-20 cursor-pointer p-1 px-3"
                              title="Permissoes">
                              <p className="text-green-700 font-semibold text-sm mr-8">Cofre</p>
                              <ClipboardDocumentListIcon className="size-6 text-blue-900" />
                              <button
                                className="p-1.5 mx-6 text-rose-600 tracking-tight font-medium rounded-lg duration-300 hover:bg-rose-600 hover:text-white"
                                title="excluir"
                                type="button"
                                onClick={() => {
                                  openModal4()
                                  setTenantToDelete(item)
                                }}>
                                <TrashIcon className="size-5" />
                              </button>
                            </div>
                          </td>
                        )
                      } else {
                        const hasPermissionEdit = permissoes.clients.some((userpermissions) => userpermissions.client === item.client) &&
                          permissoes.clients.find((userpermissions) => userpermissions.client === item.client).permissions.update === true
                        return hasPermissionEdit ? (
                          <td
                            key={index}
                            className="border w-full flex justify-between items-center cursor-pointer even:bg-white odd:bg-neutral-100 hover:bg-blue-100 text-gray-500"
                            onClick={() => {
                              setSwitchCofre(true)
                              setTenantToEdit(item.client)
                              openModal3()
                              setNameTenantPermissions(item.client_name)
                            }}>
                            <p className="px-10 py-1">{item.client_name}</p>
                            <div
                              className="flex items-center mr-20 cursor-pointer p-1 px-3"
                              title="Permissoes">
                              <p className="text-green-700 font-semibold text-sm mr-8">Cofre</p>
                              <ClipboardDocumentListIcon className="size-6 text-blue-900" />
                              {(() => {
                                const hasPermissionDelete = permissoes.clients.some((userpermissions) => userpermissions.client === item.client) &&
                                  permissoes.clients.find((userpermissions) => userpermissions.client === item.client)?.permissions?.delete === true
                                return hasPermissionDelete ? (
                                  <button
                                    className="p-1.5 mx-6 text-rose-600 tracking-tight font-medium rounded-lg duration-300 hover:bg-rose-600 hover:text-white"
                                    title="excluir"
                                    type="button"
                                    onClick={() => {
                                      openModal4()
                                      setTenantToDelete(item)
                                    }}>
                                    <TrashIcon className="size-5" />
                                  </button>
                                ) : (
                                  <div className="mx-10 " />
                                )
                              })()}
                            </div>
                          </td>
                        ) : null
                      }
                    })}
                    </tbody>
                  </table>
                </div>

                <div>
                  <Modal
                    ariaHideApp={false}
                    className="absolute top-2/4 left-2/4 right-auto bottom-auto -mr-2/4 -translate-x-2/4 -translate-y-2/4 border rounded-lg border-blue-700 h-3/6 w-3/6 bg-white shadow-lg shadow-blue-200 grid grid-rows-12 grid-cols-12"
                    contentLabel="Example Modal"
                    isOpen={modalIsOpenCheckPermissions}
                    onRequestClose={closeModal3}>
                    {/*Exporta a variavel "novoUsuario e setNovoUsuario atravez do Context.Provider*/}
                    <UsuarioContext.Provider value={{ novoUsuario, setNovoUsuario }}>
                      <div className="row-start-1 row-span-10 col-span-12 rounded-t-lg">
                        <PermissionsTab
                          edit={edit}
                          nameTenantPermissions={nameTenantPermissions}
                          setUserEditado={setUserEditado}
                          switchCofre={switchCofre}
                          tenantToEdit={tenantToEdit}
                          userToEdit={userToEdit}
                        />
                      </div>
                    </UsuarioContext.Provider>

                    <div className="row-start-11 row-end-13 col-span-12 grid grid-cols-12 rounded-b-lg">
                      <div className="col-start-2 col-span-2 content-center flex items-center justify-center">
                        <button
                          className="h-8 w-20 bg-rose-600 text-white tracking-tight font-medium rounded-lg  duration-300 hover:bg-rose-700"
                          type="button"
                          onClick={() => {
                            closePermissionsModal()
                            closeModal3()
                            setAarrayPermissoesEditadas([])
                          }}>
                          Voltar
                        </button>
                      </div>

                      <div className="col-start-10 col-span-2 content-center flex items-center justify-center">
                        <button
                          className="h-8 w-20 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                          type="button"
                          onClick={() => {
                            salvarEdicao()
                            setAarrayPermissoesEditadas((prevState) => [...prevState, permissoesEditadas])
                          }}>
                          Alterar
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </>
          )
        ) : (
          <h2 className="p-3 ml-6 text-center text-gray-500 font-bold text-lg text-nowrap place-content-center ">Permissões {novoUsuario.name}</h2>
        )}
        {/*//======================================================================================================*/}
        <div className="row-start-12 col-start-2 pl-8 place-content-center ">
          <button
            className="grid-cols-2 px-4  justify-around place-items-center p-1 bg-rose-600 text-white tracking-tight font-medium rounded-lg  duration-300 hover:bg-rose-700"
            type="button"
            onClick={() => {
              closeModal2()
              setNovoUsuario({
                email:   '',
                name:    '',
                tenants: [],
              })
              setPermissoesEditadas([])
              setAarrayPermissoesEditadas([])
            }}>
            Voltar
          </button>
        </div>
        {edit ? (
          <div className=" row-start-12 col-start-11 place-content-center">
            <button
              className="grid-cols-2 px-4  justify-around place-items-center p-1 bg-blue-900 text-white tracking-tight font-medium rounded-lg  duration-300 hover:bg-blue-950"
              type="button"
              onClick={() => {
                editUser()
              }}>
              Salvar
            </button>
          </div>
        ) : null}
      </Modal>

      <div className="h-full flex">
        {isAdmin ? (
          <div className="p-5 w-3/12">
            <form
              className="rounded-lg bg-white p-5 shadow-lg space-y-3"
              onSubmit={(e) => {
                e.preventDefault()
                createUser()
              }}>
              <div className="block place-content-center">
                <p className="font-sans text-md font-medium tracking-tight">Cadastrar novo usuário</p>
              </div>
              <div>
                <label
                  className="block tracking-tighter font-medium"
                  htmlFor="name">
                  Nome
                </label>
                <input
                  className="w-full p-2 font-sans text-gray-600 border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-700"
                  id="name"
                  required={true}
                  type="text"
                  value={novoUsuario.name}
                  onChange={(e) => setNovoUsuario({ ...novoUsuario, name: e.target.value })}
                />
              </div>
              <div>
                <label
                  className="block tracking-tighter font-medium"
                  htmlFor="email">
                  Email
                </label>
                <input
                  className="w-full p-2 font-sans text-gray-600 border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-700"
                  id="name"
                  required={true}
                  type="text"
                  value={novoUsuario.email}
                  onChange={(e) => setNovoUsuario({ ...novoUsuario, email: e.target.value })}
                />
              </div>

              {nagixEmail === true ? (
                <div className="flex mt-2">
                  <div className="col-start-1">
                    <button
                      className="px-4 flex justify-around place-items-center gap-2 p-2 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                      type="submit">
                      <p>Cadastrar</p>
                      {loading[0] && loading[1] === 'createUser' ? loadSpinner('text-white', 'size-6', 'create') : null}
                    </button>
                  </div>
                  <div className="col-start-2 pl-28">
                    <button
                      className="px-4 flex justify-around place-items-center gap-2 p-2 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                      type="button"
                      onClick={() => {
                        setNewUser(true)
                        setEdit(false)
                        openPermissionsModal()
                      }}>
                      <p>Permissões </p>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="grid mt-2">
                  {novoUsuario.tenants.length > 0 || novoUsuario.clients?.length > 0 ? (
                    <>
                      <div className="col-start-1">
                        <button
                          className="px-4 flex justify-around place-items-center gap-2 p-2 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                          type="submit">
                          <p>Cadastrar</p>
                          {loading[0] && loading[1] === 'createUser' ? loadSpinner('text-white', 'size-6', 'create') : null}
                        </button>
                      </div>
                      <div className="col-start-2 pl-28">
                        <button
                          className="px-4 flex justify-around place-items-center gap-2 p-2 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                          type="button"
                          onClick={() => {
                            setNewUser(true)
                            setEdit(false)
                            openPermissionsModal()
                          }}>
                          <p>Permissões </p>
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="col-start-1">
                      <button
                        className="px-4 flex justify-around place-items-center gap-2 p-2 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                        type="button"
                        onClick={() => {
                          setNewUser(true)
                          setEdit(false)
                          openPermissionsModal()
                        }}>
                        <p>Permissões</p>
                      </button>
                    </div>
                  )}
                </div>
              )}
            </form>
          </div>
        ) : (
          userSomeCreate === true && (
            <div className="p-5 w-3/12">
              <form
                className="rounded-lg bg-white p-5 shadow-lg space-y-3"
                onSubmit={(e) => {
                  e.preventDefault()
                  createUser()
                }}>
                <div className="block place-content-center">
                  <p className="font-sans text-md font-medium tracking-tight">Cadastrar novo usuário</p>
                </div>
                <div>
                  <label
                    className="block tracking-tighter font-medium"
                    htmlFor="name">
                    Nome
                  </label>
                  <input
                    required
                    className="w-full p-2 font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                    id="name"
                    type="text"
                    value={novoUsuario.name}
                    onChange={(e) => setNovoUsuario({ ...novoUsuario, name: e.target.value })}
                  />
                </div>
                <div>
                  <label
                    className="block tracking-tighter font-medium"
                    htmlFor="email">
                    Email
                  </label>
                  <input
                    required
                    className="w-full p-2 font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 rounded-lg focus:outline-none focus:border-2 focus:border-blue-700"
                    id="email"
                    type="text"
                    value={novoUsuario.email}
                    onChange={(e) => setNovoUsuario({ ...novoUsuario, email: e.target.value })}
                  />
                </div>
                {nagixEmail === true ? (
                  <div className=" grid mt-2">
                    <div className="col-start-1">
                      <button
                        className="px-4 flex justify-around place-items-center gap-2 p-2 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                        type="submit">
                        <p>Cadastrar</p>
                        {loading[0] && loading[1] === 'createUser' ? loadSpinner('text-white', 'size-6', 'create') : null}
                      </button>
                    </div>
                    <div className="col-start-2 pl-28">
                      <button
                        className="px-4 flex justify-around place-items-center gap-2 p-2 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                        type="button"
                        onClick={() => {
                          setNewUser(true)
                          setEdit(false)
                          openPermissionsModal()
                        }}>
                        <p>Permissões </p>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="grid mt-2">
                    {novoUsuario.tenants.length > 0 || novoUsuario.clients?.length > 0 ? (
                      <>
                        <div className="col-start-1">
                          <button
                            className="px-4 flex justify-around place-items-center gap-2 p-2 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                            type="submit">
                            <p>Cadastrar</p>
                            {loading[0] && loading[1] === 'createUser' ? loadSpinner('text-white', 'size-6', 'create') : null}
                          </button>
                        </div>
                        <div className="col-start-2 pl-28">
                          <button
                            className="px-4 flex justify-around place-items-center gap-2 p-2 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                            type="button"
                            onClick={() => {
                              setNewUser(true)
                              setEdit(false)
                              openPermissionsModal()
                            }}>
                            <p>Permissões </p>
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="col-start-1">
                        <button
                          className="px-4 flex justify-around place-items-center gap-2 p-2 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                          type="button"
                          onClick={() => {
                            setNewUser(true)
                            setEdit(false)
                            openPermissionsModal()
                          }}>
                          <p>Permissões</p>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </form>
            </div>
          )
        )}

        <div className="w-9/12 p-5 ">
          <div className="rounded-lg h-full bg-white p-3 shadow-lg overflow-auto">
            {loading[0] && loading[1] === 'getUsers' ? (
              <div className="size-full grid place-content-center place-items-center">{loadSpinner('text-blue-900', 'size-12', 'users')}</div>
            ) : (
              <table className="table-auto w-full">
                <thead className="border-b-2 border-black bg-white">
                <tr className="px-2 text-start">
                  <th className="text-start px-2">Nome</th>
                  <th className="text-start px-2">Email</th>
                  <th className="text-start px-2">Ultimo acesso</th>
                  <th className="text-start px-2" />
                </tr>
                </thead>
                <tbody className="text-md">
                {users.map((user, index) => {
                  return (
                    <>
                      {/*Verifica o email de acesso para nao exibir o usuario para edita-lo*/}
                      {userEmail === user.email ? (
                        <div />
                      ) : (
                        <tr
                          key={index}
                          className="h-12 border-b even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight">
                          <td
                            key={user.name}
                            className="px-2 w-2/5">
                            {user.name}
                          </td>
                          <td
                            key={user.email}
                            className="px-2">
                            {user.email}
                          </td>
                          <td
                            key={user.last_access}
                            className="px-2">
                            {user.last_access ?? '-'}
                          </td>
                          <td className="px-2 space-x-3 ">
                            {/*{permissoes (): null}*/}
                            <button
                              className=" p-1.5 text-white bg-blue-900 tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                              title="Editar permissões"
                              type="button"
                              onClick={() => {
                                setNewUser(false)
                                setEdit(true)
                                getUser(user)
                                setUserInfo({
                                  ...userInfo,
                                  id: user.id,
                                })
                                openModal2()
                              }}>
                              <PencilSquareIcon className="size-5" />
                            </button>

                            <button
                              className="p-1.5 text-rose-600 tracking-tight font-medium rounded-lg duration-300 hover:bg-rose-600 hover:text-white"
                              type="button"
                              onClick={() => {
                                openModal()
                                setUserToDelete(user)
                              }}>
                              <TrashIcon className="size-5" />
                            </button>
                          </td>
                        </tr>
                      )}
                    </>
                  )
                })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div>
          <Modal
            ariaHideApp={false}
            className="absolute top-2/4 left-2/4 right-auto bottom-auto -mr-2/4 -translate-x-2/4 -translate-y-2/4 border rounded-lg border-blue-700 h-5/6 w-5/6 bg-white shadow-lg shadow-blue-200 grid grid-rows-12 grid-cols-12"
            contentLabel="Example Modal"
            isOpen={modalPermissionsIsOpen}
            onRequestClose={closePermissionsModal}>
            {/*Exporta a variavel "novoUsuario e setNovoUsuario atravez do Context.Provider*/}
            <UsuarioContext.Provider value={{ novoUsuario, setNovoUsuario }}>
              <div className="row-start-1 row-span-11 col-span-12 rounded-t-lg overflow-y-auto">
                <Permissions
                  setNovoUsuario={setNovoUsuario}
                  setSwitchCofre={setSwitchCofre}
                  switchCofre={switchCofre}
                />
              </div>
            </UsuarioContext.Provider>

            <div className="row-start-12 col-span-12 grid grid-cols-12 rounded-b-lg border-t border-blue-700 ">
              <div className="col-start-2 col-span-2 content-center flex items-center justify-center">
                <button
                  className="p-2 px-3 bg-rose-600 text-white tracking-tight font-medium rounded-lg  duration-300 hover:bg-rose-700"
                  type="button"
                  onClick={() => {
                    closePermissionsModal()
                    closeModal2()
                    setNovoUsuario({
                      email:   '',
                      name:    '',
                      tenants: [],
                    })
                  }}>
                  Voltar
                </button>
              </div>

              <div className="col-start-10 col-span-2 content-center flex items-center justify-center">
                <button
                  className="p-2 px-3 bg-blue-900 text-white tracking-tight font-medium rounded-lg  duration-300 hover:bg-blue-950"
                  type="button"
                  onClick={() => {
                    closePermissionsModal()
                  }}>
                  Salvar
                </button>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            ariaHideApp={false}
            className="absolute top-2/4 left-2/4 right-auto bottom-auto -mr-2/4 -translate-x-2/4 -translate-y-2/4 border rounded-lg border-blue-700 h-72 w-96 bg-white shadow-lg shadow-blue-200"
            contentLabel="Example Modal"
            isOpen={modalIsOpenExcluir}
            onRequestClose={closeModal}>
            <div className="size-full flex flex-col justify-between">
              <h2 className="mt-6 text-center text-gray-500 font-bold text-lg">Excluir {userToDelete.name} ?</h2>

              <div className="flex items-center justify-center h-32">
                <ExclamationTriangleIcon className="size-20 text-red-600 border-2 border-rose-500 rounded-full p-2 bg-red-100 hover:bg-white transform duration-500" />
              </div>

              <div className="flex flex-row justify-evenly p-2">
                <button
                  className="p-2 w-1/5  text-blue-900 tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-900 hover:text-white"
                  type="button"
                  onClick={closeModal}>
                  Voltar
                </button>

                <button
                  className="p-2 w-1/5 text-rose-600 tracking-tight font-medium rounded-lg duration-300 hover:bg-rose-600 hover:text-white"
                  type="button"
                  onClick={() => deleteUser()}>
                  Excluir
                </button>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            ariaHideApp={false}
            className="absolute top-2/4 left-2/4 right-auto bottom-auto -mr-2/4 -translate-x-2/4 -translate-y-2/4 border rounded-lg border-rose-700 h-72 w-2/5 bg-neutral-50 shadow-lg shadow-rose-200"
            contentLabel="Example Modal"
            isOpen={modalIsOpenConfirmDelete}
            onRequestClose={closeModal4}>
            <div className="size-full flex flex-col justify-between">
              <h2 className="mt-6 text-center text-gray-500 font-bold text-lg" />

              <div className="flex items-center justify-center  text-gray-500 font-bold text-lgh-32">
                <h1>Deseja excluir {tenantToDelete?.tenant_name ? tenantToDelete.tenant_name : ''} ?</h1>
              </div>

              <div className="flex flex-row justify-evenly p-2">
                <button
                  className="p-2 w-1/5 text-blue-900 tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-900 hover:text-white"
                  type="button"
                  onClick={closeModal4}>
                  Voltar
                </button>

                <button
                  className="p-1.5 text-rose-600 tracking-tight font-medium rounded-lg duration-300 hover:bg-rose-600 hover:text-white"
                  type="button"
                  onClick={() => {
                    deleteTenant()
                  }}>
                  Excluir
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Layout>
  )
}

export default Users
