import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import Vault from './routes/Vault.jsx';
import Login from './routes/Login.jsx';
import Users from './routes/Users.jsx';
import RecuperaSenha from './routes/RecuperaSenha.jsx';
import Table from './routes/Table.jsx';
import Report from './routes/Report.jsx';
import Groups from './routes/Groups.jsx';
import { Toaster } from 'react-hot-toast';
import NewGroups from './routes/NewGroup.jsx';
import Evidences from './routes/Evidences.jsx';
import ResetPassword from './routes/ResetPassword.jsx';
import PrivateRoutes from './routes/PrivateRoutes.jsx';
import { PermissoesProvider } from './context/PermisoesContext.jsx';
import ClientList from './routes/ClientList.jsx';
import Config from './routes/Config.jsx';
import { createTheme, ThemeProvider } from '@mui/material';
import { ptBR } from '@mui/material/locale';
import Inventory from './routes/Inventory.jsx';
import Cadastros from './routes/Cadastros.jsx';
import CreateMachineInventory from './routes/CreateMachineInventory.jsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/pt-br.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const App = () => {
  const themeOptions = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#35208f',
      },
      secondary: {
        main: '#f50057',
      },
      background: {
        default: '#f1f1f1',
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          size: 'small',
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary',
          size: 'small',
        },
      },
    },
    ptBR,
  });

  const privateRoutes = [
    {
      path: '/vault',
      element: <Vault />,
    },
    {
      path: '/',
      element: <Table />,
    },
    {
      path: '/users',
      element: <Users />,
    },
    {
      path: '/groups',
      element: <Groups />,
    },
    {
      path: '/reports',
      element: <Report />,
    },
    {
      path: '/newgroups',
      element: <NewGroups />,
    },
    {
      path: '/evidences',
      element: <Evidences />,
    },
    {
      path: '/cadastros',
      element: <Cadastros />,
    },
    {
      path: '/inventory',
      element: <Inventory />,
    },
    {
      path: '/inventory/form/:id?',
      element: <CreateMachineInventory />,
    },
    {
      path: '/client-data',
      element: <ClientList />,
    },
    {
      path: '/config',
      element: <Config />,
    },
  ];

  return (
    <ThemeProvider theme={themeOptions}>
      <PermissoesProvider>
        <CookiesProvider>
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
              <Routes>
                <Route element={<PrivateRoutes />}>
                  {privateRoutes.map((route, index) => {
                    return <Route key={index} element={route.element} path={route.path} />;
                  })}
                </Route>
                <Route element={<Login />} path="/login" />
                <Route element={<RecuperaSenha />} path="/forgot-password" />
                <Route element={<ResetPassword />} path="/password-reset" />
              </Routes>
            </LocalizationProvider>
          </BrowserRouter>
          <Toaster />
        </CookiesProvider>
      </PermissoesProvider>
    </ThemeProvider>
  );
};

export default App;
