import React, { useEffect, useState } from 'react'
import Layout from '/src/components/Layouts/Layout.jsx'
import {
  CloudArrowUpIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline/index.js'
import AxiosConfigs from '../configs.js'
import Modal from 'react-modal'
import LoadingBlue from '../components/Layouts/loadingblue.jsx'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import toast from 'react-hot-toast'
import { LanguageIcon } from '@heroicons/react/20/solid'
import translate from '../translate.js'
import { Button } from '@mui/material'

const Table = () => {
  const [togSpinTab, setTogSpinTab]             = useState(false)
  const [tenants, setTenants]                   = useState([])
  const [tenantactiv, setTenantActiv]           = useState('')
  const namedTenant                             = tenants.filter(
    (tenant) => Object.prototype.hasOwnProperty.call(tenant, 'name'))
  const [translateLoading, setTranslateLoading] = useState(false)

  const translateText = (text, index, type) => {
    setTranslateLoading(true)
    translate(text).then((response) => {
      const translatedText = response.data.data.translations.translatedText

      if (type === 'warning') {
        const newWarningMessages                 = [...warningMessages]
        newWarningMessages[index].activity_error = translatedText
        setWarningMessages(newWarningMessages)
      } else if (type === 'error') {
        const newErrorMessages                 = [...errorMessages]
        newErrorMessages[index].activity_error = translatedText
        setErrorMessages(newErrorMessages)
      } else if (type === 'critical') {
        const newCriticalMessages                 = [...criticalMessages]
        newCriticalMessages[index].activity_error = translatedText
        setCriticalMessages(newCriticalMessages)
      }
      toast.success('Texto traduzido com sucesso', {
        id: '0',
      })
    }).catch((error) => {
      console.error('Erro ao traduzir texto:', error)
      toast.error('Erro ao traduzir texto', {
        id: '0',
      })
    }).finally(() => {
      setTranslateLoading(false)
    })
  }

  useEffect(() => {
    localStorage.setItem('tenants', JSON.stringify(namedTenant))
  }, [namedTenant])

  useEffect(() => {
    setTogSpinTab(true)
    AxiosConfigs.get('/tenants').then((response) => {
      if (response.status !== 200) {
        toast.error('Erro ao buscar clientes', {
          id: '0',
        })
      }
      setTenants(response.data)
    }).finally(() => {
      setTogSpinTab(false)
    })
  }, [])

  //==================================================================================================================
  const [sizeTotal, setSizeTotal] = useState('')
  useEffect(() => {
    let totalSize
    tenants.forEach((cliente) => {
      if (cliente.total_size) {
        totalSize = cliente.total_size
        setSizeTotal(totalSize)
      }
    })
  }, [tenants])
  //==================================================================================================================
  const [userEmail, setUserEmail] = useState('')
  const [avatar, setAvatar]       = useState('')

  useEffect(() => {
    if (userEmail) {
      const primeiraLetra = userEmail[0]
      setAvatar(primeiraLetra.toUpperCase())
    }
  }, [userEmail])

  useEffect(() => {
    AxiosConfigs.get('/users/info').then((response) => {
      setUserEmail(response.data)
    }).catch(function (error) {
      error
    })
  }, [])
  //=====================================================================================================================
  const [activiErrors, setActiviErrors] = useState([])
  const [activiTotal, setActiviTotal]   = useState('')
  const [showSpinner, setShowSpinner]   = useState(true)
  const [error24hrs, setError24hrs]     = useState()
  const [showSpinner2, setShowSpinner2] = useState(true)
  const [error7days, setError7days]     = useState()
  const [showSpinner3, setShowSpinner3] = useState(true)

  useEffect(() => {
    Promise.all([
      AxiosConfigs.get(`/errors/history/7`),
      AxiosConfigs.get(`/errors/history/1`),
      AxiosConfigs.get('/errors/active')]).then((response) => {
      setError7days(response[0].data)
      setError24hrs(response[1].data)
      setActiviErrors(response[2].data)
      setShowSpinner(false)
      setShowSpinner2(false)
      setShowSpinner3(false)
    })

    // AxiosConfigs.get(`/errors/history/7`)
    //     .then((response) => {
    //         setError7days(response.data)
    //         setShowSpinner3(false)
    //     })
    //     .catch((error) => {
    //         // Handle the error
    //         console.error('There was an error fetching the data!', error)
    //     })
    //
    // AxiosConfigs.get(`/errors/history/1`)
    //     .then((response) => {
    //         setError24hrs(response.data)
    //         setShowSpinner2(false)
    //     })
    //     .catch((error) => {
    //         // Handle the error
    //         console.error('There was an error fetching the data!', error)
    //     })
    //
    // AxiosConfigs.get('/errors/active')
    //     .then((response) => {
    //         const fetchedActivities = response.data
    //         setActiviErrors(fetchedActivities)
    //         localStorage.setItem('activies', JSON.stringify(fetchedActivities))
    //         setShowSpinner(false)
    //     })
    //     .catch(function (error) {
    //         console.error('Erro ao buscar atividades:', error)
    //         setShowSpinner(false)
    //     })
  }, [])

  const calcularTotalErrosAtivos = () => {
    //soma apenas erros dos clientes da tabela
    let totalErros = 0
    tenants.forEach((cliente) => {
      // Verifica se o ID do cliente está presente nos erros ativos
      if (Object.prototype.hasOwnProperty.call(activiErrors, cliente.id)) {
        totalErros += activiErrors[cliente.id]
      }
    })
    return totalErros
  }
  //========================================================================================
  useEffect(() => {
    // Chama a função para calcular o total de erros ativos
    const totalErrosAtivos = calcularTotalErrosAtivos()
    // Atualiza o estado com o total de erros ativos e formata para 3 casas decimais
    setActiviTotal(totalErrosAtivos) // Comentário adicionado
  }, [tenants, activiErrors])
  //======================================================================================================================
  // const [tenantId, setTenantId] = useState([])
  const [critical, setCritical]                 = useState([])
  const [error, setError]                       = useState([])
  const [warning, setWarning]                   = useState([])
  const [warningMessages, setWarningMessages]   = useState([])
  const [errorMessages, setErrorMessages]       = useState([])
  const [criticalMessages, setCriticalMessages] = useState([])

  const getActiveError           = (tenantId) => {
    AxiosConfigs.get(`/errors/details/${tenantId}`).then((response) => {
      if (response.data.critical && Array.isArray(response.data.critical)) {
        setCritical(response.data.critical)
        setCriticalMessages(response.data.critical)
      }
      if (response.data.error && Array.isArray(response.data.error)) {
        setError(response.data.error)
        setErrorMessages(response.data.error)
      }
      if (response.data.warning && Array.isArray(response.data.warning)) {
        setWarning(response.data.warning)
        setWarningMessages(response.data.warning)
      }
    }).catch((error) => {
      // Handle the error
      console.error('There was an error fetching the data!', error)
    })
  }
  //========================================================================================================================================
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal () {
    setIsOpen(true)
  }

  function afterOpenModal () {
    // references are now sync'd and can be accessed.
  }

  function closeModal () {
    setIsOpen(false)
    setCritical([])
    setError([])
    setWarning([])
    setWarningMessages([])
    setErrorMessages([])
    setCriticalMessages([])
  }

  //================================================================================================================================
  const [modalIsOpen2, setIsOpen2] = React.useState(false)

  function openModa2 () {
    setIsOpen2(true)
  }

  function afterOpenModal2 () {
    // references are now sync'd and can be accessed.
  }

  function closeModal2 () {
    setIsOpen2(false)
  }

  //===============================================================================================================================================================

  //====================================================================================================================================
  const [legado, setLegado]               = useState('')
  const [maquinas, setMaquinas]           = useState([])
  const [mostrarLegado, setMostrarLegado] = useState(false)
  const [clienteId, setClienteId]         = useState('')

  const getResumo     = (cliente) => {
    setClienteId(cliente)
    AxiosConfigs.get(`/resources/${cliente}`).then((response) => {
      setMaquinas(response.data)
      // Verifica se há máquinas classificadas como "legado"
      if (response.data.Legados) {
        setMostrarLegado(true)
        setLegado(response.data.Legados)
      }
    }).catch((error) => {
      console.error('There was an error fetching the data!', error)
    })
  }
  const getStatus     = (key, maquinas) => {
    const status = maquinas[key].status
    if (typeof status === 'object' && status !== null) {
      return Object.entries(status).
        map(([key, value]) => `${key}: ${value}`).
        join(', ')
    } else if (typeof status === 'string') {
      return status
    } else {
      return 'Desconhecido'
    }
  }
  //==============================================================================================================================
  const status        = (key, maquinas) => {
    if (key === 'Legados') {
      return { backgroundColor: '#000000' }
    }
    if (!maquinas[key].online) {
      return { backgroundColor: '#D1D5DB' } // Retorna cinza se offline
    }
    if (maquinas[key].status === 'ok' || maquinas[key].status === 'warning') {
      return { backgroundColor: '#19C37D' } // Retorna verde para ok e warning
    }
    return { backgroundColor: '#F05252' } // Retorna vermelho para qualquer outro caso
  }
  const contadorTotal = {
    erro:    0,
    offline: 0,
    ok:      0,
  }
  //Logica para contar quantidades de erros, offline e ok
  Object.keys(maquinas).forEach((key) => {
    const statusKey = status(key, maquinas).backgroundColor
    if (statusKey === '#F05252') {
      contadorTotal.erro++
    } else if (statusKey === '#D1D5DB') {
      contadorTotal.offline++
    } else {
      contadorTotal.ok++
    }
  })
  //=============================================================================================================================
  const [maquinaStorage, setMaquinaStorage] = useState({})

  async function storageIndividual (maquina) {
    try {
      const response             = await AxiosConfigs.get(
        `/resources/backup-size/${clienteId}/${maquina}/`)
      const maquinaStorageResult = response.data // Resultado do armazenamento para a máquina atual
      setMaquinaStorage((prevState) => ({
        ...prevState,
        [maquina]: maquinaStorageResult, // Atualiza o estado com o resultado do armazenamento para a máquina atual
      }))
      return maquinaStorageResult
    } catch (error) {
      console.error('Ocorreu um erro:', error)
      return null
    }
  }

  //=========================================================================================================================================
  return (
    <Layout active="table">
      <div>
        {togSpinTab === true ? (
          <div className="h-screen flex items-center justify-center">
            <div className="flex items-center">
              <svg
                className="animate-spin size-20 text-blue-700"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <div className="header">
                <div className="flex items-center justify-between h-20">
                  <div className="h-14 w-12 ml-4">
                    <a
                      href="https://www.nagix.com.br/"
                      target="_blank"
                      title="https://www.nagix.com.br/">
                      <img
                        alt="logoNagix"
                        src="/Logo_Nagix.png"
                      />
                    </a>
                  </div>

                  <div className="flex flex-row gap-12 text-xl">
                    <div
                      className="flex items-center justify-evenly border border-blue-700 bg-blue-100 rounded-lg h-14 w-60">
                      <div>
                        <CloudArrowUpIcon
                          className="size-7 text-blue-700" />
                      </div>
                      <p className="font-semibold text-blue-700">{sizeTotal} TB
                        Utilizados</p>
                    </div>
                    <div
                      className="flex items-center justify-evenly border border-orange-700 bg-orange-100 rounded-lg h-14 w-52">
                      <div>
                        <ExclamationCircleIcon
                          className="size-6 text-orange-700" />
                      </div>
                      <p className="font-semibold text-orange-700">{activiTotal} Ativos</p>
                    </div>
                  </div>

                  <div className="h-14 w-14 mr-4">
                    <div className=" flex items-center justify-center">
                      <div className="h-fit w-fit rounded-full ">
                        <button
                          className="h-14 w-14 shadow-md shadow-blue-600  bg-blue-700 rounded-full   hover:bg-[#35208f]"
                          style={{
                            fontSize:   '25px',
                            fontWeight: 'bold',
                            color:      'white',
                          }}
                          title={userEmail}
                          type="button">
                          {avatar}
                        </button>
                      </div>
                    </div>
                    <div />
                  </div>
                </div>
              </div>

              <div
                className="w-full pl-2 pr-2 p-x-6 mt-4 h-[calc(100vh-95px)] block overflow-auto">
                <table className="table-auto w-full">
                  <thead className="sticky -top-1 bg-white h-10 z-50">
                  <tr>
                    <th className="border font-semibold text-start px-2">Clientes</th>
                    <th className="border font-semibold">N° Maquinas</th>
                    <th className="border font-semibold">Storage</th>
                    <th className="border font-semibold">Ativos</th>
                    <th className="border font-semibold">24 hrs</th>
                    <th className="border font-semibold">7 Dias</th>
                    <th className="border font-semibold">Detalhes</th>
                  </tr>
                  </thead>

                  <tbody className="tracking-tight">
                  {namedTenant.sort((a, b) => a.name.localeCompare(b.name)).
                    map((cliente, index) => (
                      <tr
                        key={index}
                        className="border odd:bg-neutral-100 hover:bg-blue-50 text-gray-500 ">
                        <td className="border w-4/12">
                          <p className="px-2 py-1">{cliente.name}</p>
                        </td>
                        <td className="border ">
                          <p className=" text-center text-gray-500">{cliente.usage.total_protected_workloads ??
                            0}</p>
                        </td>
                        <td className="border w-60 ">
                          <p className="text-center text-gray-500">
                            {cliente.usage.total_storage ??
                              0} &nbsp;/&nbsp;
                            {cliente.quota.pg_base_storage ??
                              cliente.quota.pw_base_storage}
                          </p>
                        </td>

                        {showSpinner ? (
                          <td className=" flex justify-center">
                            <div className="size-full z-10">
                              <svg
                                className="animate-spin size-5 text-blue-700 m-auto"
                                fill="none"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                />
                                <path
                                  className="opacity-75"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </td>
                        ) : (
                          <td
                            className={`text-center border ${activiErrors[cliente.id] ===
                            0
                              ? 'border-none text-gray-500'
                              : ' bg-orange-100 text-orange-700 hover:bg-orange-200 transition duration-200 hover:cursor-pointer'} `}
                          >
                            <Button
                              className="w-full"
                              variant="text"
                              color={activiErrors[cliente.id] === 0
                                ? 'inherit'
                                : 'warning'}
                              onClick={() => {
                                if (activiErrors[cliente.id] !==
                                  0) {
                                  setTenantActiv(cliente.name)
                                  openModal()
                                  getActiveError(cliente.id)
                                }
                              }}
                            >
                              {activiErrors[cliente.id]}
                            </Button>
                            {/*<div className={`text-center border border-orange-700 ${activiErrors[cliente.id] === 0 ? 'border-none text-gray-500' : ' bg-orange-100 text-orange-700 hover:bg-orange-200 transition duration-200 hover:cursor-pointer'} `}>*/}
                            {/*{activiErrors[cliente.id]}*/}
                            {/*</div>*/}
                          </td>
                        )}

                        {showSpinner2 ? (
                          <td className="border">
                            <div className="size-full">
                              <svg
                                className="animate-spin size-5 text-blue-700 m-auto "
                                fill="none"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                />
                                <path
                                  className="opacity-75"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </td>
                        ) : (
                          <td className="border ">
                            <p className=" text-center">{error24hrs[cliente.id]}</p>
                          </td>
                        )}

                        {showSpinner3 ? (
                          <td className="border ">
                            <div className="size-full ">
                              <svg
                                className="animate-spin size-5 text-blue-700 m-auto -z-10"
                                fill="none"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                />
                                <path
                                  className="opacity-75"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </td>
                        ) : (
                          <td className="border ">
                            <p className="text-center">{error7days[cliente.id]}</p>
                          </td>
                        )}

                        <td className=" text-center flex justify-center items-center ">
                          {/*<div*/}
                          {/*    className="cursor-pointer h-8"*/}
                          {/*    onClick={() => {*/}
                          {/*        getResumo(cliente.id)*/}
                          {/*        setTenantActiv(cliente.name)*/}
                          {/*        openModa2()*/}
                          {/*    }}>*/}
                          <Button
                            className="text-center h-full w-full "
                            variant="text"
                            onClick={() => {
                              getResumo(cliente.id)
                              setTenantActiv(cliente.name)
                              openModa2()
                            }}
                          >
                            Detalhes
                          </Button>
                          {/*</div>*/}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="flex items-center justify-center">
                  <Modal
                    ariaHideApp={false}
                    className="grid grid-rows-12 border rounded-lg border-blue-700 w-4/6 h-4/6 absolute top-2/4 left-2/4 right-auto bottom-auto -mr-2/4 -translate-x-2/4 -translate-y-2/4 bg-white shadow-lg shadow-blue-200"
                    contentLabel="Example Modal"
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}>
                    <h2 className="p-4 text-gray-500 font-bold text-lg rounded-lg row-start-1">Erros ativos de {tenantactiv}</h2>

                    <div
                      className="border px-2 row-start-2 row-end-12 overflow-y-auto">
                      <div>
                        <div className="text-gray-600 ">
                          {warning.map((warn, index) => (
                            <div
                              key={index}
                              className="border rounded-lg border-yellow-400 w-full bg-yellow-50 p-2 my-1">
                              <div className="">
                                <p className="ml-3">Maquina: {warn.resource_name}</p>
                                <p className="ml-3">Aviso {index + 1}:</p>
                                <p className="ml-3 flex gap-3">
                                  <span>Mensagem de erro: {warningMessages[index].activity_error}</span>{' '}
                                  <span
                                    className="p-1 bg-green-700 text-white rounded-lg shadow-lg cursor-pointer hover:bg-green-800 duration-300"
                                    onClick={() => translateText(
                                      warn.activity_error, index,
                                      'warning')}>
                                                                        {translateLoading
                                                                          ? (
                                                                            <svg
                                                                              className="animate-spin size-5 text-white"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              xmlns="http://www.w3.org/2000/svg">
                                                                              <circle
                                                                                className="opacity-25"
                                                                                cx="12"
                                                                                cy="12"
                                                                                r="10"
                                                                                stroke="currentColor"
                                                                                strokeWidth="4"
                                                                              />
                                                                              <path
                                                                                className="opacity-75"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                fill="currentColor"
                                                                              />
                                                                            </svg>
                                                                          )
                                                                          : (
                                                                            <LanguageIcon
                                                                              className="size-5" />
                                                                          )}
                                                                    </span>
                                </p>
                                <p className="ml-3">Plano: {warn.plan_name}</p>
                                <p className="ml-3">Data e
                                  Hora: {warn.raised_ts}</p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className=" text-gray-600  ">
                          {error.map((err, index) => (
                            <div
                              key={index}
                              className="border rounded-lg border-orange-400 w-full bg-orange-50 p-2 my-1">
                              <div className="">
                                <p className="ml-3">Maquina: {err.resource_name}</p>
                                <p className="ml-3">Erro {index + 1}:</p>
                                <p className="ml-3 flex gap-3">
                                  <span>Mensagem de erro: {errorMessages[index].activity_error}</span>{' '}
                                  <span
                                    className="p-1 bg-green-700 text-white rounded-lg shadow-lg cursor-pointer hover:bg-green-800 duration-300"
                                    onClick={() => translateText(
                                      err.activity_error, index,
                                      'error')}>
                                                                        {translateLoading
                                                                          ? (
                                                                            <svg
                                                                              className="animate-spin size-5 text-white"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              xmlns="http://www.w3.org/2000/svg">
                                                                              <circle
                                                                                className="opacity-25"
                                                                                cx="12"
                                                                                cy="12"
                                                                                r="10"
                                                                                stroke="currentColor"
                                                                                strokeWidth="4"
                                                                              />
                                                                              <path
                                                                                className="opacity-75"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                fill="currentColor"
                                                                              />
                                                                            </svg>
                                                                          )
                                                                          : (
                                                                            <LanguageIcon
                                                                              className="size-5" />
                                                                          )}
                                                                    </span>
                                </p>
                                <p className="ml-3">Plano: {err.plan_name}</p>
                                <p className="ml-3">Data e
                                  Hora: {err.raised_ts}</p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="text-gray-600 ">
                          {critical.map((criti, index) => (
                            <div
                              key={index}
                              className="border rounded-lg border-red-400 w-full bg-red-50 p-2 my-1">
                              <div className="">
                                <p className="ml-3">Maquina: {criti.resource_name}</p>
                                <p className="ml-3">Critico {index +
                                  1}:</p>
                                <p className="ml-3 flex gap-3">
                                  <span>Mensagem de erro: {criticalMessages[index].activity_error}</span>{' '}
                                  <span
                                    className="p-1 bg-green-700 text-white rounded-lg shadow-lg cursor-pointer hover:bg-green-800 duration-300"
                                    onClick={() => translateText(
                                      criti.activity_error, index,
                                      'critical')}>
                                                                        {translateLoading
                                                                          ? (
                                                                            <svg
                                                                              className="animate-spin size-5 text-white"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              xmlns="http://www.w3.org/2000/svg">
                                                                              <circle
                                                                                className="opacity-25"
                                                                                cx="12"
                                                                                cy="12"
                                                                                r="10"
                                                                                stroke="currentColor"
                                                                                strokeWidth="4"
                                                                              />
                                                                              <path
                                                                                className="opacity-75"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                fill="currentColor"
                                                                              />
                                                                            </svg>
                                                                          )
                                                                          : (
                                                                            <LanguageIcon
                                                                              className="size-5" />
                                                                          )}
                                                                    </span>
                                </p>
                                <p className="ml-3">Nome do
                                  plano: {criti.plan_name}</p>
                                <p className="ml-3">Data e
                                  Hora: {criti.raised_ts}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div
                      className=" row-start-12 rounded-md flex items-center pl-8">
                      <div className="">
                        <button
                          className="p-1.5 tracking-tight font-medium rounded-lg duration-300 bg-rose-600 text-white shadow-md shadow-red-200
                                    hover:bg-rose-700 hover:shadow-md
                                    "
                          type="button"
                          onClick={() => {
                            setCritical([])
                            setError([])
                            setWarning([])
                            closeModal()
                          }}>
                          Voltar
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>

                <div className="flex items-center justify-center">
                  <Modal
                    ariaHideApp={false}
                    className="grid grid-rows-12 border rounded-lg border-blue-700 w-4/6 h-4/6 absolute top-2/4 left-2/4 right-auto bottom-auto -mr-2/4 -translate-x-2/4 -translate-y-2/4 bg-white shadow-lg shadow-blue-200"
                    contentLabel="Example Modal"
                    isOpen={modalIsOpen2}
                    onAfterOpen={afterOpenModal2}
                    onRequestClose={closeModal2}>
                    <h2 className="p-4 text-gray-500 font-bold text-lg rounded-lg row-start-1">Resumo
                      de {tenantactiv}</h2>

                    <div
                      className="border row-start-2 row-end-12 overflow-y-auto p-6">
                      {Object.keys(maquinas).sort((a, b) => {
                        if (a === 'Legados') return 1 // "legado" será exibido por último
                        if (b === 'Legados') return -1 // "legado" será exibido por último
                        const statusA = status(a, maquinas).backgroundColor
                        const statusB = status(b, maquinas).backgroundColor
                        if (statusA === '#F05252' && statusB !==
                          '#F05252') {
                          return -1 // "erro" exibe primeiro
                        }
                        if (statusA === '#D1D5DB' && statusB !==
                          '#F05252' && statusB !== '#D1D5DB') {
                          return -1 // "offline" segundo
                        }
                        return 1 // "ok" terceiro
                      }).map((key, index) => (
                        <Accordion
                          key=""
                          allowMultipleExpanded={true}
                          allowZeroExpanded={true}
                          className="transition duration-200"
                          onClick={() => storageIndividual(
                            maquinas[key].id)}>
                          <AccordionItem key={key}>
                            <AccordionItemHeading>
                              <AccordionItemButton
                                key={index}
                                className="bg-neutral-100 border border-gray-400 h-14 flex items-center p-6 hover:bg-blue-100">
                                <div className="flex items-center justify-between w-full ">
                                  <div>
                                    <div className="inline-block h-3 w-3 border border-gray-500 rounded-full" style={status(key, maquinas)} />
                                    &nbsp; &nbsp;
                                    <div className="inline-block">{key}&nbsp;-&nbsp;{maquinas[key].online ? 'Online' : 'Offline'}
                                    </div>
                                  </div>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel
                              key={index}
                              className="bg-neutral-200">
                              {key === 'Legados' ? (
                                Object.keys(legado).
                                  map((maqLegado, index) => (
                                    <div
                                      key={index}
                                      className="p-2 pl-8 border border-y-gray-500">
                                      <p>Maquina: {maqLegado}</p>
                                      <p>IP: {legado[maqLegado].ip.join(' - ')}</p>
                                      <p>Ultimo Backup: {legado[maqLegado].last_backup}</p>
                                      <p>Estado do Backup: &nbsp;{legado[maqLegado].status === 'ok' ? 'ok' : JSON.stringify(legado[maqLegado].status).replace(/["{}]/g, '')}</p>
                                      <hr />
                                    </div>
                                  ))
                              ) : (
                                <div
                                  key={maquinas[key].id}
                                  className="p-2 pl-8">
                                  <p>IP: {maquinas[key].ip.join(' - ')}</p>
                                  <p>Último Backup comSucesso: {maquinas[key].last_backup}</p>
                                  <p>Estado do Backup: {getStatus(key, maquinas)}</p>
                                  <p>Estado da Máquina: {maquinas[key].online ? 'Online' : 'Offline'}</p>
                                  <p>
                                    Armazenamento :&nbsp;&nbsp; {maquinaStorage && maquinaStorage[maquinas[key].id] !== undefined ? (maquinaStorage[maquinas[key].id]) : (
                                    <div className="h-8 w-8 ">
                                      <LoadingBlue
                                        className="text-center" />
                                    </div>
                                  )}
                                  </p>
                                  <hr />
                                </div>
                              )}
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      ))}
                    </div>

                    <div
                      className=" row-start-12 rounded-md flex items-center pl-8">
                      <div className="grid grid-cols-12 w-full">
                        <div className=" col-start-1">
                          <button
                            className="p-1.5 tracking-tight font-medium rounded-lg duration-300 bg-rose-600 text-white shadow-md shadow-red-200
                                    hover:bg-rose-700 hover:shadow-md
                                    "
                            type="button"
                            onClick={() => {
                              closeModal2()
                            }}>
                            Voltar
                          </button>
                        </div>

                        <div
                          className="flex justify-between col-start-3 col-end-12 items-center">
                          <div
                            className="bg-red-100 h-8 w-32 flex items-center  border border-red-700 justify-center rounded-lg">
                            <div
                              className=" inline-block h-3 w-3 border border-red-500 rounded-full" />
                            &nbsp;
                            {contadorTotal.erro} Erros
                          </div>
                          <div
                            className="bg-gray-100 h-8 w-32 flex items-center  border border-gray-700 justify-center rounded-lg">
                            <div
                              className=" inline-block h-3 w-3 border border-gray-500 rounded-full" />
                            &nbsp;
                            {contadorTotal.offline} Offline
                          </div>
                          <div
                            className="bg-green-100 h-8 w-32 flex items-center  border border-green-700 justify-center rounded-lg">
                            <div
                              className=" inline-block h-3 w-3 border border-green-500 rounded-full" />
                            &nbsp;
                            {contadorTotal.ok} Online
                          </div>
                          {mostrarLegado ? (
                            <div
                              className="bg-gray-300 h-8 w-32 flex items-center  border border-black justify-center rounded-lg">
                              <div
                                className=" inline-block h-3 w-3 border-2 border-black rounded-full bg-black" />
                              &nbsp;
                              {Object.keys(legado).length} Legados
                            </div>
                          ) : null}
                          <div
                            className=" h-8 w-32 flex items-center  justify-center rounded-lg border border-black">
                            <p>
                              <strong>Total:</strong>
                            </p>
                            &nbsp;
                            {Object.keys(maquinas).length +
                              Object.keys(legado).length}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}
export default Table
