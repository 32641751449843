import axios from 'axios'

const translate = (text) => {
    return axios.post(
        'https://deep-translate1.p.rapidapi.com/language/translate/v2',
        {
            q: text,
            source: 'en',
            target: 'pt'
        },
        {
            headers: {
                'x-rapidapi-key': import.meta.env.VITE_RAPID_API_KEY,
                'x-rapidapi-host': import.meta.env.VITE_RAPID_API_HOST
            }
        }
    )
}

export default translate
