import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline/index.js"; // Importe os ícones
import React, { useState } from "react";
import LoadingBlue from "../components/Layouts/loadingblue.jsx";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { toast } from "react-hot-toast";
import { useContext } from "react";
import PermissoesContext from "../context/PermisoesContext.jsx";
import { Button, IconButton, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Login = () => {
  const { atualizarPermissoes } = useContext(PermissoesContext);
  localStorage.removeItem("tenants");
  const [showLoad, setShowLoad] = useState(false);
  const navigate = useNavigate();
  const [, setCookie] = useCookies(["user"]);
  const [loading, setLoading] = useState(false);
  const [seePass, setSeePass] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const login = () => {
    setLoading(true);
    axios
      .post(
        `${import.meta.env.VITE_BACKEND_URL}/login`,
        { ...credentials },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          atualizarPermissoes(response.data.permissions);
          setCookie("user", `Bearer ${response.data.token}`, {
            path: "/",
            maxAge: response.data.expires_in,
          });
          setCookie("is_admin", response.data.is_admin, {
            path: "/",
            maxAge: response.data.expires_in,
          });
          window.location.replace("/");
        }
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          toast.error("Servidor indisponível", {
            id: "0",
          });
        } else if (error.response.status === 500) {
          toast.error("Erro interno do servidor, contate o administrador", {
            id: "500",
          });
        } else {
          toast.error(error.response.data, {
            id: error.response.status,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen flex-col p-2 bg-neutral-100">
      <div className="flex my-auto items-center flex-col justify-start bg-white h-auto min-h-[50vh] w-11/12 sm:w-2/4 md:w-2/3 lg:w-2/5 rounded-2xl shadow p-6">
        {showLoad ? (
          <div className="h-16 w-16 mt-4">
            <LoadingBlue />
          </div>
        ) : (
          <h1 className="mt-12 font-semibold text-2xl">Login</h1>
        )}

        <form
          className="flex flex-col w-full sm:w-5/6 md:w-4/6 lg:w-3/6"
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
        >
          <div className="flex flex-col mt-8 w-full">
            <TextField
              required={true}
              type="email"
              label="Email"
              size="medium"
              value={credentials.email}
              onChange={(e) =>
                setCredentials({ ...credentials, email: e.target.value })
              }
            />
          </div>

          <div className="flex flex-col mt-8 w-full">
            <div className="relative">
              <TextField
                className="w-full"
                autoComplete="email"
                required={true}
                label="Senha"
                size="medium"
                type={seePass ? "text" : "password"}
                value={credentials.password}
                onChange={(e) =>
                  setCredentials({ ...credentials, password: e.target.value })
                }
              />
              <IconButton
                color="primary"
                className="absolute -top-[44px] -end-[calc(100%-40px)]"
                type="button"
                onClick={() => {
                  setSeePass(!seePass);
                }}
              >
                {seePass ? (
                  <EyeSlashIcon className="size-5" />
                ) : (
                  <EyeIcon className="size-5" />
                )}
              </IconButton>
            </div>
          </div>

          <div className="mx-auto w-fit">
            <LoadingButton
              loading={loading}
              variant="contained"
              size="large"
              type="submit"
            >
              Entrar
            </LoadingButton>
          </div>

          <div className="mt-8 flex items-center justify-center text-blue-900">
            <Button
              variant="text"
              onClick={() => {
                setShowLoad(!showLoad);
                navigate("/forgot-password");
              }}
            >
              Esqueci a senha
            </Button>
          </div>
        </form>
      </div>
      <div>
        <p className="text-xs font-bold">F - v1.5.12</p>
        <p className="text-xs font-bold">B - v1.3.11</p>
      </div>
    </div>
  );
};

export default Login;
