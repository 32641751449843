import { useEffect, useState } from 'react';
import AxiosConfigs from '../../configs';
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import axios from 'axios';

const InvoiceCreation = ({ invoiceData, setInvoiceAdd }) => {
  const [municipios, setMunicipios] = useState([]);
  const [uf, setUf] = useState('');
  const [clients, setClients] = useState([]);
  const [services, setServices] = useState([]);
  const [invoice, setInvoice] = useState({
    client: '',
    status: 2,
    expiration_date: dayjs(),
    services: [
      {
        label: '',
        description: '',
        quantity: 0,
        unit_price: 0,
        unit_tax: 0,
      },
    ],
    metadata: '',
  });
  const [invoiceValue, setInvoiceValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const sendInvoice = () => {
    setLoading(true);
    const formattedInvoice = invoice;
    formattedInvoice.client = invoice.client.id;
    formattedInvoice.services = invoice.services.map((service) => {
      return {
        label: service.label?.label,
        description: service.description,
        quantity: service.quantity,
        unit_price: service.unit_price,
        unit_tax: service.unit_tax,
      };
    });

    AxiosConfigs.post('/invoices', formattedInvoice)
      //TODO: remover o campo description da inserção e pegar direto do retorno do serviço
      .then((response) => {
        if (response.status) {
          toast.success('Nota salva com sucesso!');
          setInvoiceAdd(false);
        }
      })
      .catch((error) => {
        toast.error('Falha ao envia a nota!');
        console.log(error);
      })
      .finally(setLoading(false));
  };

  const updateInvoice = () => {
    setLoading(true);

    const formattedInvoice = { ...invoice };
    formattedInvoice.client = invoice.client.id;
    formattedInvoice.services = invoice.services.map((service) => ({
      label: service.label.label ?? service.label,
      description: service.description,
      quantity: service.quantity,
      unit_price: service.unit_price,
      unit_tax: service.unit_tax,
    }));

    AxiosConfigs.put(`/invoices/${invoiceData.id}`, formattedInvoice)
      .then((response) => {
        if (response.status) {
          toast.success('Nota atualizada com sucesso!');
          setInvoiceAdd(false);
        }
      })
      .catch((error) => {
        toast.error('Falha ao atualizar a nota!');
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const handleServiceAdd = () => {
    setInvoice({
      ...invoice,
      services: [
        ...invoice.services,
        {
          label: '',
          description: '',
          quantity: 0,
          unit_price: 0,
          unit_tax: 0,
        },
      ],
    });
  };

  useEffect(() => {
    if (uf !== '') {
      axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/distritos?orderBy=nome&view=nivelado`).then((r) => {
        let novasCidades = r.data.map((atual) => atual['distrito-nome'].toUpperCase());
        novasCidades = [...new Set(novasCidades)]; //retorna um array com valores únicos já que a API retonar algumas cidades iguais
        setMunicipios(novasCidades);
      });
    }
  }, [uf]);

  useEffect(() => {
    Promise.all([AxiosConfigs.get('/new_clients'), AxiosConfigs.get('/services')]).then(([clientResponse, serviceResponse]) => {
      setClients(
        clientResponse.data.map((client) => {
          return {
            id: client.id,
            label: client.razao_social,
          };
        })
      );
      setServices(
        serviceResponse.data.map((service) => {
          return {
            id: service.id,
            label: service.name,
            description: service.description,
          };
        })
      );
    });
    if (invoiceData) {
      let state;
      switch (invoiceData.state) {
        case 'paid':
          state = 1;
          break;
        case 'pending':
          state = 2;
          break;
        case 'refunded':
          state = 3;
          break;
        default:
          state = '';
          break;
      }

      setInvoice({
        client: { id: invoiceData?.client?.id, label: invoiceData?.client?.razao_social },
        status: state,
        services: invoiceData.services ? [...invoiceData.services] : [],
        metadata: invoiceData.description.split(':')[1].split('</strong>')[0].trim(),
      });
    }
  }, []);

  return (
    <form
      className="bg-white p-5 shadow rounded-lg grid gap-5"
      onSubmit={(e) => {
        e.preventDefault();

        if (invoice.status === 2) {
          if (!invoiceData) {
            sendInvoice();
          } else {
            updateInvoice();
          }
        } else {
          if (invoiceData) {
            updateInvoice();
          } else {
            sendInvoice();
          }
        }
      }}
    >
      <Box>
        <Autocomplete
          value={invoice.client}
          options={clients}
          isOptionEqualToValue={(o, v) => v.id === o.id}
          renderInput={(params) => <TextField {...params} label="Cliente" required />}
          onChange={(_, e) => {
            setInvoice({
              ...invoice,
              client: e,
            });
          }}
        />
      </Box>
      <Box className="flex gap-3">
        {/* <DatePicker
          value={invoice.expiration_date}
          label="Data de vencimento"
          onChange={(e) => {
            setInvoice({
              ...invoice,
              expiration_date: e,
            });
          }}
        /> */}
        <FormControl required size="small" className="w-2/12">
          <InputLabel>Status</InputLabel>
          <Select
            defaultValue={0}
            value={invoice.status}
            label="Status"
            onChange={(e) => {
              setInvoice({
                ...invoice,
                status: e.target.value,
              });
            }}
          >
            <MenuItem value={1}>Emitido</MenuItem>
            <MenuItem value={2}>Em Digitação</MenuItem>
            <MenuItem value={3}>Cancelado</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="w-2/12" size="small">
          <InputLabel>UF</InputLabel>
          <Select
            className="w-full"
            label="UF"
            value={uf}
            variant="outlined"
            onChange={(e) => {
              setUf(e.target.value);
            }}
          >
            <MenuItem value="AC">Acre</MenuItem>
            <MenuItem value="AL">Alagoas</MenuItem>
            <MenuItem value="AP">Amapá</MenuItem>
            <MenuItem value="AM">Amazonas</MenuItem>
            <MenuItem value="BA">Bahia</MenuItem>
            <MenuItem value="CE">Ceará</MenuItem>
            <MenuItem value="DF">Distrito Federal</MenuItem>
            <MenuItem value="ES">Espírito Santo</MenuItem>
            <MenuItem value="GO">Goiás</MenuItem>
            <MenuItem value="MA">Maranhão</MenuItem>
            <MenuItem value="MT">Mato Grosso</MenuItem>
            <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
            <MenuItem value="MG">Minas Gerais</MenuItem>
            <MenuItem value="PA">Pará</MenuItem>
            <MenuItem value="PB">Paraíba</MenuItem>
            <MenuItem value="PR">Paraná</MenuItem>
            <MenuItem value="PE">Pernambuco</MenuItem>
            <MenuItem value="PI">Piauí</MenuItem>
            <MenuItem value="RJ">Rio de Janeiro</MenuItem>
            <MenuItem value="RN">Rio Grande do Norte</MenuItem>
            <MenuItem value="RS">Rio Grande do Sul</MenuItem>
            <MenuItem value="RO">Rondônia</MenuItem>
            <MenuItem value="RR">Roraima</MenuItem>
            <MenuItem value="SC">Santa Catarina</MenuItem>
            <MenuItem value="SP">São Paulo</MenuItem>
            <MenuItem value="SE">Sergipe</MenuItem>
            <MenuItem value="TO">Tocantins</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          className="w-2/5"
          autoHighlight={true}
          value={invoice.metadata}
          options={municipios}
          onChange={(_, value) => {
            setInvoice({
              ...invoice,
              metadata: value,
            });
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Municípios" placeholder="Municípios" />}
        />
      </Box>
      <Box className="flex justify-between">
        <Button variant="outlined" onClick={handleServiceAdd}>
          Adicionar serviço
        </Button>
        <Typography variant="subtitle2">
          <Typography
            variant="caption"
            sx={{
              fontWeight: 600,
            }}
          >
            Total da nota:{' '}
          </Typography>
          {invoiceValue.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Box>
      <Box>
        {invoice.services.map((service, index) => (
          <Box key={index}>
            <Box className="flex gap-3 ">
              <Autocomplete
                className="w-3/12"
                value={invoice.services[index].label}
                isOptionEqualToValue={(o, v) => (invoiceData ? v === o.label : v.label === o.label)}
                options={services}
                disableClearable
                renderInput={(params) => <TextField {...params} label="Serviço" required />}
                onChange={(_, newValue) => {
                  setInvoice((prevInvoice) => {
                    const updatedServices = [...prevInvoice.services];
                    updatedServices[index] = { ...updatedServices[index], label: newValue, description: newValue.description };
                    return { ...prevInvoice, services: updatedServices };
                  });
                }}
              />
              <TextField
                className="w-6/12"
                label="Descrição do serviço"
                value={invoice.services[index].description}
                required
                onChange={(e) => {
                  setInvoice((prevInvoice) => {
                    const updatedServices = [...prevInvoice.services];
                    updatedServices[index] = { ...updatedServices[index], description: e.target.value };
                    return { ...prevInvoice, services: updatedServices };
                  });
                }}
              />
              <TextField
                label="Quantidade"
                required
                value={invoice.services[index].quantity}
                onInput={(e) => {
                  setInvoice((prevInvoice) => {
                    const updatedServices = [...prevInvoice.services];
                    updatedServices[index] = { ...updatedServices[index], quantity: e.target.value.replace(/\D/g, '') };
                    return { ...prevInvoice, services: updatedServices };
                  });
                }}
              />
              <TextField
                label="Preço unitário"
                required
                value={invoice.services[index].unit_price}
                onInput={(e) => {
                  setInvoice((prevInvoice) => {
                    const updatedServices = [...prevInvoice.services];
                    updatedServices[index] = { ...updatedServices[index], unit_price: e.target.value.replace(',', '.').replace(/[a-zZ-Z]/g, '') };
                    return { ...prevInvoice, services: updatedServices };
                  });
                }}
                onBlur={() => {
                  setInvoiceValue(invoice.services.reduce((acc, service) => acc + service.quantity * service.unit_price, 0));
                }}
              />
            </Box>
            <Typography variant="subtitle2" className="text-end w-full inline-block px-2">
              {(service.quantity * service.unit_price).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Box>
        ))}
        <Box className="flex justify-end pt-5">
          <LoadingButton type="submit" variant="contained" loading={loading}>
            {invoice.status !== 2 ? 'Finalizar' : 'Salvar'}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

export default InvoiceCreation;
