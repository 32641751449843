import React, { useContext, useEffect, useState } from 'react'
import AxiosConfigs from '../../configs.js'
import Modal from 'react-modal'
import PermissionsTab from './PermissionsTab.jsx'
import { useCookies } from 'react-cookie'
import { UsuarioContext } from '../../routes/Users.jsx'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline/index.js'
import { toast } from 'react-hot-toast'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'

const Permissions = ({ tenantToAdd, switchCofre, setSwitchCofre }) => {
    const permissoes = JSON.parse(localStorage.getItem('localPermissions'))
    //importa a variavel que foi exportada pelo componente Users de forma global
    const { novoUsuario, setNovoUsuario } = useContext(UsuarioContext)
    //verifica se é usuario nagix para mostrar conteudo
    const [cookies] = useCookies('is_admin')
    const isAdmin = cookies.is_admin

    const [tenants, setTenants] = useState([])
    const [namedTenants, setNamedTenants] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [showLoad, setShowLoad] = useState(true)
    const [modalPermissionsIsOpen, setlPermissionsIsOpen] = useState(false)
    const [, setArrayPermissions] = useState([])
    // variavel feita para usar em PermissionsTab para verificar se foi selecionado algo diferente de 'none' no select de cargo para assim exibir o btn
    const [selectStatus, setselectStatus] = useState(false)

    //variavel para receber a valor de usuario acronis
    const [acronis, setAcronis] = useState([])
    //variavel para receber a valor de usuario cofre
    const [cofre, setCofre] = useState([])

    //----------------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        AxiosConfigs.get('/tenants')
            .then((response) => {
                const fetchedTenants = response.data
                setAcronis(fetchedTenants)
            })
            .catch((error) => {
                console.error('Error fetching tenants:', error)
            })
            .finally(() => {
                setShowLoad(false) // Escondendo o indicador de carregamento após o carregamento dos dados
            })

        AxiosConfigs.get('/clients')
            .then((response) => {
                setCofre(response.data)
            })
            .catch((error) => {
                console.error('Error fetching tenants:', error)
            })
    }, [tenantToAdd]) //Dependência adicionada para atualizar quando tenantToAdd mudar
    //----------------------------------------------------------------------------------------------------------------------------------------------

    //PEGA APENAS OS TENANTS QUE TEM PROPRIEDADE NOME, em tenants vem o storage. aqui seleciono todos, menos storage
    useEffect(() => {
        const filteredTenants = tenants.filter((tenant) => Object.prototype.hasOwnProperty.call(tenant, 'name'))
        setNamedTenants(filteredTenants)
    }, [tenants])

    //----------------------------------------------------------------------------------------------------------------------------------------------
    //valor da pesquisa do input
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    }
    //----------------------------------------------------------------------------------------------------------------------------------------------
    //FILTRA TENANTS COM COM PESQUISA POR NOME
    const filteredTenants = namedTenants.filter((tenant) => {
        return tenant.name.toLowerCase().includes(searchTerm.toLowerCase())
    })

    //----------------------------------------------------------------------------------------------------------------------------------------------
    //tenant pra receber permissoes
    const [tenant, setTenant] = useState('')

    function openPermissionsModal(tenant) {
        setTenant(tenant)
        setlPermissionsIsOpen(true)
    }

    function closePermissionsModal() {
        setlPermissionsIsOpen(false)
    }

    //----------------------------------------------------------------------------------------------------------------------------------------------

    return (
        <div className="rounded-t-lg h-full">
            <div className="col-span-12 row-start-1 flex justify-end items-center rounded-t-lg ">
                <div className="p-4">
                    <input
                        className="p-2 border rounded-md h-8 focus:outline-none focus:border-2 focus:border-blue-700"
                        placeholder="Pesquisar"
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
            <div className="row-start-2 row-span-11 col-span-12 border-t bg-white border-blue-700 overflow-y-auto relative">
                {showLoad ? (
                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                        <svg
                            className="animate-spin size-20 text-blue-700"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            />
                            <path
                                className="opacity-75"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                ) : (
                    <Accordion
                        allowZeroExpanded={true}
                        className="transition duration-200">
                        {/* Tenants Accordion Item */}
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton className="bg-neutral-100 h-14 flex items-center  hover:bg-blue-100">
                                    <button
                                        className="flex items-center justify-center w-full h-full"
                                        type="button"
                                        onClick={() => {
                                            setTenants(acronis)
                                            setSwitchCofre(false)
                                        }}>
                                        <span className="font-bold text-xl text-gray-600">Acronis</span>
                                    </button>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="bg-neutral-200">
                                <table className="w-full h-full">
                                    <tbody>
                                        {filteredTenants
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((tenant) => {
                                                const isInNovoUsuario = novoUsuario.tenants.some((novoTenant) => novoTenant.tenant === tenant.id)
                                                if (isAdmin) {
                                                    return (
                                                        <tr
                                                            key={tenant.id}
                                                            className={`border odd:bg-neutral-100 hover:bg-blue-100 text-gray-500 cursor-pointer ${isInNovoUsuario ? 'text-gray-200 cursor-auto' : ''}`}
                                                            onClick={() => {
                                                                if (isInNovoUsuario) {
                                                                    toast.success('Cliente já selecionado')
                                                                } else {
                                                                    openPermissionsModal(tenant)
                                                                    setTenant(tenant)
                                                                    setNovoUsuario((prevState) => ({
                                                                        ...prevState,
                                                                        tenants: [...(prevState.tenants || []), { tenant: tenant.id, tenant_name: tenant.name }]
                                                                    }))
                                                                }
                                                            }}>
                                                            <td className="border w-full flex justify-between items-center">
                                                                <p className="px-2 py-1">{tenant.name}</p>
                                                                {isInNovoUsuario ? (
                                                                    <div />
                                                                ) : (
                                                                    <div
                                                                        className="flex items-center mr-20 cursor-pointer p-1 px-3"
                                                                        title="Permissões">
                                                                        <ClipboardDocumentListIcon className="size-6 text-blue-900" />
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                } else {
                                                    //conteudo se nao for ADM com base nas permissoes
                                                    const hasPermissionCreate = permissoes.tenants.some((userpermissions) => userpermissions.tenant === tenant.id) && permissoes.tenants.find((userpermissions) => userpermissions.tenant === tenant.id)?.permissions?.users?.create === true
                                                    return hasPermissionCreate ? (
                                                        <tr
                                                            key={tenant.id}
                                                            className={`border odd:bg-neutral-100 hover:bg-blue-100 text-gray-500 cursor-pointer ${isInNovoUsuario ? 'text-gray-200 cursor-auto' : ''}`}
                                                            onClick={() => {
                                                                if (isInNovoUsuario) {
                                                                    toast.success('Cliente já selecionado')
                                                                } else {
                                                                    openPermissionsModal(tenant)
                                                                    setTenant(tenant)
                                                                    setNovoUsuario((prevState) => ({
                                                                        ...prevState,
                                                                        tenants: [...(prevState.tenants || []), { tenant: tenant.id, tenant_name: tenant.name }]
                                                                    }))
                                                                }
                                                            }}>
                                                            <td className="border w-full flex justify-between items-center">
                                                                <p className="px-2 py-1">{tenant.name}</p>
                                                                {/*Mostrar ou nao icone se ja selecionado*/}
                                                                {isInNovoUsuario ? (
                                                                    <div />
                                                                ) : (
                                                                    <div
                                                                        className="flex items-center mr-20 cursor-pointer p-1 px-3"
                                                                        title="Permissões">
                                                                        <ClipboardDocumentListIcon className="size-6 text-blue-900" />
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ) : null
                                                }
                                            })}
                                    </tbody>
                                </table>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton className="bg-neutral-100 border-t border-neutral-400 h-14 flex items-center hover:bg-blue-100">
                                    <button
                                        className="flex items-center justify-center w-full h-full "
                                        type="button"
                                        onClick={() => {
                                            setTenants(cofre)
                                            setSwitchCofre(true)
                                        }}>
                                        <span className="font-bold text-xl text-gray-600">Cofre</span>
                                    </button>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="bg-neutral-200">
                                <table className="w-full h-full">
                                    <tbody>
                                        {filteredTenants
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((tenant) => {
                                                const isInNovoUsuario = novoUsuario.clients?.some((novoClient) => novoClient.client === tenant.id)
                                                if (isAdmin) {
                                                    return (
                                                        <tr
                                                            key={tenant.id}
                                                            className={`border odd:bg-neutral-100 hover:bg-blue-100 text-gray-500 cursor-pointer ${isInNovoUsuario ? 'text-gray-200 cursor-auto' : ''}`}
                                                            onClick={() => {
                                                                if (isInNovoUsuario) {
                                                                    toast.success('Cliente já selecionado')
                                                                } else {
                                                                    openPermissionsModal(tenant)
                                                                    setTenant(tenant)
                                                                    setNovoUsuario((prevState) => ({
                                                                        ...prevState,
                                                                        clients: [...(prevState.clients || []), { client: tenant.id, client_name: tenant.name }]
                                                                    }))
                                                                }
                                                            }}>
                                                            <td className="border w-full flex justify-between items-center">
                                                                <p className="px-2 py-1">{tenant.name}</p>
                                                                {/*Mostrar ou nao icone se ja selecionado*/}
                                                                {isInNovoUsuario ? (
                                                                    <div />
                                                                ) : (
                                                                    <div
                                                                        className="flex items-center mr-20 cursor-pointer p-1 px-3"
                                                                        title="Permissões">
                                                                        <ClipboardDocumentListIcon className="size-6 text-blue-900" />
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                } else {
                                                    //conteudo se nao for ADM com base nas permissoes
                                                    const hasPermissionCreate = permissoes.tenants.some((userpermissions) => userpermissions.tenant_name === tenant.name) && permissoes.tenants.find((userpermissions) => userpermissions.tenant_name === tenant.name)?.permissions?.users?.create === true
                                                    return hasPermissionCreate ? (
                                                        <tr
                                                            key={tenant.id}
                                                            className={`border odd:bg-neutral-100 hover:bg-blue-100 text-gray-500 cursor-pointer ${isInNovoUsuario ? 'text-gray-200 cursor-auto' : ''}`}
                                                            onClick={() => {
                                                                if (isInNovoUsuario) {
                                                                    toast.success('Cliente já selecionado')
                                                                } else {
                                                                    openPermissionsModal(tenant)
                                                                    setTenant(tenant)
                                                                    setNovoUsuario((prevState) => ({
                                                                        ...prevState,
                                                                        tenants: [...(prevState.tenants || []), { tenant: tenant.id, tenant_name: tenant.name }]
                                                                    }))
                                                                }
                                                            }}>
                                                            <td className="border w-full flex justify-between items-center">
                                                                <p className="px-2 py-1">{tenant.name}</p>
                                                                {isInNovoUsuario ? (
                                                                    <div />
                                                                ) : (
                                                                    <div
                                                                        className="flex items-center mr-20 cursor-pointer p-1 px-3"
                                                                        title="Permissões">
                                                                        <ClipboardDocumentListIcon className="size-6 text-blue-900" />
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ) : null
                                                }
                                            })}
                                    </tbody>
                                </table>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                )}
            </div>

            <div>
                <Modal
                    ariaHideApp={false}
                    className="absolute top-2/4 left-2/4 right-auto bottom-auto -mr-2/4 -translate-x-2/4 -translate-y-2/4 border rounded-lg border-blue-700 h-3/6 w-3/6 bg-white shadow-lg shadow-blue-200 grid grid-rows-12 grid-cols-12"
                    contentLabel="Example Modal"
                    isOpen={modalPermissionsIsOpen}
                    // onRequestClose={closePermissionsModal}
                >
                    <div className="row-start-1 row-span-10 col-span-12 rounded-t-lg">
                        <PermissionsTab
                            selectStatus={selectStatus}
                            setselectStatus={setselectStatus}
                            switchCofre={switchCofre}
                            tenant={tenant} // tenant escolhido
                        />
                    </div>
                    <div className="row-start-11 row-end-13 col-span-12 grid grid-cols-12 rounded-b-lg">
                        <div className="col-start-2 col-span-2 content-center flex items-center justify-center">
                            <button
                                className="h-8 w-20 bg-rose-600 text-white tracking-tight font-medium rounded-lg  duration-300 hover:bg-rose-700"
                                type="button"
                                onClick={() => {
                                    if (switchCofre) {
                                        novoUsuario.clients.pop()
                                    } else {
                                        novoUsuario.tenants.pop()
                                    }
                                    setselectStatus(false)
                                    closePermissionsModal()
                                }}>
                                Voltar
                            </button>
                        </div>

                        <div className="col-start-10 col-span-2 content-center flex items-center justify-center">
                            {selectStatus === true ? (
                                <button
                                    className="h-8 w-20 bg-blue-900 text-white tracking-tight font-medium rounded-lg duration-300 hover:bg-blue-950"
                                    type="button"
                                    onClick={() => {
                                        setArrayPermissions((prevArray) => [...prevArray, tenant])
                                        closePermissionsModal()
                                        setselectStatus(false)
                                    }}>
                                    Salvar
                                </button>
                            ) : null}
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default Permissions
