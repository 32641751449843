import React, { useContext, useEffect, useState } from 'react';
import AxiosConfigs from '../configs.js';
import { toast } from 'react-hot-toast';
import { PlusIcon } from '@heroicons/react/20/solid/index.js';
import { MinusIcon } from '@heroicons/react/24/solid/index.js';
import { Autocomplete, Box, Breadcrumbs, Button, Checkbox, Container, FormControl, IconButton, InputLabel, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Select as MUISelect } from '@mui/material';
import Select from 'react-select';
import Layout from '../components/Layouts/Layout.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const CreateMachineInventory = () => {
  const navigate = useNavigate();
  const [ipMaquina, setIpMaquina] = useState(['']);
  const [hd, setHd] = useState(['']);
  const machineId = useParams().id;
  const [isLoading, setIsLoading] = useState(false);
  const [inventory, setInventory] = useState({
    tenant_id: 0,
    name: '',
    machineType: '',
    otherMachineType: '',
    soFamily: '',
    soModel: '',
    cpu: '',
    ram: '',
    ip: [],
    hd: [],
    directories: [],
    itHasBackup: false,
    backupRTO: '',
    backupTool: '',
    backupType: '',
    otherBackupTool: '',
    dailyRetention: {
      quantity: 0,
      time: [],
    },
    weeklyRetention: {
      quantity: 0,
      time: [
        {
          time: dayjs().format('HH:mm'),
          weekDay: 'monday',
        },
      ],
    },
    biWeeklyRetention: {
      quantity: 0,
      time: [{ day: 0, time: dayjs().format('HH:mm') }],
    },
    monthlyRetention: {
      quantity: 0,
      time: [{ date: dayjs().format('DD/MM/YY'), time: dayjs().format('HH:mm') }],
    },
    yearlyRetention: {
      quantity: 0,
      time: [{ date: dayjs().format('DD/MM/YY'), time: dayjs().format('HH:mm') }],
    },
    comment: '',
    is_on_backup: false,
  });
  const [clients, setClients] = useState([]);
  const [tabValue, setTabValue] = useState('machine');

  const handleIpsChange = (index, value) => {
    const newIpMaquinas = [...ipMaquina];
    newIpMaquinas[index] = value;
    inventory.ip[index] = value;
    setIpMaquina(newIpMaquinas);
  };
  const addRowIp = () => {
    setIpMaquina([...ipMaquina, '']);
  };

  const handleHdChange = (index, value) => {
    const newHdMaquinas = [...hd];
    inventory.hd[index] = value;
    newHdMaquinas[index] = value;
    setHd(newHdMaquinas);
  };

  const addRowHd = () => {
    setHd([...hd, '']);
  };

  const fetchInventario = () => {
    setIsLoading(true);
    AxiosConfigs.post(`/inventory`, inventory)
      .then((response) => {
        if (response.status === 201) {
          toast.success('Maquina cadastrada');
          navigate('/inventory');
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateInventario = () => {
    AxiosConfigs.post(`/inventory/${inventory.id}`, inventory)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Maquina atualizada');
          navigate('/inventory');
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if (!inventory.itHasBackup) {
      setInventory({
        ...inventory,
        backupTool: '',
        backupType: '',
        otherBackupTool: '',
        dailyRetention: {
          quantity: 0,
          time: [],
        },
        weeklyRetention: {
          quantity: 0,
          time: [],
        },
        biWeeklyRetention: {
          quantity: 0,
          time: [],
        },
        monthlyRetention: {
          quantity: 0,
          time: [],
        },
        yearlyRetention: {
          quantity: 0,
          time: [],
        },
        comment: '',
      });
    }
  }, [inventory.itHasBackup]);

  useEffect(() => {
    if (machineId) {
      AxiosConfigs.get(`/inventory/${machineId}`)
        .then((response) => {
          setHd([...response.data.hd]);
          setIpMaquina([...response.data.ip]);
          setInventory({ ...inventory, ...response.data });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
    AxiosConfigs.get('/new_clients?page=1').then((response) => {
      setClients(response.data);
    });
  }, []);

  //-----------------------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <Layout active="inventories">
      <Container className="tracking-tighter p-5">
        <div className="py-5 flex justify-between">
          <Breadcrumbs aria-label="breadcrumb">
            <span>Inventario</span>
            <span>{inventory.id ? `Atualizar ${inventory.name}` : 'Cadastro'}</span>
          </Breadcrumbs>
        </div>
        <form
          className="tracking-tighter"
          onSubmit={(e) => {
            e.preventDefault();
            e.currentTarget.reset();
            inventory.id ? updateInventario() : fetchInventario();
          }}
        >
          <div className="grid content-between size-full">
            <TabContext value={tabValue}>
              <Box className="bg-white rounded-lg shadow px-2">
                <Tabs
                  value={tabValue}
                  onChange={(_, value) => {
                    setTabValue(value);
                  }}
                >
                  <Tab label="Máquina" value="machine" />
                  <Tab label="Backup" value="backup" />
                  <Tab label="Horário dos backups" value="backup_hours" disabled={!inventory.itHasBackup} />
                </Tabs>
              </Box>
              <TabPanel value="machine" sx={{ px: 0 }}>
                <div className="grid gap-5 shadow bg-white rounded-lg p-10 size-full">
                  <Autocomplete
                    autoHighlight={true}
                    options={clients}
                    value={clients.find((client) => client.id === inventory.tenant_id) || null}
                    renderInput={(params) => <TextField {...params} label="Cliente" variant="outlined" />}
                    getOptionLabel={(option) => option.fantasy_name}
                    filterOptions={(options, params) => {
                      const filtered = options.filter((option) => {
                        return option.fantasy_name.toLowerCase().includes(params.inputValue.toLowerCase());
                      });
                      return filtered;
                    }}
                    onChange={(_, data) =>
                      setInventory({
                        ...inventory,
                        tenant_id: data.id,
                      })
                    }
                  />
                  {/* </div> */}
                  <div className="flex gap-5">
                    <div className="w-1/2">
                      <TextField
                        className="w-full"
                        id="outlined-basic"
                        label="Nome do dispositivo"
                        required={true}
                        size="small"
                        type="text"
                        value={inventory.name}
                        variant="outlined"
                        onChange={(event) =>
                          setInventory({
                            ...inventory,
                            name: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="w-1/2">
                      <FormControl fullWidth size="small">
                        <InputLabel>Tipo de máquina</InputLabel>
                        <MUISelect
                          label="Tipo de máquina"
                          value={inventory.machineType}
                          onChange={(event) =>
                            setInventory({
                              ...inventory,
                              machineType: event.target.value,
                            })
                          }
                        >
                          <MenuItem value="vm">Virtual</MenuItem>
                          <MenuItem value="fisica">Física</MenuItem>
                          <MenuItem value="other">Outro</MenuItem>
                        </MUISelect>
                      </FormControl>

                      <div className="mt-3" hidden={inventory.machineType !== 'other'}>
                        <TextField
                          className="w-full"
                          label="Tipo da máquina"
                          size="small"
                          type="text"
                          value={inventory.otherMachineType}
                          variant="outlined"
                          onChange={(event) =>
                            setInventory({
                              ...inventory,
                              otherMachineType: event.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-5">
                    <div className="w-1/2">
                      <FormControl fullWidth size="small">
                        <InputLabel>Familia do SO</InputLabel>
                        <MUISelect
                          label="Familia do SO"
                          value={inventory.soFamily.toLowerCase()}
                          onChange={(event) =>
                            setInventory({
                              ...inventory,
                              soFamily: event.target.value,
                            })
                          }
                        >
                          <MenuItem value="linux">Linux</MenuItem>
                          <MenuItem value="macos">MacOS</MenuItem>
                          {/* <MenuItem value="vmware">VMware</MenuItem> */}
                          <MenuItem value="windows">Windows</MenuItem>
                        </MUISelect>
                      </FormControl>
                    </div>

                    <div className="w-1/2">
                      <FormControl fullWidth size="small">
                        <InputLabel>Modelo do SO</InputLabel>
                        <MUISelect
                          disabled={inventory.soFamily.length === 0}
                          label="Modelo do SO"
                          value={inventory.soModel}
                          onChange={(event) =>
                            setInventory({
                              ...inventory,
                              soModel: event.target.value,
                            })
                          }
                        >
                          {inventory.soFamily === 'linux'
                            ? [
                                <MenuItem key="debian" value="debian">
                                  Debian
                                </MenuItem>,
                                <MenuItem key="fedora" value="fedora">
                                  Fedora
                                </MenuItem>,
                                <MenuItem key="ubuntu" value="ubuntu">
                                  Ubuntu
                                </MenuItem>,
                                <MenuItem key="centos stream" value="centos stream">
                                  CentOS Stream
                                </MenuItem>,
                                <MenuItem key="rocky linux" value="rocky linux">
                                  Rocky Linux
                                </MenuItem>,
                                <MenuItem key="almalinux" value="almalinux">
                                  AlmaLinux
                                </MenuItem>,
                                <MenuItem key="fedora server" value="fedora server">
                                  Fedora Server
                                </MenuItem>,
                                <MenuItem key="opensuse leap" value="opensuse leap">
                                  openSUSE Leap
                                </MenuItem>,
                                <MenuItem key="rhel" value="rhel">
                                  RHEL (Red Hat Enterprise Linux)
                                </MenuItem>,
                                <MenuItem key="oracle Linux" value="oracle Linux">
                                  Oracle Linux
                                </MenuItem>,
                                <MenuItem key="freebsd" value="freebsd">
                                  FreeBSD
                                </MenuItem>,
                              ]
                            : null}

                          {inventory.soFamily === 'macos'
                            ? [
                                <MenuItem key="catalina" value="catalina">
                                  Catalina
                                </MenuItem>,
                                <MenuItem key="mojave" value="mojave">
                                  Mojave
                                </MenuItem>,
                                <MenuItem key="big-sur" value="big sur">
                                  Big Sur
                                </MenuItem>,
                              ]
                            : null}

                          {/* {inventory.soFamily === "vmware"
                            ? [
                                <MenuItem key="esxi" value="esxi">
                                  ESXi
                                </MenuItem>,
                                <MenuItem key="vcenter" value="vCenter">
                                  vCenter
                                </MenuItem>,
                              ]
                            : null} */}

                          {inventory.soFamily === 'windows'
                            ? [
                                <MenuItem key="11" value="11">
                                  11
                                </MenuItem>,
                                <MenuItem key="10" value="10">
                                  10
                                </MenuItem>,
                                <MenuItem key="8.1" value="8.1">
                                  8.1
                                </MenuItem>,
                                <MenuItem key="7" value="7">
                                  7
                                </MenuItem>,
                                <MenuItem key="server-2022" value="server 2022">
                                  Server 2022
                                </MenuItem>,
                                <MenuItem key="server-2019" value="server 2019">
                                  Server 2019
                                </MenuItem>,
                                <MenuItem key="server-2016" value="server 2016">
                                  Server 2016
                                </MenuItem>,
                                <MenuItem key="server-2012" value="server 2012">
                                  Server 2012
                                </MenuItem>,
                                <MenuItem key="server-2008-r2" value="server 2008 r2">
                                  Server 2008 R2
                                </MenuItem>,
                                <MenuItem key="server-2008-r2-standard" value="server 2008 r2 standard">
                                  Server 2008 R2 Standard
                                </MenuItem>,
                                <MenuItem key="server-2008" value="server 2008">
                                  Server 2008
                                </MenuItem>,
                                <MenuItem key="server-2003" value="server 2003">
                                  Server 2003
                                </MenuItem>,
                              ]
                            : null}
                        </MUISelect>
                      </FormControl>
                    </div>
                  </div>

                  <div className="flex">
                    {/* <div className="w-1/2">
                      <TextField
                        className="w-full"
                        label="Processador"
                        size="small"
                        type="text"
                        required={true}
                        value={inventory.cpu}
                        variant="outlined"
                        onChange={(event) =>
                          setInventory({
                            ...inventory,
                            cpu: event.target.value,
                          })
                        }
                      />
                    </div> */}
                    <div className="w-1/3">
                      <TextField
                        className="w-full"
                        label="Memória RAM"
                        size="small"
                        value={inventory.ram}
                        variant="outlined"
                        onChange={(event) =>
                          setInventory({
                            ...inventory,
                            ram: event.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="w-1/3 max-h-56 overflow-y-auto grid gap-2 py-2">
                      {ipMaquina.map((value, index) => (
                        <div key={index} className="flex gap-2 w-full">
                          <IconButton
                            color="error"
                            onClick={() => {
                              if (ipMaquina.length >= 2) {
                                const newIpMaquinas = [...ipMaquina];
                                newIpMaquinas.splice(index, 1);
                                setIpMaquina([...newIpMaquinas]);
                                inventory.ip.splice(index, 1);
                              } else {
                                ipMaquina.length = 1;
                                toast.error('Não é possível remover mais IPs', {
                                  id: 1,
                                });
                              }
                            }}
                          >
                            <MinusIcon className="size-4 mx-auto" />
                          </IconButton>
                          <TextField
                            id={`base${index}`}
                            className="w-full"
                            label="IP"
                            size="small"
                            type="text"
                            value={value}
                            variant="outlined"
                            onChange={(e) => handleIpsChange(index, e.target.value)}
                          />
                        </div>
                      ))}
                      <div className="flex justify-end gap-5">
                        <IconButton color="primary" onClick={addRowIp}>
                          <PlusIcon className="size-4 mx-auto" />
                        </IconButton>
                      </div>
                    </div>

                    <div className="w-1/3 max-h-56 overflow-y-auto grid gap-2 py-2">
                      {hd.map((value, index) => (
                        <div key={index} className="flex gap-2 w-full items-center">
                          <IconButton
                            color="error"
                            onClick={() => {
                              if (hd.length >= 2) {
                                const newHdMaquinas = [...hd];
                                newHdMaquinas.splice(index, 1);
                                setHd([...newHdMaquinas]);
                                inventory.hd.splice(index, 1);
                              } else {
                                hd.length = 1;
                                toast.error('Não é possível remover mais HDs', {
                                  id: 1,
                                });
                              }
                            }}
                          >
                            <MinusIcon className="size-4 mx-auto" />
                          </IconButton>
                          <TextField
                            id={`base${index}`}
                            className="w-full"
                            label="HD"
                            size="small"
                            type="text"
                            value={value}
                            variant="outlined"
                            onChange={(e) => handleHdChange(index, e.target.value)}
                          />
                        </div>
                      ))}
                      <div className="flex justify-end gap-5">
                        <IconButton color="primary" onClick={addRowHd}>
                          <PlusIcon className="size-4 mx-auto" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="backup" sx={{ px: 0 }}>
                <div className="grid gap-5 shadow bg-white rounded-lg p-10 size-full">
                  <div className="w-full flex gap-10">
                    <div className="w-1/2">
                      <FormControl fullWidth size="small">
                        <InputLabel>Dispositivo possui backup?</InputLabel>
                        <MUISelect
                          label="Dispositivo possui backup?"
                          defaultValue={false}
                          value={inventory.itHasBackup}
                          required={true}
                          onChange={(event) =>
                            setInventory({
                              ...inventory,
                              itHasBackup: event.target.value,
                            })
                          }
                        >
                          <MenuItem value={true}>Sim</MenuItem>
                          <MenuItem value={false}>Não</MenuItem>
                        </MUISelect>
                      </FormControl>
                    </div>
                    <div className="w-1/2 grid gap-2">
                      <FormControl className="w-full" fullWidth size="small" disabled={inventory.itHasBackup === false}>
                        <InputLabel>Ferramenta do backup</InputLabel>
                        <MUISelect
                          className="disabled:bg-gray-200"
                          label="Ferramenta do backup"
                          defaultValue={null}
                          value={inventory.backupTool}
                          required={inventory.itHasBackup}
                          onChange={(event) =>
                            setInventory({
                              ...inventory,
                              backupTool: event.target.value,
                            })
                          }
                        >
                          <MenuItem value="acronisCyberBackup">Acronis Cyber Backup</MenuItem>
                          <MenuItem value="veeamBackupReplication">Veeam Backup & Replication</MenuItem>
                          <MenuItem value="commvaultCompleteBackupRecovery">Commvault Complete Backup & Recovery</MenuItem>
                          <MenuItem value="veritasBackupExec">Veritas Backup Exec</MenuItem>
                          <MenuItem value="ibmSpectrumProtect">IBM Spectrum Protect</MenuItem>
                          <MenuItem value="dellEmcAvamar">Dell EMC Avamar</MenuItem>
                          <MenuItem value="rubrik">Rubrik</MenuItem>
                          <MenuItem value="cohesityDataProtect">Cohesity DataProtect</MenuItem>
                          <MenuItem value="bacula">Bacula</MenuItem>
                          <MenuItem value="amanda">Amanda (Advanced Maryland Automatic Network Disk Archiver)</MenuItem>
                          <MenuItem value="duplicity">Duplicity</MenuItem>
                          <MenuItem value="restic">Restic</MenuItem>
                          <MenuItem value="naquivo">Naquivo</MenuItem>
                          <MenuItem value="borgBackup">BorgBackup</MenuItem>
                          <MenuItem value="urBackup">UrBackup</MenuItem>
                          <MenuItem value="other">Outro</MenuItem>
                        </MUISelect>
                      </FormControl>
                      {inventory.backupTool === 'other' ? (
                        <TextField
                          className="w-full"
                          id="outlined-basic"
                          label="Nome da ferramenta"
                          required={true}
                          size="small"
                          type="text"
                          hidden={inventory.backupTool !== 'other'}
                          value={inventory.otherBackupTool}
                          variant="outlined"
                          onChange={(event) =>
                            setInventory({
                              ...inventory,
                              otherBackupTool: event.target.value,
                            })
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="flex gap-10">
                    <div className="w-1/2">
                      <FormControl fullWidth size="small" disabled={!inventory.itHasBackup}>
                        <InputLabel>Tipo do backup</InputLabel>
                        <MUISelect
                          label="Tipo do backup"
                          defaultValue={''}
                          value={inventory.backupType}
                          required={true}
                          onChange={(event) =>
                            setInventory({
                              ...inventory,
                              backupType: event.target.value,
                            })
                          }
                        >
                          <MenuItem value="baremetal">Máquina inteira</MenuItem>
                          <MenuItem value="directories">Diretórios</MenuItem>
                        </MUISelect>
                        <div className="w-full max-h-56 overflow-y-auto grid gap-2 py-2">
                          {inventory.directories.length > 0 &&
                            inventory.directories.map((value, index) => (
                              <div key={index} className="flex gap-1 w-full">
                                <TextField
                                  id={`base${index}`}
                                  className="w-full"
                                  label="Diretório"
                                  size="small"
                                  type="text"
                                  value={value}
                                  variant="outlined"
                                  onChange={(e) => {
                                    const newDirectories = [...inventory.directories];
                                    newDirectories[index] = e.target.value;
                                    setInventory({
                                      ...inventory,
                                      directories: newDirectories,
                                    });
                                  }}
                                />
                                <IconButton
                                  color="error"
                                  onClick={() => {
                                    if (inventory.directories.length >= 2) {
                                      const newDirectories = [...inventory.directories];
                                      newDirectories.splice(index, 1);
                                      setInventory({
                                        ...inventory,
                                        directories: newDirectories,
                                      });
                                    } else {
                                      inventory.directories.length = 1;
                                      toast.error('Não é possível remover mais diretórios', {
                                        id: 1,
                                      });
                                    }
                                  }}
                                >
                                  <MinusIcon className="size-4 mx-auto" />
                                </IconButton>
                              </div>
                            ))}

                          <div className="flex gap-5" hidden={inventory.backupType !== 'directories'}>
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={() => {
                                setInventory({
                                  ...inventory,
                                  directories: [...inventory.directories, ''],
                                });
                              }}
                            >
                              Adicionar
                            </Button>
                          </div>
                        </div>
                      </FormControl>
                    </div>
                    <div className="w-1/2">
                      <FormControl fullWidth size="small" disabled={!inventory.itHasBackup}>
                        <InputLabel>Método do backup</InputLabel>
                        <MUISelect
                          label="Método do backup"
                          defaultValue=""
                          value={inventory.agentLocation}
                          required={true}
                          onChange={(event) =>
                            setInventory({
                              ...inventory,
                              agentLocation: event.target.value,
                            })
                          }
                        >
                          <MenuItem value="host">Host</MenuItem>
                          <MenuItem value="agent">Agente</MenuItem>
                        </MUISelect>
                      </FormControl>
                    </div>
                  </div>
                  {/* <div className="w-3/4">
                        <TextField
                          className="w-full"
                          id="outlined-basic"
                          label="Tempo de armazenamento (RTO)"
                          required={true}
                          size="small"
                          type="text"
                          value={inventory.backupRTO}
                          disabled={inventory.itHasBackup === false}
                          variant="outlined"
                          onChange={(event) =>
                            setInventory({
                              ...inventory,
                              backupRTO: event.target.value,
                            })
                          }
                        />
                      </div> */}

                  <div className="w-full mx-auto mt-4">
                    {/*<InputLabel htmlFor="comment">Comentário</InputLabel>*/}
                    <TextField
                      className="w-full p-2 block"
                      label="Comentário"
                      multiline
                      value={inventory.comment}
                      rows={5}
                      onChange={(event) =>
                        setInventory({
                          ...inventory,
                          comment: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="backup_hours" sx={{ px: 0 }}>
                <div className="grid gap-5 shadow bg-white rounded-lg p-10 size-full">
                  <div className="flex items-center gap-2 overflow-hidden">
                    <div className="flex items-center w-2/12 justify-between">
                      <Typography>Diária</Typography>
                      <MUISelect
                        size="small"
                        value={inventory.dailyRetention.quantity}
                        onChange={(event) => {
                          const newDailyRetention = [];
                          for (let index = 0; index < event.target.value; index++) {
                            newDailyRetention.push('00:00');
                          }
                          setInventory({
                            ...inventory,
                            dailyRetention: {
                              ...inventory.dailyRetention,
                              time: newDailyRetention,
                              quantity: event.target.value,
                            },
                          });
                        }}
                      >
                        {Array.from({ length: 25 }, (_, i) => i).map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </MUISelect>
                    </div>
                    <div className="w-full self-end flex gap-2 overflow-auto p-1">
                      {inventory.dailyRetention.quantity > 0 &&
                        inventory.dailyRetention.time.map((_, index) => (
                          <TimePicker
                            ampm={false}
                            sx={{ width: 115, flexShrink: 0 }}
                            key={`timePickerDaily_${index}`}
                            timeSteps={{ minutes: 1 }}
                            value={dayjs(inventory.dailyRetention.time[index])}
                            onChange={(event) => {
                              const newDailyRetention = [...inventory.dailyRetention.time];
                              newDailyRetention[index] = event;
                              setInventory({
                                ...inventory,
                                dailyRetention: {
                                  ...inventory.dailyRetention,
                                  time: newDailyRetention,
                                },
                              });
                            }}
                          />
                        ))}
                    </div>
                  </div>
                  <div className="flex items-center gap-2 overflow-hidden">
                    <div className="flex items-center w-2/12 justify-between">
                      <Typography>Semanal</Typography>
                      <MUISelect
                        size="small"
                        value={inventory.weeklyRetention.quantity}
                        onChange={(event) => {
                          const newWeeklyRetention = [];
                          for (let index = 0; index < event.target.value; index++) {
                            newWeeklyRetention.push({
                              time: '00:00',
                              weekDay: 'monday',
                            });
                          }

                          setInventory({
                            ...inventory,
                            weeklyRetention: {
                              ...inventory.weeklyRetention,
                              time: newWeeklyRetention,
                              quantity: event.target.value,
                            },
                          });
                        }}
                      >
                        {Array.from({ length: 8 }, (_, i) => i).map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </MUISelect>
                    </div>
                    <div className="w-full self-end flex gap-2 overflow-auto p-1">
                      {inventory.weeklyRetention.quantity > 0 &&
                        inventory.weeklyRetention.time.map((_, index) => (
                          <div key={`weekly_${index}`} className="flex">
                            <TimePicker
                              ampm={false}
                              sx={{
                                width: 115,
                                flexShrink: 0,
                                '.MuiOutlinedInput-root': {
                                  borderRight: 'none',
                                  borderRadius: '4px 0 0 4px',
                                },
                              }}
                              key={`timePickerWeekly_${index}`}
                              timeSteps={{ minutes: 1 }}
                              value={dayjs(inventory.weeklyRetention.time[index].time)}
                              onChange={(event) => {
                                const newWeeklyRetention = [...inventory.weeklyRetention.time];
                                newWeeklyRetention[index].time = event;
                                setInventory({
                                  ...inventory,
                                  weeklyRetention: {
                                    ...inventory.weeklyRetention,
                                    time: newWeeklyRetention,
                                  },
                                });
                              }}
                            />
                            <MUISelect
                              key={`selectWeekly_${index}`}
                              size="small"
                              sx={{
                                borderRadius: '0 4px 4px 0',
                              }}
                              onChange={(event) => {
                                const newWeeklyRetention = [...inventory.weeklyRetention.time];
                                newWeeklyRetention[index].weekDay = event.target.value;
                                setInventory({
                                  ...inventory,
                                  weeklyRetention: {
                                    ...inventory.weeklyRetention,
                                    time: newWeeklyRetention,
                                  },
                                });
                              }}
                              value={inventory.weeklyRetention.time[index].weekDay}
                            >
                              <MenuItem value="monday">Seg</MenuItem>
                              <MenuItem value="tuesday">Ter</MenuItem>
                              <MenuItem value="wednesday">Qua</MenuItem>
                              <MenuItem value="thursday">Qui</MenuItem>
                              <MenuItem value="friday">Sex</MenuItem>
                              <MenuItem value="saturday">Sab</MenuItem>
                              <MenuItem value="sunday">Dom</MenuItem>
                            </MUISelect>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="flex items-center gap-2 overflow-hidden">
                    <div className="flex items-center w-2/12 justify-between">
                      <Typography>Quinzenal</Typography>
                      <MUISelect
                        size="small"
                        value={inventory.biWeeklyRetention.quantity}
                        onChange={(event) => {
                          const newBiWeeklyRetention = [];
                          for (let index = 0; index < event.target.value; index++) {
                            newBiWeeklyRetention.push({ date: 0, time: dayjs().format('HH:mm') });
                          }
                          setInventory({
                            ...inventory,
                            biWeeklyRetention: {
                              ...inventory.biWeeklyRetention,
                              quantity: event.target.value,
                              time: newBiWeeklyRetention,
                            },
                          });
                        }}
                      >
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                      </MUISelect>
                    </div>
                    <div className="w-full self-end flex gap-2 overflow-auto p-1">
                      {inventory.biWeeklyRetention.quantity > 0 &&
                        inventory.biWeeklyRetention.time.map((_, index) => (
                          <div key={`biWeekly_${index}`}>
                            <MUISelect
                              size="small"
                              value={inventory.biWeeklyRetention.time[index].date}
                              onChange={(day) => {
                                const newBiWeeklyRetention = [...inventory.biWeeklyRetention.time];
                                newBiWeeklyRetention[index].date = day.target.value;
                                setInventory({
                                  ...inventory,
                                  biWeeklyRetention: {
                                    ...inventory.biWeeklyRetention,
                                    time: newBiWeeklyRetention,
                                  },
                                });
                              }}
                              sx={{
                                borderRadius: '4px 0 0 4px',
                              }}
                            >
                              {Array.from({ length: 32 }, (_, i) => i).map((day) => (
                                <MenuItem key={day} value={day}>
                                  {day}
                                </MenuItem>
                              ))}
                            </MUISelect>
                            <TimePicker
                              ampm={false}
                              timeSteps={{ minutes: 1 }}
                              value={dayjs(inventory.biWeeklyRetention.time[index].time)}
                              onChange={(event) => {
                                const newBiWeeklyRetention = [...inventory.biWeeklyRetention.time];
                                newBiWeeklyRetention[index].time = event;
                                setInventory({
                                  ...inventory,
                                  biWeeklyRetention: {
                                    ...inventory.biWeeklyRetention,
                                    time: newBiWeeklyRetention,
                                  },
                                });
                              }}
                              sx={{
                                width: 113,
                                '.MuiOutlinedInput-root': {
                                  borderRight: 'none',
                                  borderRadius: '0 4px 4px 0',
                                },
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="flex items-center gap-2 overflow-hidden">
                    <div className="flex items-center w-2/12 justify-between">
                      <Typography>Mensal</Typography>
                      <MUISelect
                        size="small"
                        value={inventory.monthlyRetention.quantity}
                        onChange={(event) => {
                          const newMonthlyRetention = [];
                          for (let index = 0; index < event.target.value; index++) {
                            newMonthlyRetention.push({ date: dayjs().format('DD/MM/YY'), time: dayjs().format('HH:mm') });
                          }
                          setInventory({
                            ...inventory,
                            monthlyRetention: {
                              ...inventory.monthlyRetention,
                              quantity: event.target.value,
                              time: newMonthlyRetention,
                            },
                          });
                        }}
                      >
                        {Array.from({ length: 13 }, (_, i) => i).map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </MUISelect>
                    </div>
                    <div className="w-full flex gap-2 overflow-auto p-1">
                      {inventory.monthlyRetention.quantity > 0 &&
                        inventory.monthlyRetention.time.map((_, index) => (
                          <div key={`monthly_${index}`} className="flex">
                            <DatePicker
                              format="DD/MM"
                              views={['month', 'day']}
                              openTo="month"
                              value={dayjs(inventory.monthlyRetention.time[index].date)}
                              onChange={(event) => {
                                const newMonthlyRetention = [...inventory.monthlyRetention.time];
                                newMonthlyRetention[index].date = event;
                                setInventory({
                                  ...inventory,
                                  monthlyRetention: {
                                    ...inventory.monthlyRetention,
                                    time: newMonthlyRetention,
                                  },
                                });
                              }}
                              sx={{
                                width: 120,
                                '.MuiOutlinedInput-root': {
                                  borderRadius: '4px 0 0 4px',
                                },
                              }}
                            />
                            <TimePicker
                              ampm={false}
                              timeSteps={{ minutes: 1 }}
                              value={dayjs(inventory.monthlyRetention.time[index].time)}
                              onChange={(event) => {
                                const newMonthlyRetention = [...inventory.monthlyRetention.time];
                                newMonthlyRetention[index].time = event;
                                setInventory({
                                  ...inventory,
                                  monthlyRetention: {
                                    ...inventory.monthlyRetention,
                                    time: newMonthlyRetention,
                                  },
                                });
                              }}
                              sx={{
                                width: 120,
                                '.MuiOutlinedInput-root': {
                                  borderRight: 'none',
                                  borderRadius: '0 4px 4px 0',
                                },
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="flex items-center gap-2 overflow-hidden">
                    <div className="flex items-center w-2/12 justify-between">
                      <Typography>Anual</Typography>
                      <MUISelect
                        size="small"
                        value={inventory.yearlyRetention.quantity}
                        onChange={(event) => {
                          const newYearlyRetention = [];
                          for (let index = 0; index < event.target.value; index++) {
                            newYearlyRetention.push({ date: dayjs().format('DD/MM/YY'), time: dayjs().format('HH:mm') });
                          }

                          setInventory({
                            ...inventory,
                            yearlyRetention: {
                              ...inventory.yearlyRetention,
                              quantity: event.target.value,
                              time: newYearlyRetention,
                            },
                          });
                        }}
                      >
                        {Array.from({ length: 13 }, (_, i) => i).map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </MUISelect>
                    </div>
                    <div className="w-full self-end flex gap-2 overflow-auto p-1">
                      {inventory.yearlyRetention.quantity > 0 &&
                        inventory.yearlyRetention.time.map((_, index) => (
                          <div className="flex">
                            <DatePicker
                              format="DD/MM"
                              views={['month', 'day']}
                              openTo="month"
                              value={dayjs(inventory.yearlyRetention.time[index].date)}
                              onChange={(event) => {
                                const newYearlyRetention = [...inventory.yearlyRetention.time];
                                newYearlyRetention[index].date = event;
                                setInventory({
                                  ...inventory,
                                  yearlyRetention: {
                                    ...inventory.yearlyRetention,
                                    time: newYearlyRetention,
                                  },
                                });
                              }}
                              sx={{
                                width: 120,
                                '.MuiOutlinedInput-root': {
                                  borderRadius: '4px 0 0 4px',
                                },
                              }}
                            />
                            <TimePicker
                              ampm={false}
                              timeSteps={{ minutes: 1 }}
                              value={dayjs(inventory.yearlyRetention.time[index].time)}
                              onChange={(event) => {
                                const newYearlyRetention = [...inventory.yearlyRetention.time];
                                newYearlyRetention[index].time = event;
                                setInventory({
                                  ...inventory,
                                  yearlyRetention: {
                                    ...inventory.yearlyRetention,
                                    time: newYearlyRetention,
                                  },
                                });
                              }}
                              sx={{
                                width: 120,
                                '.MuiOutlinedInput-root': {
                                  borderRight: 'none',
                                  borderRadius: '0 4px 4px 0',
                                },
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
            <div className="flex justify-around pt-6">
              <Button
                color="error"
                variant="outlined"
                size="small"
                onClick={() => {
                  navigate('/inventory');
                }}
              >
                Voltar
              </Button>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                color="primary"
                // disabled={!isComplete}
                className="p-2 shadow-md shadow-blue-200 bg-blue-900 text-white tracking-tight font-medium rounded-lg hover:bg-blue-950 disabled:bg-gray-500"
                type="submit"
              >
                {inventory.id ? 'Atualizar' : 'Salvar'}
              </LoadingButton>
            </div>
          </div>
        </form>
      </Container>
    </Layout>
  );
};
export default CreateMachineInventory;
