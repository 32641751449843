import React, { useEffect, useState } from 'react'
import { Document, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import Grafico from './Grafic.jsx'
import { DocumentIcon } from '@heroicons/react/24/outline/index.js'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import { LanguageIcon } from '@heroicons/react/24/solid'
import translate from '../translate.js'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
        pageBreakAfter: 'always'
    },
    section: {
        marginVertical: 10,
        paddingHorizontal: 10,
        flexGrow: 1,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10
    },
    subheading: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 5
    },
    paragraph: {
        fontSize: 12,
        marginBottom: 5
    }
})

const Relatorio = ({ cancel, resultRelatorio, dataInicial, dataFinal }) => {
    const [totalCriticosPdf, setTotalCriticosPdf] = useState(0)
    const [totalAvisosPdf, setTotalAvisosPdf] = useState(0)
    const [totaisPorCliente, setTotaisPorCliente] = useState({})
    const [errorsMessages, setErrorsMessages] = useState([])
    const [translateLoading, setTranslateLoading] = useState(false)

    const translateError = (text, maquina, index, type) => {
        setTranslateLoading(true)
        translate(text)
            .then((response) => {
                const translatedText = response.data.data.translations.translatedText
                setErrorsMessages((prevState) => {
                    const newState = { ...prevState }
                    newState[maquina][type][index].error = translatedText
                    return newState
                })
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setTranslateLoading(false)
            })
    }

    useEffect(() => {
        if (resultRelatorio && Object.keys(resultRelatorio).length > 0) {
            let totalCriticos = 0
            let totalAvisos = 0

            // Itera sobre cada cliente
            Object.entries(resultRelatorio).forEach(([clienteName, maquinas]) => {
                let totalCriticosCliente = 0
                let totalAvisosCliente = 0

                // Calcula totais de críticos e avisos para o cliente
                Object.entries(maquinas).forEach(([, tipoErr]) => {
                    totalCriticosCliente += tipoErr.Critico.length
                    totalAvisosCliente += tipoErr.Avisos.length
                })
                setTotaisPorCliente((prevState) => ({
                    ...prevState,
                    [clienteName]: { totalCriticos: totalCriticosCliente, totalAvisos: totalAvisosCliente }
                }))

                // Acumula os totais globais para exibir no PDF
                totalCriticos += totalCriticosCliente
                totalAvisos += totalAvisosCliente
            })

            // Define os totais globais para exibição no PDF, se necessário
            setTotalCriticosPdf(totalCriticos)
            setTotalAvisosPdf(totalAvisos)
        }
    }, [errorsMessages, resultRelatorio])

    const [showPDF, setShowPDF] = useState(false)

    const gerarPdf = () => {
        setShowPDF(true)
    }

    if (!resultRelatorio) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center justify-center">
                        <svg
                            className="animate-spin size-8 text-blue-900"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            />
                            <path
                                className="opacity-75"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <p className="mt-4 text-blue-900">Carregando...</p>
                </div>
            </div>
        )
    }

    const dataInicialFormatada = new Date(dataInicial).toLocaleDateString()
    const dataFinalFormatada = new Date(dataFinal).toLocaleDateString()

    const dadosParaGrafico = Object.entries(resultRelatorio).map(([clienteName, maquinas]) => {
        return {
            cliente: clienteName,
            maquinas: Object.entries(maquinas).map(([nomeMaqui, erros]) => {
                const totalCriticos = erros.Critico && Array.isArray(erros.Critico) ? erros.Critico.length : 0
                const totalAvisos = erros.Avisos && Array.isArray(erros.Avisos) ? erros.Avisos.length : 0

                return {
                    nome: nomeMaqui,
                    quantidadeErros: totalCriticos + totalAvisos
                }
            })
        }
    })

    return (
        <div className="w-full ">
            <div className="font-semibold ">
                <div className="flex flex-col p-8 ">
                    <div className="flex items-center justify-between">
                        <button
                            className="ml-16 shadow-md shadow-rose-200 p-2 flex bg-rose-600 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-rose-700"
                            type="submit"
                            onClick={() => {
                                cancel()
                            }}>
                            Voltar
                        </button>

                        <button
                            className=" mr-16 p-2  shadow-md shadow-blue-200 flex bg-blue-900 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                            type="submit"
                            onClick={() => {
                                gerarPdf()
                            }}>
                            PDF &nbsp; <DocumentIcon className="size-6" />
                        </button>
                    </div>
                </div>
            </div>

            {!showPDF ? (
                <div id="conteudo-pdf">
                    <div className="h-[calc(100vh-130px)] block overflow-auto">
                        {Object.entries(resultRelatorio).map(([clienteName, maquinas], index) => {
                            let totalCriticos = 0
                            let totalAvisos = 0

                            // Calcula totais de críticos e avisos para o cliente
                            Object.values(maquinas).forEach((maquina) => {
                                if (Array.isArray(maquina.Critico)) {
                                    totalCriticos += maquina.Critico.length
                                }
                                if (Array.isArray(maquina.Avisos)) {
                                    totalAvisos += maquina.Avisos.length
                                }
                            })

                            return (
                                <div
                                    key={index}
                                    className="border overflow-auto">
                                    <hr />
                                    <div className="flex justify-center mt-8 text-3xl font-semibold">{clienteName}</div>
                                    {/*{totalCriticos !== 0 && totalAvisos !== 0 ? (*/}
                                    <div className="font-semibold mt-12 pl-8">
                                        {totalAvisosPdf !== 0 && totalCriticosPdf !== 0 ? (
                                            <p>Em {dataInicialFormatada}</p>
                                        ) : (
                                            <p>
                                                Período de {dataInicialFormatada} a {dataFinalFormatada}
                                            </p>
                                        )}
                                        <p className="mt-1">Total Críticos: {totalCriticos}</p>
                                        <p className="mt-1">Total Avisos: {totalAvisos}</p>
                                        <p className="mt-1">Total: {totalCriticos + totalAvisos}</p>
                                    </div>
                                    {/*) : (*/}
                                    {/*<div className="font-semibold mt-12 text ml-16 text-start text-lg">*/}
                                    {/*    Não houve erros no período de {dataInicialFormatada} a {dataFinalFormatada}*/}
                                    {/*</div>*/}
                                    {/*)}*/}

                                    <div className="flex items-center justify-center mt-12">
                                        <div className="flex items-center">
                                            <Grafico dados={dadosParaGrafico[index]} />
                                        </div>
                                    </div>
                                    <div>
                                        {Object.keys(maquinas).map((nomeMaqui, index) => {
                                            const maquina = maquinas[nomeMaqui]
                                            let numCriticos = 0
                                            let numAvisos = 0

                                            if (!errorsMessages[nomeMaqui]) {
                                                errorsMessages[nomeMaqui] = []
                                                errorsMessages[nomeMaqui]['Critico'] = [...maquina.Critico]
                                                errorsMessages[nomeMaqui]['Avisos'] = [...maquina.Avisos]
                                            }

                                            Object.entries(maquina).forEach(([tipoErro, value]) => {
                                                if (tipoErro === 'Critico' && Array.isArray(value)) {
                                                    numCriticos = value.length
                                                } else if (tipoErro === 'Avisos' && Array.isArray(value)) {
                                                    numAvisos = value.length
                                                }
                                            })

                                            return (
                                                <div
                                                    key={index}
                                                    className="overflow-auto mb-2">
                                                    <Accordion
                                                        allowZeroExpanded
                                                        className="bg-white">
                                                        <AccordionItem>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    <span className="font-semibold text-xl">{nomeMaqui}</span>
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <div className="font-semibold">
                                                                    {numCriticos !== 0 ? (
                                                                        <Accordion
                                                                            allowZeroExpanded
                                                                            className="bg-neutral-200">
                                                                            <AccordionItem key={`criticos-${index}`}>
                                                                                <AccordionItemHeading>
                                                                                    <AccordionItemButton>
                                                                                        <span className="font-semibold">Críticos: {numCriticos}</span>
                                                                                    </AccordionItemButton>
                                                                                </AccordionItemHeading>
                                                                                <AccordionItemPanel>
                                                                                    <div>
                                                                                        <ul>
                                                                                            {maquina['Critico'].map((erro, i) => (
                                                                                                <li key={i}>
                                                                                                    <span className="flex">
                                                                                                        <p className="w-6/12">{errorsMessages[nomeMaqui]['Critico'][i].error}</p>
                                                                                                        &nbsp;
                                                                                                        <span
                                                                                                            className="h-9 p-2 bg-green-700 text-white rounded-lg shadow-lg cursor-pointer hover:bg-green-800 duration-300"
                                                                                                            onClick={() => translateError(errorsMessages[nomeMaqui]['Critico'][i].error, nomeMaqui, i, 'Critico')}>
                                                                                                            {translateLoading ? (
                                                                                                                <svg
                                                                                                                    className="animate-spin size-5 text-white"
                                                                                                                    fill="none"
                                                                                                                    viewBox="0 0 24 24"
                                                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <circle
                                                                                                                        className="opacity-25"
                                                                                                                        cx="12"
                                                                                                                        cy="12"
                                                                                                                        r="10"
                                                                                                                        stroke="currentColor"
                                                                                                                        strokeWidth="4"
                                                                                                                    />
                                                                                                                    <path
                                                                                                                        className="opacity-75"
                                                                                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                                                        fill="currentColor"
                                                                                                                    />
                                                                                                                </svg>
                                                                                                            ) : (
                                                                                                                <LanguageIcon
                                                                                                                    className="size-5"
                                                                                                                    title="Traduzir"
                                                                                                                />
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <p className="flex justify-end">{erro.raised_ts}</p>
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </AccordionItemPanel>
                                                                            </AccordionItem>
                                                                        </Accordion>
                                                                    ) : null}
                                                                    {numAvisos !== 0 ? (
                                                                        <Accordion
                                                                            allowZeroExpanded
                                                                            className="bg-neutral-200">
                                                                            <AccordionItem key={`avisos-${index}`}>
                                                                                <AccordionItemHeading>
                                                                                    <AccordionItemButton>
                                                                                        <span className="font-semibold">Avisos: {numAvisos}</span>
                                                                                    </AccordionItemButton>
                                                                                </AccordionItemHeading>
                                                                                <AccordionItemPanel>
                                                                                    <div>
                                                                                        <ul>
                                                                                            {maquina['Avisos'].map((erro, i) => (
                                                                                                <li key={i}>
                                                                                                    <span className="flex">
                                                                                                        <p className="w-6/12">{errorsMessages[nomeMaqui]['Avisos'][i].error}</p>
                                                                                                        &nbsp;
                                                                                                        <span
                                                                                                            className="h-9 p-2 bg-green-700 text-white rounded-lg shadow-lg cursor-pointer hover:bg-green-800 duration-300"
                                                                                                            onClick={() => translateError(errorsMessages[nomeMaqui]['Avisos'][i].error, nomeMaqui, i, 'Avisos')}>
                                                                                                            {translateLoading ? (
                                                                                                                <svg
                                                                                                                    className="animate-spin size-5 text-white"
                                                                                                                    fill="none"
                                                                                                                    viewBox="0 0 24 24"
                                                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <circle
                                                                                                                        className="opacity-25"
                                                                                                                        cx="12"
                                                                                                                        cy="12"
                                                                                                                        r="10"
                                                                                                                        stroke="currentColor"
                                                                                                                        strokeWidth="4"
                                                                                                                    />
                                                                                                                    <path
                                                                                                                        className="opacity-75"
                                                                                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                                                        fill="currentColor"
                                                                                                                    />
                                                                                                                </svg>
                                                                                                            ) : (
                                                                                                                <LanguageIcon
                                                                                                                    className="size-5"
                                                                                                                    title="Traduzir"
                                                                                                                />
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <p className="flex justify-end">{erro.raised_ts}</p>
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </AccordionItemPanel>
                                                                            </AccordionItem>
                                                                        </Accordion>
                                                                    ) : null}
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    </Accordion>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            ) : null}

            {showPDF ? (
                <PDFViewer style={{ width: '100%', height: '100vh' }}>
                    <Document>
                        <Page
                            size="A4"
                            style={styles.page}>
                            {Object.entries(resultRelatorio).map(([clienteName, maquinas], index) => (
                                <View
                                    key={index}
                                    style={styles.section}>
                                    {maquinas.length === 0 ? (
                                        <View>
                                            <Text style={styles.heading}>{clienteName}</Text>
                                            <Text style={styles.paragraph}>
                                                {dataInicialFormatada === dataFinalFormatada ? (
                                                    <>Não houve erros em {dataInicialFormatada}</>
                                                ) : (
                                                    <>
                                                        Não houve erros no período de {dataInicialFormatada} a {dataFinalFormatada}
                                                    </>
                                                )}
                                            </Text>
                                        </View>
                                    ) : (
                                        <View>
                                            <Text style={styles.heading}>{clienteName}</Text>
                                            <Text style={styles.paragraph}>
                                                {dataInicialFormatada === dataFinalFormatada ? (
                                                    <>Em {dataInicialFormatada}</>
                                                ) : (
                                                    <>
                                                        Período de {dataInicialFormatada} a {dataFinalFormatada}
                                                    </>
                                                )}
                                            </Text>

                                            {/* Exibe os totais do cliente */}
                                            {totaisPorCliente[clienteName] ? (
                                                <>
                                                    <Text style={styles.paragraph}>Total Críticos: {totaisPorCliente[clienteName].totalCriticos}</Text>
                                                    <Text style={styles.paragraph}>Total Avisos: {totaisPorCliente[clienteName].totalAvisos}</Text>
                                                    <Text style={styles.paragraph}>Total: {totaisPorCliente[clienteName].totalCriticos + totaisPorCliente[clienteName].totalAvisos}</Text>
                                                </>
                                            ) : null}

                                            {Object.keys(maquinas).map((nomeMaqui, index) => {
                                                const maquina = maquinas[nomeMaqui]
                                                let numCriticos = 0
                                                let numAvisos = 0

                                                Object.entries(maquina).forEach(([tipoErro, value]) => {
                                                    if (tipoErro === 'Critico' && Array.isArray(value)) {
                                                        numCriticos = value.length
                                                    } else if (tipoErro === 'Avisos' && Array.isArray(value)) {
                                                        numAvisos = value.length
                                                    }
                                                })

                                                return (
                                                    <View key={index}>
                                                        <View style={styles.section}>
                                                            <Text style={styles.subheading}>{nomeMaqui}</Text>
                                                            {numCriticos > 0 ? <Text style={styles.paragraph}>Crítico: {numCriticos}</Text> : null}
                                                            {numCriticos > 0 ? (
                                                                <View>
                                                                    <Text style={styles.subheading}>Crítico:</Text>
                                                                    <View style={styles.subheading}>
                                                                        {maquina['Critico'].map((erro, i) => (
                                                                            <View key={i}>
                                                                                <Text>{erro.error}</Text>
                                                                                <Text style={{ textAlign: 'right' }}>{erro.raised_ts}</Text>
                                                                            </View>
                                                                        ))}
                                                                    </View>
                                                                </View>
                                                            ) : null}
                                                            {numAvisos > 0 ? <Text style={styles.paragraph}>Avisos: {numAvisos}</Text> : null}
                                                            {numAvisos > 0 ? (
                                                                <View>
                                                                    <Text style={styles.subheading}>Aviso:</Text>
                                                                    <View style={styles.paragraph}>
                                                                        {maquina['Avisos'].map((erro, i) => (
                                                                            <View key={i}>
                                                                                <Text>{erro.error}</Text>
                                                                                <Text style={{ textAlign: 'right' }}>{erro.raised_ts}</Text>
                                                                            </View>
                                                                        ))}
                                                                    </View>
                                                                </View>
                                                            ) : null}
                                                        </View>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    )}
                                </View>
                            ))}
                        </Page>
                    </Document>
                </PDFViewer>
            ) : null}
        </div>
    )
}

export default Relatorio
