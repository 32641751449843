import React, { useState } from "react";
import Layout from "/src/components/Layouts/Layout.jsx";
import { toast } from "react-hot-toast";
import AxiosConfigs from "../configs.js";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline/index.js";
import { Button, IconButton, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Config = () => {
  const [loading, setLoading] = useState(false);
  const [seePass, setSeePass] = useState(false);
  const [credentials, setCredentials] = useState({
    password: "",
    password_confirmation: "",
  });
  const send = () => {
    setLoading(true);
    AxiosConfigs.post(`/change-password`, credentials)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data);
          setLoading(false);
          setSeePass(false);
          setCredentials({
            ...credentials,
            password: "",
            password_confirmation: "",
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <Layout active="config">
      <div className=" h-full overflow-y-auto">
        <div className="p-5">
          <form
            className="grid rounded-lg bg-white h-full p-3 shadow-lg gap-5"
            onSubmit={(e) => {
              e.preventDefault();
              if (credentials.password === credentials.password_confirmation) {
                send();
              } else {
                toast.error("Senhas diferentes");
              }
            }}
          >
            <p className="font-sans text-md font-medium tracking-tight">
              Alterar senha
            </p>

            <div className="relative w-2/5">
              <TextField
                id="password"
                label="Senha"
                className="w-1/2"
                required={true}
                type={seePass ? "text" : "password"}
                value={credentials.password}
                onChange={(e) =>
                  setCredentials({ ...credentials, password: e.target.value })
                }
              />
              <IconButton
                className="absolute top-[2px] end-10"
                type="button"
                onClick={() => {
                  setSeePass(!seePass);
                }}
              >
                {seePass ? (
                  <EyeSlashIcon className="text-blue-700 size-5" />
                ) : (
                  <EyeIcon className="text-blue-700 size-5" />
                )}
              </IconButton>
            </div>

            <div className="relative w-2/5">
              <TextField
                className="w-1/2"
                id="password"
                label="Confirme a senha"
                required={true}
                type={seePass ? "text" : "password"}
                value={credentials.password_confirmation}
                onChange={(e) =>
                  setCredentials({
                    ...credentials,
                    password_confirmation: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <LoadingButton
                variant="contained"
                loading={loading}
                // className="flex gap-5 mt-4 items-center bg-[#35208f] py-3 px-16 rounded-xl text-white hover:shadow-lg hover:shadow-blue-200 font-semibold hover:bg-blue-800 transition-all duration-200"
                type="submit"
              >
                Alterar
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};
export default Config;
