import loading_blue from '../../assets/loading_blue.svg'

const LoadingBlue = () => {
    return (
        <div>
            <img
                alt="Loading"
                src={loading_blue}
            />
        </div>
    )
}

export default LoadingBlue
