import { Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import AxiosConfigs from '../../configs';
import toast from 'react-hot-toast';

const ServiceCreation = ({ setServiceAdd, service }) => {
  const [serviceData, setServiceData] = useState({
    code: '',
    name: '',
    description: '',
  });

  const createService = () => {
    AxiosConfigs.post('/services', serviceData)
      .then((response) => {
        if (response.status === 201) {
          toast.success('Serviço criado com sucesso!');
          setServiceData({
            code: '',
            name: '',
            description: '',
          });
          setServiceAdd(false);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu um erro ao salvar o serviço. Tente novamente.');
        console.log(error);
      });
  };
  const updateService = () => {
    AxiosConfigs.post(`/services/${service.id}`, serviceData)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Serviço atualizado com sucesso!');
          setServiceData({
            code: '',
            name: '',
            description: '',
          });
          setServiceAdd(false);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu um erro ao salvar o serviço. Tente novamente.');
        console.log(error);
      });
  };

  useEffect(() => {
    setServiceData(service);
  }, [service]);

  return (
    <form
      className="bg-white p-5 shadow rounded-lg grid gap-5"
      onSubmit={(e) => {
        e.preventDefault();
        serviceData.id ? updateService() : createService();
      }}
    >
      <Box className="flex gap-3">
        <TextField
          label="Código"
          variant="outlined"
          className="w-2/12"
          value={serviceData.code}
          onInput={(e) => {
            setServiceData({ ...serviceData, code: e.target.value });
          }}
        />
        <TextField
          label="Nome"
          variant="outlined"
          className="w-10/12"
          value={serviceData.name}
          onInput={(e) => {
            setServiceData({ ...serviceData, name: e.target.value });
          }}
        />
      </Box>
      <Box>
        <TextField
          label="Descrição"
          variant="outlined"
          className="w-full"
          multiline={true}
          rows={3}
          value={serviceData.description}
          onInput={(e) => {
            setServiceData({ ...serviceData, description: e.target.value });
          }}
        />
      </Box>
      <div className="my-auto ml-auto">
        <Button variant="contained" color="primary" type="submit" size="small">
          Salvar
        </Button>
      </div>
    </form>
  );
};

export default ServiceCreation;
