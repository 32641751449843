import { Link } from "react-router-dom";
import { KeyIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import styles from "./Sidebar.module.css";
import {
  ArrowLeftStartOnRectangleIcon,
  UserPlusIcon,
  TableCellsIcon,
  ChartPieIcon,
  PlusCircleIcon,
  Cog8ToothIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline/index.js";
import { useCookies } from "react-cookie";
import AxiosConfigs from "../../configs.js";
import PermissoesContext from "../../context/PermisoesContext.jsx";
import { useContext } from "react";
import { Tooltip } from "@mui/material";

const Sidebar = ({ active }) => {
  const { permissoes } = useContext(PermissoesContext);
  localStorage.setItem("localPermissions", JSON.stringify(permissoes));

  const [cookies] = useCookies("is_admin");
  const isAdmin = cookies.is_admin;
  const permissoesTenantArray = Array.isArray(permissoes.tenants)
    ? permissoes.tenants
    : [];
  const permissoesClientArray = Array.isArray(permissoes.clients)
    ? permissoes.clients
    : [];
  // Verificação de permissão para exibir os icones no sideBar - se ouver pelo menos um view true exibe icone
  const inventoryPermission = permissoesTenantArray.some(
    (permissao) => permissao.permissions.inventory?.view === true
  );
  const userPermission = permissoesTenantArray.some(
    (permissao) => permissao.permissions.users?.view === true
  );
  // const configPermission = permissoesTenantArray.some((permissao) => permissao.permissions.config?.view === true)
  const evidencePermission = permissoesTenantArray.some(
    (permissao) => permissao.permissions.evidences?.view === true
  );
  const reportsPermission = permissoesTenantArray.some(
    (permissao) => permissao.permissions.reports?.view === true
  );
  const tablePermission = permissoesTenantArray.some(
    (permissao) => permissao.permissions.table?.view === true
  );
  const vaultPermission =
    permissoesTenantArray.some(
      (tenant) => tenant.permissions.vault?.view === true
    ) ||
    permissoesClientArray.some((client) => client.permissions?.view === true);
  // const groupPermission = permissoesArray.some((permissao) => permissao.permissions.groups?.view === true)
  const [, , removeCookies] = useCookies(["user"]);
  const menu = [
    // Adiciona item de tabela se a permissão for verdadeira
    ...(isAdmin || tablePermission
      ? [
          {
            name: "Painel Acronis",
            icon: TableCellsIcon,
            path: "/",
            active: active === "table",
            class: "",
          },
        ]
      : []),

    // Adiciona item de cofre se a permissão for verdadeira
    ...(isAdmin || vaultPermission
      ? [
          {
            name: "Cofre",
            icon: KeyIcon,
            path: "/vault",
            active: active === "clients",
            class: "",
          },
        ]
      : []),

    ...(isAdmin || userPermission
      ? [
          {
            name: "Cadastros",
            icon: PlusCircleIcon,
            path: "/cadastros",
            active: active === "cadastros",
            class: "",
          },
        ]
      : []),

    // Adiciona item de usuario se a permissão for verdadeira
    ...(isAdmin || userPermission
      ? [
          {
            name: "Cadastrar Usuario",
            icon: UserPlusIcon,
            path: "/users",
            active: active === "users",
            class: "",
          },
        ]
      : []),

    // Adiciona item de relatorio se a permissão for verdadeira
    ...(isAdmin || reportsPermission
      ? [
          {
            name: "Relatórios",
            icon: ChartPieIcon,
            path: "/reports",
            active: active === "reports",
            class: "",
          },
        ]
      : []),

    //===========================================================================================================================
    // Adiciona item de groupo se a permissão for verdadeira
    // ...(groupPermission
    //     ? [
    //           {
    //               name: 'Grupos',
    //               icon: SquaresPlusIcon,
    //               path: '/groups',
    //               active: active === 'groups',
    //               class: ''
    //           }
    //       ]
    //     : []),
    //===========================================================================================================================

    // Adiciona item de evidencia se a permissão for verdadeira
    ...(isAdmin || evidencePermission
      ? [
          {
            name: "Evidencias",
            icon: PaperClipIcon,
            path: "/evidences",
            active: active === "evidences",
            class: "",
          },
        ]
      : []),

    // Adiciona item de Inventário se a permissão for verdadeira
    ...(isAdmin || inventoryPermission
      ? [
          {
            name: "Inventário",
            icon: RectangleGroupIcon,
            path: "/inventory",
            active: active === "inventories",
            class: "",
          },
        ]
      : []),
    {
      name: "Configurações",
      icon: Cog8ToothIcon,
      path: "/config",
      active: active === "config",
      class: "",
    },
    //   ]
    // : [])
  ];

  return (
    <div className="h-full border-r ">
      <ul className="grid grid-rows-12 p-4 size-full">
        {menu.map((item, index) => (
          <Tooltip title={item.name} key={index} placement="right" arrow={true}>
            <Link
              key={index}
              className={`${item.class} size-full`}
              to={item.path}
            >
              <li
                key={index}
                className={`${item.active ? styles.active : ""} h-3/4 shadow shadow-neutral-400 menu-item hover:bg-[#35208f] hover:text-white duration-300 ${styles.menuItem}`}
                // title={item.name}
              >
                <item.icon className="size-6" />
                {/*<p>{item.name}</p>*/}
              </li>
            </Link>
          </Tooltip>
        ))}
        <div className="row-start-12 size-full cursor-pointer">
          <li
            className={`h-3/4 shadow-md shadow-neutral-400 menu-item hover:bg-[#35208f] hover:text-white duration-300 ${styles.menuItem}`}
            onClick={() => {
              AxiosConfigs.post("/logout").then((response) => {
                if (response.status === 204) {
                  removeCookies("user");
                  removeCookies("is_admin");
                  // localStorage.removeItem('permissoes')
                  localStorage.clear();
                  window.location.replace("/login");
                }
              });
            }}
          >
            <ArrowLeftStartOnRectangleIcon className="size-6" />
          </li>
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
