import { useEffect, useState } from 'react';
import AxiosConfigs from '../../configs';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';

const ClientList = ({ selectedClient, setClientAdd }) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    AxiosConfigs.get('/new_clients').then((data) => {
      setClients(data.data);
    });
  }, []);
  return (
    <Paper>
      <TableContainer
        sx={{
          maxHeight: 700,
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>ID</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Nome</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Razão Social</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>CNPJ</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Data de Cadastro</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client, index) => (
              <TableRow
                key={index}
                className="cursor-pointer hover:bg-blue-50"
                onClick={() => {
                  selectedClient(client);
                  setClientAdd(true);
                }}
              >
                <TableCell className="w-1/12 text-nowrap text-ellipsis overflow-hidden">{client.id}</TableCell>
                <TableCell className="w-3/12 max-w-xs text-nowrap text-ellipsis overflow-hidden">{client.fantasy_name}</TableCell>
                <TableCell className="w-4/12 max-w-xs text-nowrap text-ellipsis overflow-hidden">{client.razao_social}</TableCell>
                <TableCell className="w-2/12 text-nowrap text-ellipsis overflow-hidden">{client.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}</TableCell>
                <TableCell className="w-2/12 text-nowrap text-ellipsis overflow-hidden">{dayjs(client.created_at).format('DD/MM/YY HH:mm:ss')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ClientList;
