import React, { useState } from "react";
import LoadingBlue from "../components/Layouts/loadingblue.jsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import AxiosConfigs from "../configs.js";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline/index.js";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showLoad, setShowLoad] = useState(false);

  const [searchParams] = useSearchParams()(searchParams);
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const [credentials, setCredentials] = useState({
    token: token,
    email: email,
    password: "",
    password_confirmation: "",
  });

  const send = () => {
    setLoading(true);
    AxiosConfigs.post(`/reset-password`, credentials)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data);
          setLoading(false);
          navigate("/login");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const [seePass, setSeePass] = useState(false);

  return (
    <div className="flex items-center justify-center bg-neutral-50 min-h-screen">
      <div className="flex items-center flex-col justify-start bg-white border border-blue-200 h-auto min-h-[60vh] w-11/12 sm:w-3/4 md:w-2/3 lg:w-2/4 rounded-2xl shadow-lg shadow-blue-200 p-6">
        {showLoad ? (
          <div className="h-16 w-16 mt-4">
            <LoadingBlue />
          </div>
        ) : (
          <div>
            <h1 className="mt-12 font-semibold text-2xl">Recuperar Senha</h1>
          </div>
        )}

        <form
          className="flex flex-col w-full sm:w-5/6 md:w-4/6 lg:w-3/6"
          onSubmit={(e) => {
            e.preventDefault();
            if (credentials.password === credentials.password_confirmation) {
              send();
            } else {
              toast.error("Senhas diferentes");
            }
          }}
        >
          <div className="flex flex-col mt-8 ">
            <div className=" grid grid-cols-12">
              <label
                className="font-semibold mb-2 col-span-8 "
                htmlFor="password"
              >
                Nova senha:
              </label>
              <div className="size-5 col-start-12 ">
                <div className="text-center mt-1">
                  {seePass ? (
                    <EyeSlashIcon
                      className="text-blue-700"
                      onClick={() => {
                        setSeePass(!seePass);
                      }}
                    />
                  ) : (
                    <EyeIcon
                      className="text-blue-700"
                      onClick={() => {
                        setSeePass(!seePass);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <input
              className="pl-4 h-12 border border-b-blue-900 bg-neutral-50 rounded-md focus:outline-none focus:border focus:border-blue-700"
              id="password"
              required={true}
              type={seePass ? "text" : "password"}
              value={credentials.password}
              onChange={(e) =>
                setCredentials({ ...credentials, password: e.target.value })
              }
            />
          </div>

          <div className="flex flex-col mt-8">
            <label
              className="font-semibold mb-2"
              htmlFor="password_confirmation"
            >
              Confirme a senha:
            </label>
            <input
              className="pl-4 h-12 border border-b-blue-900 bg-neutral-50 rounded-md focus:outline-none focus:border focus:border-blue-700"
              id="password_confirmation"
              required={true}
              type={seePass ? "text" : "password"}
              value={credentials.password_confirmation}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  password_confirmation: e.target.value,
                })
              }
            />
          </div>

          <div className="flex items-center justify-center mt-8">
            <button
              className="flex gap-5 items-center bg-[#35208f] py-3 px-16 rounded-xl text-white hover:shadow-lg hover:shadow-blue-200 font-semibold hover:bg-blue-800 transition-all duration-200"
              type="submit"
            >
              Enviar
              <div className={loading ? "" : "hidden"}>
                <svg
                  className="animate-spin size-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </button>
          </div>

          <div className="mt-8 flex items-center justify-center text-blue-900">
            <a
              className="cursor-pointer font-semibold hover:underline"
              onClick={() => {
                setShowLoad(!showLoad);
                navigate("/");
              }}
            >
              Voltar
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ResetPassword;
