import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import AxiosConfigs from '../../configs';

const ServiceList = ({ selectedService, setServiceAdd }) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    AxiosConfigs.get('/services').then((services) => setServices(services.data));
  }, []);

  return (
    <Paper>
      <TableContainer
        sx={{
          maxHeight: 700,
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>Código</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Nome</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Descrição</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow
                key={service.id}
                className="cursor-pointer hover:bg-blue-50"
                onClick={() => {
                  selectedService(service);
                  setServiceAdd(true);
                }}
              >
                <TableCell>{service.code}</TableCell>
                <TableCell>{service.name}</TableCell>
                <TableCell>{service.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ServiceList;
