import React, { Fragment } from 'react'
import Layout from '../components/Layouts/Layout.jsx'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline/index.js'
import { useState } from 'react'
import { useEffect } from 'react'
import AxiosConfigs from '../configs.js'
import DatePicker from 'react-datepicker'
import { pt } from 'date-fns/locale/pt'
import ReactPaginate from 'react-paginate'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { toast } from 'react-hot-toast'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid/index.js'
import { useRef } from 'react'
import { useCookies } from 'react-cookie'

const Evidences = () => {
  const permissoes = JSON.parse(localStorage.getItem('localPermissions'))

  const [cookies] = useCookies('is_admin')
  const isAdmin   = cookies.is_admin

  const [search, setSearch]                                       = useState('')
  const [clientesSelecionados, setClientesSelecionados]           = useState([])
  const [nomesClientesSelecionados, setNomesClientesSelecionados] = useState('')
  const [mostraMaquinaEditar, setMostraMaquinaEditar]             = useState(false)
  const [btnEdit, setBtnEdit]                                     = useState(false)
  const [togHistEvid, setTogHistEvid]                             = useState(false)
  const [cliente, setCliente]                                     = useState([])
  const [togLoad, setTogLoad]                                     = useState(true)

  const clientes                        = cliente.filter((tenant) => Object.prototype.hasOwnProperty.call(tenant, 'name'))
  const [togTenantMaq, setTogTenantMaq] = useState(false)
  useEffect(() => {
    AxiosConfigs.get('/tenants').then((response) => {
      setCliente(response.data)
      setTogLoad(false)
    }).catch(function (error) {

    })
  }, [])

  //clienteSelecionado UTILIZADO PARA CONSULTAR PERMISSOES DE USUARIO
  const [clienteSelecionado, setClienteSelecionado] = useState()
  const handleCheckboxClick                         = (id, name) => {
    setClienteSelecionado(id)
    setBtnEdit(!btnEdit)
    if (clientesSelecionados.includes(id)) {
      // Remover cliente selecionado se já estiver presente
      setClientesSelecionados(clientesSelecionados.filter((clienteId) => clienteId !== id))
      setNomesClientesSelecionados(name)
    } else {
      // Adicionar cliente selecionado se não estiver presente
      setClientesSelecionados([id])
      setNomesClientesSelecionados(name)
    }
  }

  const filteredClients = search.length > 0 ? clientes.filter((cliente) => cliente.name.toLowerCase().includes(search.toLowerCase())) : clientes

  // Filtrar apenas os IDs que são do tipo string
  const selectedIds             = clientesSelecionados.filter((id) => typeof id === 'string')
  const selectedIdsSemColchetes = selectedIds.join(', ') // Convertendo o array em uma string sem os colchetes
  //----------------------------------------------------------------------------------------------------------------------

  // Ediçao de maquinas
  const [nomeDaMaquina, setNomeDaMaquina] = useState('')
  const [idDaMaquina, setIdDaMaquina]     = useState('')
  const [images, setImages]               = useState([])
  const [ImagesComents, setImagesComents] = useState([])
  const [comentario, setComentario]       = useState('')

  const [respMaquinas, setRespMaquinas] = useState([])
  const maquinasRelatorio               = () => {
    AxiosConfigs.get(`/reports/machines?tenants=${selectedIdsSemColchetes}`).then((response) => {
      setRespMaquinas(response.data)
      setTogTenantMaq(true)
    }).catch(function (error) {

    })
  }

  const handleClick        = (valorMaquina, chaveMaquina) => {
    setNomeDaMaquina(valorMaquina)
    setIdDaMaquina(chaveMaquina)
    setMostraMaquinaEditar(true)
  }
  const handleComentChange = (value) => {
    setComentario(value)
  }

  let dadosResource = {
    tenant:        selectedIdsSemColchetes,
    resource_id:   idDaMaquina,
    comment:       comentario,
    image_comment: ImagesComents,
  }

  const evidenciar = () => {
    if (comentario === '') {
      toast.error('Digite o comentario')
    } else {
      const formData = new FormData()
      // Adiciona cada campo de dadosResource ao formData
      for (let key in dadosResource) {
        if (Array.isArray(dadosResource[key])) {
          // Se for um array, percorra e adicione cada elemento individualmente
          dadosResource[key].forEach((item, index) => {
            if (item instanceof File) {
              // Se o item for um arquivo (imagem), adicione-o separadamente
              formData.append(`${key}[${index}][images]`, item)
            } else {
              // Se não for um arquivo, adicione como uma string normal
              formData.append(`${key}[${index}][${Object.keys(item)[0]}]`, item[Object.keys(item)[0]])
              formData.append(`${key}[${index}][comment]`, ImagesComents[index].comment) // Adiciona o comentário da imagem
            }
          })
        } else {
          // Se não for um array, adicione como uma string normal
          formData.append(key, dadosResource[key])
        }
      }
      const formDataObject = {}
      for (let [key, value] of formData.entries()) {
        formDataObject[key] = value
      }

      AxiosConfigs.post(`/restore`, formData).then((response) => {

        toast.success('Evidência criada')
        setTogHistEvid(false)
        window.location.replace('/evidences')
      }).catch((error) => {
        console.error('There was an error fetching the data!', error)
      })
    }
  }

  const handleComentarioChange = (event, index) => {
    const newImagesComents = [...ImagesComents]

    newImagesComents[index] = { ...newImagesComents[index], comment: event.target.value }
    setImagesComents(newImagesComents)
  }

  const fileInputRef = useRef(null)

  const addRowIp = () => {

    setImages([...images, ''])
    setImagesComents([...ImagesComents, { image: '', comment: '' }])
    setTimeout(() => {
      if (fileInputRef.current) {
        fileInputRef.current.click()
      }
    }, 0)
  }

  const removeRowIp = (indexToRemove) => {
    // Atualiza o estado das imagens
    setImages((prevImages) => prevImages.filter((_, index) => index !== indexToRemove))
    // Atualiza o estado dos comentários das imagens
    setImagesComents((prevComments) => prevComments.filter((_, index) => index !== indexToRemove))
  }

  const handleImageChange = (event, index) => {
    const files            = event.target.files
    const newImagesComents = [...ImagesComents]
    for (let i = 0; i < files.length; i++) {
      const file              = files[i]
      newImagesComents[index] = {
        images:  file,
        comment: newImagesComents[index] ? newImagesComents[index].comment : '',
      }
    }
    setImagesComents(newImagesComents)
  }
  //----------------------------------------------------------------------------------------------------------------------

  const [resultHist, setResultHist]         = useState([])
  const [IdMaq, setIdMaq]                   = useState('')
  const [numeroDaPagina, setNumeroDaPagina] = useState(0)

  useEffect(() => {
    if (IdMaq !== '' && numeroDaPagina !== 0) {
      historicoEvidencia()
    }
  }, [IdMaq, numeroDaPagina])

  const historicoEvidencia = () => {
    AxiosConfigs.get(`/restore/${IdMaq}`, {
      params: {
        page: numeroDaPagina,
      },
    }).then((response) => {
      setResultHist(response.data)
      numPaginas()
    }).catch((error) => {
      toast.error(error.message)
      console.error('Erro:', error)
    })
  }

  const [resultadoPaginas, setResultadoPaginas] = useState('')
  const { total_pages }                         = resultadoPaginas

  const [togAcordeon, setTogAcordeon]       = useState(false)
  const numPaginas                          = () => {
    AxiosConfigs.get(`/restore/pages/${IdMaq}`).then((response) => {
      setResultadoPaginas(response.data)
      setTogAcordeon(true)
    }).catch(function (error) {

    })
  }
  //--------------------------------------------------------------------------------------------------------
  const [dataInicial, setDataInicial]       = useState()
  const [dataFormatada, setDataFormatada]   = useState('')
  const [resultHistData, setResultHistData] = useState([])

  useEffect(() => {
    if (dataFormatada !== '') {
      AxiosConfigs.get(`/restore/data/${IdMaq}/${dataFormatada}`).then((response) => {
        setResultHistData(response.data)
      }).catch(function (error) {

      })
    }
  }, [IdMaq, dataFormatada])

  // Função para formatar a data no formato "dd-mm-yyyy"
  const formatarData = (data) => {
    if (!data) return '' // Retorna uma string vazia se a data for nula

    const dia          = data.getDate()
    const mes          = data.getMonth() + 1 // Adicionando 1, pois os meses começam de 0
    const ano          = data.getFullYear()
    // Formatar o dia e o mês para garantir que tenham dois dígitos
    const diaFormatado = dia < 10 ? '0' + dia : dia
    const mesFormatado = mes < 10 ? '0' + mes : mes
    return `${diaFormatado}-${mesFormatado}-${ano}`
  }

  useEffect(() => {
    if (dataInicial) {
      const dataFormatada = formatarData(dataInicial)
      setDataFormatada(dataFormatada)
    }
  }, [dataInicial])

  const [fullscreenImage, setFullscreenImage] = useState(null)

  const openFullscreen = (image) => {
    setFullscreenImage(image)
  }

  const closeFullscreen = () => {
    setFullscreenImage(null)
  }

  const [, setImagemDownload]                   = useState('')
  const [togTabEvid, setTogTabEvid]             = useState(false)
  const [btnBotton, setBtnBotton]               = useState(false)
  const [btnBottonDowload, setBtnBottonDowload] = useState(false)

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1 // `selected` é baseado em 0, então adicionamos 1
    setNumeroDaPagina(selectedPage)
    closeFullscreen() // Certifique-se de definir esta função se necessário
  }

  return (
    <Layout active="evidences">
      <div className="h-full p-6">
        <div className="p-5 ">
          <p className=" font-sans text-md font-medium tracking-tight">Evidências</p>
        </div>
        {togHistEvid === false ? (
          <div className="h-[calc(90%-40px)] grid grid-rows-12 ">
            <div className="row-span-10  size-full ">
              <div className="rounded-lg bg-white h-full ">
                {togTabEvid === false ? (
                  <div className="h-full overflow-auto border rounded-lg border-gray-300 shadow-md ">
                    {togLoad === false ? (
                      <table className="table-auto w-full">
                        <thead className="border-b-2 border-black h-10 -top-0 sticky bg-white ">
                        <tr className="px-2 text-start ">
                          <th />
                          <th className="text-start px-2 hover:cursor-pointer" />
                          <th className="text-start px-2">
                            {togTenantMaq === false ? (
                              <div className="flex items-center justify-end sticky top mr-6">
                                <input
                                  className="px-2 rounded-md font-thin border border-gray-500 focus:outline-none focus:border-2 focus:border-blue-700"
                                  name="search"
                                  placeholder="Pesquisar"
                                  type="text"
                                  value={search}
                                  onChange={(e) => setSearch(e.target.value)}
                                />
                              </div>
                            ) : (
                              <div className="flex items-center justify-end sticky top pr-16">
                                <div>
                                  <p className="font-semibold">{nomesClientesSelecionados}</p>
                                </div>
                              </div>
                            )}
                          </th>
                        </tr>
                        </thead>
                        {togTenantMaq === false ? (
                          <tbody className="text-md">
                          {filteredClients.length > 0
                            ? filteredClients.sort((a, b) => a.name.localeCompare(b.name)) // Ordenar os clientes em ordem alfabética
                              .map((cliente) => {
                                if (isAdmin) {
                                  return (
                                    <tr
                                      key={cliente.id}
                                      className="h-12 border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight hover:cursor-pointer"
                                      onClick={() => handleCheckboxClick(cliente.id, cliente.name)}>
                                      <th className="text-start px-2">
                                        <div className="flex items-center">
                                          <input
                                            checked={clientesSelecionados.includes(cliente.id)}
                                            className="size-4 mx-auto"
                                            type="checkbox"
                                            onChange={() => handleCheckboxClick(cliente.id, cliente.name)}
                                          />
                                        </div>
                                      </th>
                                      <td
                                        className="px-2"
                                        colSpan="2">
                                        <label className="hover:cursor-pointer">{cliente.name}</label>
                                      </td>
                                    </tr>
                                  )
                                } else {
                                  // EXIBE OU NAO CONFORME AS PERMISSOES DO CLIENTE
                                  const hasPermissionView   = permissoes.tenants.some((userpermissions) => userpermissions.tenant === cliente.id) &&
                                    permissoes.tenants.find((userpermissions) => userpermissions.tenant === cliente.id)?.permissions?.evidences?.view
                                  const hasPermissionCreate =
                                          permissoes.tenants.some((userpermissions) => userpermissions.tenant === cliente.id) &&
                                          permissoes.tenants.find((userpermissions) => userpermissions.tenant === cliente.id)?.permissions?.evidences?.create
                                  return hasPermissionView === true || hasPermissionCreate === true ? (
                                    <tr
                                      key={cliente.id}
                                      className="h-12 border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight hover:cursor-pointer"
                                      onClick={() => handleCheckboxClick(cliente.id, cliente.name)}>
                                      <th className="text-start px-2">
                                        <div className="flex items-center">
                                          <input
                                            checked={clientesSelecionados.includes(cliente.id)}
                                            className="size-4 mx-auto"
                                            type="checkbox"
                                            onChange={() => handleCheckboxClick(cliente.id, cliente.name)}
                                          />
                                        </div>
                                      </th>
                                      <td
                                        className="px-2"
                                        colSpan="2">
                                        <label className="hover:cursor-pointer">{cliente.name}</label>
                                      </td>
                                    </tr>
                                  ) : null
                                }
                              })
                            : null}
                          </tbody>
                        ) : (
                          <tbody className="text-md">
                          {Object.keys(respMaquinas).map((cliente) =>
                            Object.keys(respMaquinas[cliente]).map((chaveMaquina) => (
                              <tr
                                key={chaveMaquina} // Usando a chave da máquina como chave de lista
                                className="h-12 border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight hover:cursor-pointer"
                                onClick={() => {
                                  setTogTabEvid(true)
                                  handleClick(respMaquinas[cliente][chaveMaquina], chaveMaquina)
                                }}>
                                <th className="text-start px-2">
                                  <button
                                    className="ml-12 font-semibold"
                                    type="button">
                                    {respMaquinas[cliente][chaveMaquina]}
                                  </button>
                                </th>
                                <th />
                                <th />
                              </tr>
                            )),
                          )}
                          </tbody>
                        )}
                      </table>
                    ) : (
                      <div className="h-full flex items-center justify-center">
                        <svg
                          className="animate-spin size-36 text-blue-700"
                          fill="none"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="h-full overflow-auto border rounded-lg border-gray-300 shadow-md ">
                    <div>
                      {mostraMaquinaEditar ? (
                        <div className="">
                          <div className="flex items-center justify-end sticky top pr-16 pt-2 pb-2 border-b-2 border-black">
                            <div>
                              <p className="font-semibold">
                                {nomesClientesSelecionados} - {nomeDaMaquina}
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-center mt-8 ">
                            <div className="flex flex-col w-4/6">
                              <div>
                                <div className="font-semibold">
                                  <label htmlFor="large">Descrição:</label>
                                </div>

                                <input
                                  className="p-2 w-full bg-neutral-50 border rounded-md "
                                  id="large"
                                  type="text"
                                  value={comentario}
                                  onChange={(event) => handleComentChange(event.target.value)}
                                />

                                {images.map((image, index) => (
                                  <div
                                    key={index}
                                    className="mb-3 mt-8 shadow-md shadow-gray-100">
                                    <div className="flex flex-col mt-1 border border-gray-400 rounded-md p-3 bg-white">
                                      <input
                                        accept="image/*"
                                        className="bg-blue-100 mx-4 rounded-br-md rounded-tr-md cursor-pointer"
                                        type="file"
                                        onChange={(event) => handleImageChange(event, index)}
                                      />
                                      <input
                                        className="mt-3 rounded-md border h-16 border-gray-300 pl-6"
                                        placeholder="Comentário da imagem"
                                        style={{ wordWrap: 'break-word' }}
                                        type="text"
                                        value={ImagesComents[index]?.comment || ''}
                                        onChange={(event) => handleComentarioChange(event, index)}
                                      />
                                      <div className="flex justify-end mt-3">
                                        <button
                                          className="p-1.5 tracking-tight font-medium rounded-lg duration-300 bg-rose-600 text-white shadow-md shadow-red-200 hover:bg-rose-700 hover:shadow-md"
                                          type="button"
                                          onClick={() => removeRowIp(index)}>
                                          Remover
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <div className="flex items-center justify-end mt-3 mb-8 p-3 ">
                                <button
                                  className="p-1.5 tracking-tight font-medium rounded-lg duration-300 bg-blue-900 text-white shadow-md shadow-blue-200 hover:bg-blue-950 hover:shadow-md"
                                  type="button"
                                  onClick={addRowIp}>
                                  Adicionar imagem
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {togTabEvid === false ? (
              <div className="row-start-12 flex items-center justify-between">
                {togTenantMaq ? (
                  <button
                    className="ml-16 p-2 flex bg-rose-600 shadow-md shadow-rose-200 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-rose-700"
                    type="submit"
                    onClick={() => {
                      if (clientesSelecionados.length === 0) {
                        toast.error('Selecione um cliente')
                      } else {
                        setTogTenantMaq(false)
                        setTogTabEvid(false)
                      }
                    }}>
                    Voltar
                  </button>
                ) : (
                  <>
                    {isAdmin ? (
                        <button
                          className="ml-16 p-2 shadow-md shadow-blue-200 flex bg-blue-900 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                          type="submit"
                          onClick={() => {
                            if (clientesSelecionados.length === 0) {
                              toast.error('Selecione um cliente')
                            } else {
                              maquinasRelatorio()
                              setTogHistEvid(true)
                            }
                          }}>
                          Histórico
                        </button>
                      ) : // Exibe o botão com base nas permissões do usuário
                      permissoes?.tenants?.some((userPermission) => userPermission.tenant === clienteSelecionado && userPermission.permissions?.evidences?.view) ? (
                        <button
                          className="ml-16 p-2 shadow-md shadow-blue-200 flex bg-blue-900 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                          type="submit"
                          onClick={() => {
                            if (clientesSelecionados.length === 0) {
                              toast.error('Selecione um cliente')
                            } else {
                              maquinasRelatorio()
                              setTogHistEvid(true)
                            }
                          }}>
                          Histórico
                        </button>
                      ) : (
                        <div />
                      )}
                  </>
                )}
                {!togTenantMaq ? (
                  <div>
                    {isAdmin ? (
                        <button
                          className=" mr-16 p-2 flex shadow-md shadow-blue-200 bg-blue-900 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                          type="submit"
                          onClick={() => {
                            if (clientesSelecionados.length === 0) {
                              alert('selecione um cliente')
                            } else {
                              maquinasRelatorio()
                            }
                          }}>
                          Evidênciar
                        </button>
                      ) : // Exibe o botão com base nas permissões do usuário
                      permissoes.tenants.some((userPermission) => userPermission.tenant === clienteSelecionado && userPermission.permissions?.evidences?.create) ? (
                        <button
                          className=" mr-16 p-2 flex shadow-md shadow-blue-200 bg-blue-900 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                          type="submit"
                          onClick={() => {
                            if (clientesSelecionados.length === 0) {
                              alert('selecione um cliente')
                            } else {
                              maquinasRelatorio()
                            }
                          }}>
                          Evidênciar
                        </button>
                      ) : null}
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="row-start-12 flex items-center justify-between">
                {/* Botão Voltar */}
                <button
                  className="ml-16 p-2 shadow-md shadow-rose-200 flex bg-rose-600 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-rose-700"
                  type="button" // Altere de "submit" para "button" se não estiver enviando um formulário
                  onClick={() => {
                    setTogTabEvid(false)
                    setNomeDaMaquina(undefined)
                    setIdDaMaquina(undefined)
                    setMostraMaquinaEditar(false)
                  }}>
                  Voltar
                </button>

                <button
                  className="mr-16 p-2 flex shadow-md shadow-blue-200 bg-blue-900 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950"
                  type="button"
                  onClick={evidenciar}>
                  Evidenciar
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="h-[calc(90%-40px)] grid grid-rows-12">
            <div className="row-span-10  size-full ">
              <div className="rounded-lg bg-white h-full ">
                <div className="h-full overflow-auto border rounded-lg border-gray-300 shadow-md ">
                  <div className="table-auto w-full">
                    <div className="border-b-2 border-black h-10 -top-0 z-20 sticky bg-white flex items-center justify-end ">
                      <div className="flex items-center justify-end sticky top pr-16">
                        <p className="font-semibold">Histórico - {nomesClientesSelecionados}</p>
                      </div>
                    </div>

                    {togAcordeon === false ? (
                      <div className="text-md  ">
                        {Object.keys(respMaquinas).map((cliente) =>
                          Object.keys(respMaquinas[cliente]).map((chaveMaquina) => (
                            <div
                              key={chaveMaquina} // Usando a chave da máquina como chave de lista
                              className="h-12 w-full flex items-center border even:bg-neutral-50 hover:bg-neutral-50 duration-300 tracking-tight hover:cursor-pointer"
                              onClick={() => {
                                handleClick(respMaquinas[cliente][chaveMaquina], chaveMaquina)
                                setIdMaq(chaveMaquina)
                                setNumeroDaPagina(1)
                                setTogTabEvid(true)
                                setBtnBotton(true)
                              }}>
                              <div className="w-full">
                                <button
                                  className="ml-12 font-semibold"
                                  type="button">
                                  {respMaquinas[cliente][chaveMaquina]}
                                </button>
                              </div>
                            </div>
                          )),
                        )}
                      </div>
                    ) : (
                      <div>
                        {resultHist.length === 1 && resultHist[0].length === 0 ? (
                          <div className="flex items-center justify-center mt-12 font-semibold text-xl">Não há evidências</div>
                        ) : (
                          <div>
                            {dataInicial
                              ? resultHistData.map((objeto, index) => (
                                <div key={index}>
                                  <div className="flex items-center justify-center mt-3 mb-3">
                                    <DatePicker
                                      className="rounded-xl border h-10 pl-2 focus:outline-none focus:border-2 focus:border-blue-700"
                                      dateFormat="dd/MM/yyyy"
                                      locale={pt.code}
                                      placeholderText="Pesquisa por data"
                                      selected={dataInicial}
                                      onChange={(date) => setDataInicial(date)}
                                      onSelect={() => {}}
                                    />
                                  </div>

                                  <div>
                                    {Array.isArray(resultHistData) && resultHistData.length > 0 && Array.isArray(resultHistData[0]) && resultHistData[0].length === 0 ? (
                                      <div className="flex items-center justify-center mt-12 font-semibold text-xl">Não há evidencia para essa data</div>
                                    ) : (
                                      <Accordion
                                        allowMultipleExpanded={false}
                                        allowZeroExpanded={true}>
                                        {Object.keys(objeto).map(
                                          (chave, index) =>
                                            objeto[chave].created_at === dataFormatada && (
                                              <AccordionItem key={index}>
                                                <AccordionItemHeading className="font-semibold hover:cursor-pointer hover:bg-blue-100">
                                                  <AccordionItemButton>{`${objeto[chave].created_at.replace(/-/g, '/')} ${objeto[chave].hour}`}</AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel className="grid gap-5 p-5">
                                                  <div className="flex gap-5">
                                                    <p className="font-thin">
                                                      <span className="font-medium">Criado por: </span>
                                                      {objeto[chave].user_email}
                                                    </p>
                                                    <p className="font-thin">
                                                      <span className="font-medium">Data: </span>
                                                      {`${objeto[chave].created_at.replace(/-/g, '/')} ${objeto[chave].hour}`}
                                                    </p>
                                                  </div>
                                                  <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                                                    <p className="font-medium">Motivo:</p>
                                                    <p className="font-thin">{chave}</p>
                                                  </div>
                                                  <div className="grid grid-cols-5">
                                                    {objeto[chave].image_comment.map((image, imageIndex) => (
                                                      <div
                                                        key={imageIndex}
                                                        className="">
                                                        {image.image !== null ? (
                                                          <div className="bg-gray-100 rounded-lg border border-gray-200 p-3 size-fit">
                                                            {image.image ? (
                                                              <img
                                                                alt="Imagem"
                                                                className="cursor-pointer size-52 rounded-lg"
                                                                src={`data:image/jpeg;base64,${image.image}`}
                                                                onClick={() => {
                                                                  const imageUrl = `data:image/jpeg;base64,${image.image}`
                                                                  setImagemDownload(imageUrl)
                                                                  openFullscreen(image)
                                                                  setBtnBottonDowload(true)
                                                                }}
                                                              />
                                                            ) : null}
                                                            {fullscreenImage ? (
                                                              <div
                                                                className="fixed z-30 top-0 left-0 w-full h-full bg-black bg-opacity-90 flex items-center justify-center"
                                                                onClick={closeFullscreen}>
                                                                <img
                                                                  alt="Imagem"
                                                                  className="size-fit"
                                                                  src={`data:image/jpeg;base64,${fullscreenImage.image}`}
                                                                />
                                                              </div>
                                                            ) : null}

                                                            {image.comment !== null ? <p className="font-medium p-3">{image.comment}</p> : null}
                                                            <div>
                                                              <a
                                                                className="p-1 flex justify-around border border-blue-900 bg-blue-900 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950 hover:text-white"
                                                                download="imagem"
                                                                href={`data:image/jpeg;base64,${image.image}`}>
                                                                Baixar imagem <ArrowDownTrayIcon className="size-6" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    ))}
                                                  </div>
                                                </AccordionItemPanel>
                                              </AccordionItem>
                                            ),
                                        )}
                                      </Accordion>
                                    )}
                                  </div>
                                </div>
                              ))
                              : // Renderização padrão se nenhuma data estiver selecionada
                              resultHist.map((objeto, index) => (
                                <div key={index}>
                                  <div className="flex items-center justify-center mt-3 mb-3 pl-4">
                                    <DatePicker
                                      className="rounded-xl border h-10 pl-2 focus:outline-none focus:border-2 focus:border-blue-700"
                                      dateFormat="dd/MM/yyyy"
                                      locale={pt.code}
                                      placeholderText="Pesquisa por datas"
                                      selected={dataInicial}
                                      onChange={(date) => setDataInicial(date)}
                                      onSelect={() => {}}
                                    />
                                  </div>

                                  <Accordion
                                    allowMultipleExpanded={false}
                                    allowZeroExpanded={true}>
                                    {Object.keys(objeto).map((chave, index) => (
                                      <AccordionItem
                                        key={index}
                                        allowMultipleExpanded={false}
                                        allowZeroExpanded={true}>
                                        <AccordionItemHeading className="font-semibold hover:cursor-pointer hover:bg-blue-100">
                                          <AccordionItemButton>{`${objeto[chave].created_at.replace(/-/g, '/')} ${objeto[chave].hour}`}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="grid gap-5 p-5">
                                          <div className="flex gap-5">
                                            <p className="font-thin">
                                              <span className="font-medium">Criado por: </span>
                                              {objeto[chave].user_email}
                                            </p>
                                            <p className="font-thin">
                                              <span className="font-medium">Data: </span>
                                              {`${objeto[chave].created_at.replace(/-/g, '/')} ${objeto[chave].hour}`}
                                            </p>
                                          </div>
                                          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                                            <p className="font-medium">Motivo:</p>
                                            <p className="font-thin">{chave}</p>
                                          </div>
                                          <div className="grid grid-cols-5">
                                            {objeto[chave].image_comment.map((image, imageIndex) => (
                                              <div
                                                key={imageIndex}
                                                className="">
                                                {image.image !== null ? (
                                                  <div className="bg-gray-100 rounded-lg border border-gray-200 p-3 size-fit">
                                                    {image.image ? (
                                                      <img
                                                        alt="Imagem"
                                                        className="cursor-pointer size-52 rounded-lg"
                                                        src={`data:image/jpeg;base64,${image.image}`}
                                                        onClick={() => {
                                                          const imageUrl = `data:image/jpeg;base64,${image.image}`
                                                          setImagemDownload(imageUrl)
                                                          openFullscreen(image)
                                                          setBtnBottonDowload(true)
                                                        }}
                                                      />
                                                    ) : null}
                                                    {fullscreenImage ? (
                                                      <div
                                                        className="fixed z-30 top-0 left-0 w-full h-full bg-black bg-opacity-90 flex items-center justify-center"
                                                        onClick={closeFullscreen}>
                                                        <img
                                                          alt="Imagem"
                                                          className="size-fit"
                                                          src={`data:image/jpeg;base64,${fullscreenImage.image}`}
                                                        />
                                                      </div>
                                                    ) : null}

                                                    {image.comment !== null ? <p className="font-medium p-3">{image.comment}</p> : null}
                                                    <div>
                                                      <a
                                                        className="p-1 flex justify-around border border-blue-900 bg-blue-900 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-blue-950 hover:text-white"
                                                        download="imagem"
                                                        href={`data:image/jpeg;base64,${image.image}`}>
                                                        Baixar imagem <ArrowDownTrayIcon className="size-6" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                            ))}
                                          </div>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    ))}
                                  </Accordion>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {btnBottonDowload === false ? (
              btnBotton === false ? (
                <div className="row-start-12 flex items-center justify-between">
                  <button
                    className="ml-16 p-2 flex bg-rose-600 shadow-md shadow-rose-200 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-rose-700"
                    type="button"
                    onClick={() => {
                      setTogTabEvid(false)
                      setBtnBotton(false)
                      setResultHist([])
                      setTogHistEvid(false)
                      setRespMaquinas([])
                      setTogTenantMaq(false)
                    }}>
                    Voltar
                  </button>
                </div>
              ) : (
                <div className="row-start-12 flex items-center justify-between">
                  <button
                    className="ml-16 p-2 shadow-md shadow-rose-200 flex bg-rose-600 text-white tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-rose-700"
                    type="button"
                    onClick={() => {
                      // navigate('/evidences')
                      setClientesSelecionados([])
                      setNomesClientesSelecionados('')
                      setMostraMaquinaEditar(false)
                      setBtnEdit(false)
                      setTogHistEvid(false)
                      setTogTenantMaq(false)
                      setNomeDaMaquina('')
                      setIdDaMaquina('')
                      setImages([])
                      setImagesComents([])
                      setComentario('')
                      setRespMaquinas([])
                      setResultHist([])
                      setIdMaq('')
                      setNumeroDaPagina(0)
                      setResultadoPaginas('')
                      setTogAcordeon(false)
                      setDataInicial()
                      setDataFormatada('')
                      setResultHistData([])
                      setTogTabEvid(false)
                      setBtnBotton(false)
                    }}>
                    Voltar
                  </button>

                  <div className="flex  sm:justify-center">
                    {!dataInicial ? (
                      <nav className="">
                        <ul className="inline-flex items-center space-x-4">
                          <li>
                            <ReactPaginate
                              activeClassName="bg-cyan-50 text-cyan-600"
                              activeLinkClassName="hover:bg-cyan-100 hover:text-cyan-700 bg-blue-100"
                              breakLabel="..."
                              containerClassName="pagination flex flex-row items-center"
                              marginPagesDisplayed={2}
                              nextLabel={<ChevronDoubleRightIcon className="text-blue-700 size-8 border rounded-tr-md rounded-br-md hover:bg-blue-700 hover:text-white" />}
                              pageCount={total_pages}
                              pageLinkClassName="border border-gray-300 bg-white px-3 py-1 leading-tight text-gray-700"
                              pageRangeDisplayed={5}
                              previousLabel={<ChevronDoubleLeftIcon className="text-blue-700 size-8 border rounded-tl-md rounded-bl-md hover:bg-blue-700 hover:text-white" />}
                              onPageChange={handlePageClick}
                            />
                          </li>
                        </ul>
                      </nav>
                    ) : null}
                  </div>
                </div>
              )
            ) : (
              <div className="row-start-12 z-40 flex items-center justify-between">
                <button
                  className="ml-16 p-2 flex border border-rose-600 text-white bg-rose-600 tracking-tight font-medium rounded-lg px-3 duration-300 hover:bg-rose-700 hover:text-white "
                  type="button"
                  onClick={() => {
                    setBtnBottonDowload(false)
                    closeFullscreen()
                  }}>
                  Fechar
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}
export default Evidences
