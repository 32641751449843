import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const PrivateRoutes = () => {
    const [cookies] = useCookies(['user'])
    return cookies.user ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateRoutes
