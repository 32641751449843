import Layout from '/src/components/Layouts/Layout.jsx';
import Tab from '/src/components/Tabs/Tab.jsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import AxiosConfigs from '../configs.js';
import Modal from 'react-modal';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid/index.js';
import { ClipboardDocumentCheckIcon, CloudArrowUpIcon, DocumentIcon, EyeIcon, EyeSlashIcon, PhotoIcon, TableCellsIcon } from '@heroicons/react/24/outline/index.js';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid/index.js';
import * as CryptoJS from 'crypto-js';
import { useDebounce } from 'use-debounce';
import PermissoesContext from '../context/PermisoesContext.jsx';
import { useCookies } from 'react-cookie';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  LinearProgress,
  Paper,
  Divider,
  InputBase,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

/**
 * @var postData se refere ao id do cliente
 * @returns {React.JSX.Element}
 */

const Vault = () => {
  const { permissoes } = useContext(PermissoesContext);
  const [cookies] = useCookies('is_admin');
  const isAdmin = cookies.is_admin;
  const [clientToCheckPermissions, setClientToCheckPermissions] = useState('');
  const [clients, setClients] = useState([]);
  const [sublist, setSublist] = useState([]);
  const [items, setItems] = useState([]);
  const [hidden, setHidden] = useState(true);
  const [hiddenResults, setHiddenResults] = useState(true);
  const [emptyResults, setEmptyResults] = useState(true);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [debouncedValue] = useDebounce(search, 800);
  const [hiddenItems, setHiddenItems] = useState(true);
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [pwdLength, setPwdLength] = useState(6)
  const [progress, setProgress] = useState(0);
  const [uploadRate, setUploadRate] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [filesSize, setFilesSize] = useState(0);
  const tabRef = useRef(null);
  const [passwordVisibility, setPasswordVisibility] = useState([]);
  const [, setLoadingClients] = useState(false);
  const [splitOpen, setSplitOpen] = useState(false);
  const formRef = useRef(null);

  const togglePasswordVisibility = (index) => {
    setPasswordVisibility((prevVisibility) => {
      const newVisibility = [...prevVisibility];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };
  const [itemData, setItemData] = useState({
    name: '',
    // credentials: [],
    credentials: [{ user: '', password: '' }],
    ips: [''],
    itemType: null,
    sublist_id: 1,
    internal_port: '',
    external_port: '',
    password: '',
    info: '',
    last_inspection_date: dayjs(new Date()),
    send_email: false,
    emails: [''],
    files: [],
    newFiles: [],
    removedFiles: [],
  });

  const resetData = async () => {
    setItemData((prevData) => ({
      ...prevData,
      id: null,
      name: '',
      credentials: [{ user: '', password: '' }],
      ips: [''],
      itemType: '',
      // sublist_id:    1,
      internal_port: '',
      external_port: '',
      password: '',
      info: '',
      files: [],
      newFiles: [],
      removedFiles: [],
      emails: [''],
      send_email: false,
    }));

    // setItemData({
    //     id: null,
    //     name: '',
    //     credentials: [{ user: '', password: '' }],
    //     ips: [''],
    //     itemType: '',
    //     sublist_id: 1,
    //     internal_port: '',
    //     external_port: '',
    //     password: '',
    //     info: '',
    //     files: [],
    //     newFiles: [],
    //     removedFiles: []
    // })
    setFilesSize(0);
  };

  const serversTypes = {
    1: 'Linux',
    2: 'Windows',
    3: 'VMWare',
    4: 'HYPER-V',
    5: 'Proxmox',
    6: 'Mac',
    7: 'Firewall',
  };
  const networkTypes = {
    8: 'Switch',
    9: 'Storage',
    10: 'Roteadores',
    11: 'Impressoras',
    12: 'IP Publico',
  };

  const eletricalTypes = {
    13: 'No-Break',
    14: 'Estabilizador',
    15: 'Gerador',
    16: 'Transformador',
    17: 'Quadro de Distribuição',
  };

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      border: 'none',
      width: '70%',
      height: '90%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  const downloadSheet = (itemType) => {
    const alertMessage = (
      <div className="text-yellow-400">
        <p className="text-center">⚠️</p>
        <p>Atenção ao preenchimento da planilha</p>
        <ol className="list-disc">
          <li>
            <p>Separar o campo IPs por virgula </p>
          </li>
          <li>
            <p>Separar o campo informações por virgula</p>
          </li>
          <li>
            <p>Não alterar o nome das colunas.</p>
          </li>
          <li>
            <p>Não alterar o nome da planilha.</p>
          </li>
        </ol>
      </div>
    );

    AxiosConfigs.get(`/sheets?type=${itemType}`, {
      responseType: 'blob',
    }).then((r) => {
      const url = window.URL.createObjectURL(new Blob([r.data], { type: r.headers['Content-Type'] }));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'planilha-modelo.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast(alertMessage, {
        id: 'sheetAlert',
        duration: 5000,
        style: {
          backgroundColor: 'lightyellow',
          color: 'black',
          borderRadius: '5px',
          padding: '16px',
          display: 'block',
          border: '1px solid yellow',
        },
      });
    });
  };

  const searchItem = () => {
    if (!postData) {
      // toast.error('Selecione um cliente.')
    } else if (debouncedValue !== '') {
      setIsLoading(true);
      AxiosConfigs.get(`/items?client=${postData}&name=${debouncedValue}`).then((response) => {
        setSearchResults(response.data.data);
        setHiddenItems(false);
        setSearch('');
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    searchItem();
    const handleClickOutside = (event) => {
      if (tabRef.current && !tabRef.current.contains(event.target)) {
        setHiddenItems(true);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [debouncedValue]);

  const formData = () => {
    const formData = new FormData();
    if (itemData.id) {
      Array.from(itemData.newFiles).forEach((file) => {
        formData.append('newFiles[]', file);
      });
    } else {
      Array.from(itemData.files).forEach((file) => {
        formData.append('files[]', file);
      });
    }

    formData.append('name', itemData.name);
    itemData.itemType && formData.append('itemType', itemData.itemType);
    formData.append('sublist_id', itemData.sublist_id);
    formData.append('info', itemData.info);
    formData.append('client_id', postData);

    itemData.internal_port !== '' && formData.append('internal_port', itemData.internal_port);
    itemData.external_port !== '' && formData.append('external_port', itemData.external_port);
    itemData.password !== '' && formData.append('password', CryptoJS.AES.encrypt(itemData.password, import.meta.env.VITE_ENC_KEY, { mode: CryptoJS.mode.CBC }).toString());
    itemData.last_inspection_date && formData.append('last_inspection_date', dayjs(itemData.last_inspection_date).format('YYYY-MM-DD'));
    itemData.send_email && formData.append('send_email', itemData.send_email);
    itemData.emails[0] !== '' && formData.append('emails', JSON.stringify(itemData.emails));

    if (itemData.credentials[0] && itemData.credentials[0].user !== '') formData.append('credentials', JSON.stringify(itemData.credentials));
    if (itemData.ips[0]) formData.append('ips', JSON.stringify(itemData.ips));

    // itemData.credentials[0].user !== '' && formData.append('credentials', JSON.stringify(itemData.credentials))
    // itemData.ips[0] !== '' && formData.append('ips', JSON.stringify(itemData.ips))
    if (itemData.removedFiles.length >= 1) formData.append('removedFiles', JSON.stringify(itemData.removedFiles));

    return formData;
  };

  const decryptData = async (item) => {
    if (item.credentials[0] !== '') {
      for (const [index, creds] of Object.entries(item.credentials)) {
        let enc = atob(creds.password);
        enc = JSON.parse(enc);
        let iv = CryptoJS.enc.Base64.parse(enc.iv);
        let val = enc.value;
        let key = CryptoJS.enc.Base64.parse(import.meta.env.VITE_ENC_KEY);
        let dec = await CryptoJS.AES.decrypt(val, key, { iv: iv });
        dec = dec.toString(CryptoJS.enc.Utf8);
        item.credentials[index].password = dec;
      }
    }
    if (item.password !== '') {
      const key = CryptoJS.enc.Base64.parse(import.meta.env.VITE_ENC_KEY);
      item.password = await CryptoJS.AES.decrypt(item.password, key).toString(CryptoJS.enc.Utf8);
    }

    return item;
  };

  // const pwdGen = () => {
  //     const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?'
  //     let pwd = ''
  //     for (let i = 0; i < pwdLength; i++) {
  //         const randomIndex = Math.floor(Math.random() * chars.length)
  //         pwd += chars[randomIndex]
  //     }
  //
  //     setItemData({ ...itemData, password: pwd })
  // }

  const loadSpinner = (color, size) => (
    <div>
      <svg className={`animate-spin ${size} ${color}`} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
        <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="currentColor" />
      </svg>
    </div>
  );

  const itemDataCredentials = (index) => (
    <div key={index} className="w-full flex gap-4 h-fit">
      <div className="h-full my-auto">
        <IconButton
          color="error"
          id={`remove_${index}`}
          type="button"
          onClick={() => {
            if (itemData.credentials.length === 1) {
              return;
            }
            const updatedCredentials = itemData.credentials.filter((_, i) => i !== index);
            setItemData({ ...itemData, credentials: updatedCredentials });
          }}
        >
          <XMarkIcon className="size-6" />
        </IconButton>
      </div>
      <div className="w-1/2">
        <TextField
          className="w-full"
          id={`user_${index}`}
          label="Usuário"
          size="small"
          value={itemData.credentials[index].user}
          onInput={(e) => {
            const updatedCredentials = itemData.credentials.map((cred, i) => (i === index ? { ...cred, user: e.target.value } : cred));
            setItemData({ ...itemData, credentials: updatedCredentials });
          }}
        />
      </div>
      <div className="w-1/2">
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          variant="outlined"
        >
          <InputBase
            // className="rounded-lg border border-blue-900 p-2 w-full focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-none"
            id={`password_${index}`}
            placeholder="Senha"
            required={true}
            size="small"
            type={passwordVisibility[index] ? 'text' : 'password'}
            value={itemData.credentials[index].password}
            sx={{ ml: 1, flex: 1 }}
            onInput={(e) => {
              const updatedCredentials = itemData.credentials.map((cred, i) =>
                i === index
                  ? {
                      ...cred,
                      password: e.target.value,
                    }
                  : cred
              );
              setItemData({ ...itemData, credentials: updatedCredentials });
            }}
          />
          <IconButton color="success" title="Mostrar/Ocultar senha" type="button" onClick={() => togglePasswordVisibility(index)}>
            {passwordVisibility[index] ? <EyeSlashIcon className="size-6" /> : <EyeIcon className="size-6" />}
          </IconButton>
          <Divider
            orientation="vertical"
            sx={{
              height: 28,
              my: 'auto',
            }}
          />
          <IconButton
            color="primary"
            title="Copiar senha"
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(itemData.credentials[index].password).then(() => toast.success('Senha copiada para a area de transferência'));
            }}
          >
            <ClipboardDocumentCheckIcon className="size-6" />
          </IconButton>
        </Paper>
      </div>
    </div>
  );

  const itemDataEmails = (index) => (
    <div key={index} className="w-full flex gap-4 h-fit">
      <div className="h-full my-auto">
        <IconButton
          color="error"
          id={`remove_email_${index}`}
          type="button"
          onClick={() => {
            if (itemData.emails.length === 1) {
              return;
            }
            const updatedEmails = itemData.emails.filter((_, i) => i !== index);
            setItemData({ ...itemData, emails: updatedEmails });
          }}
        >
          <XMarkIcon className="size-6" />
        </IconButton>
      </div>
      <div className="w-full">
        <TextField
          className="w-full"
          id={`email_${index}`}
          label="Email"
          size="small"
          value={itemData.emails[index]}
          variant="outlined"
          onInput={(e) => {
            const updatedEmails = itemData.emails.map((email, i) => (i === index ? e.target.value : email));
            setItemData({ ...itemData, emails: updatedEmails });
          }}
        />
      </div>
    </div>
  );

  const itemDataIps = (index) => (
    <div key={index} className="w-full flex gap-4 h-fit">
      <div className="h-full my-auto">
        <IconButton
          color="error"
          // className="p-2 bg-rose-600 shadow-md shadow-rose-500 text-white rounded-lg"
          id={`remove_ip_${index}`}
          type="button"
          onClick={() => {
            if (itemData.ips.length === 1) {
              return;
            }
            const updatedIps = itemData.ips.filter((_, i) => i !== index);
            setItemData({ ...itemData, ips: updatedIps });
          }}
        >
          <XMarkIcon className="size-6" />
        </IconButton>
      </div>
      <div className="w-full">
        <TextField
          key={index}
          className="w-full"
          id={`ip_${index}`}
          label="IP"
          size="small"
          value={itemData.ips[index]}
          variant="outlined"
          onInput={(e) => {
            const updatedIps = itemData.ips.map((ip, i) => (i === index ? e.target.value.replace(/[^0-9.]+/g, '') : ip));
            setItemData({ ...itemData, ips: updatedIps });
          }}
        />
      </div>
    </div>
  );

  const createItem = async () => {
    await AxiosConfigs.postForm('/items', formData(), {
      onUploadProgress: (progressEvent) => {
        setIsUploading(progressEvent.upload);
        !isNaN(progressEvent.rate) ? setUploadRate(parseFloat((progressEvent.rate / 1024 / 1000).toFixed(2))) : null;
        !isNaN(progressEvent.progress) ? setProgress(Math.round(progressEvent.progress * 100)) : null;
      },
    })
      .then((res) => {
        if (res.status === 201) {
          toast.success('Item criado com sucesso!');
          setOpenModal(!openModal);
          setRefresh(!refresh);
          resetData().then(() => {
            getItems(res.data.sublist_id);
          });
        } else {
          toast.error('Erro ao criar item');
        }
      })
      .catch((err) => {
        err.response.data.forEach((error) => {
          toast.error(error);
        });
      })
      .finally(() => {
        setIsUploading(false);
        setProgress(0);
        setUploadRate(0);
      });
  };

  const updateItem = async (id) => {
    const form = formData();
    form.append('_method', 'PUT');
    await AxiosConfigs.post(`/items/${id}`, form, {
      onUploadProgress: (progressEvent) => {
        setIsUploading(progressEvent.upload);
        !isNaN(progressEvent.rate) ? setUploadRate(parseFloat((progressEvent.rate / 1024 / 1000).toFixed(2))) : null;
        !isNaN(progressEvent.progress) ? setProgress(Math.round(progressEvent.progress * 100)) : null;
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success('Item atualizado com sucesso!');
          setOpenModal(!openModal);
          setRefresh(!refresh);
          getItems(res.data.data.sublist_id);
          resetData();
          setProgress(0);
          setUploadRate(0);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const downloadFile = (fileName) => {
    AxiosConfigs.post(
      `/items/file`,
      {
        id: itemData.id,
        file: fileName,
      },
      {
        responseType: 'blob',
      }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getSublist = () => {
    setHidden(false);
    setLoading(true);

    AxiosConfigs.get(`/sublists`)
      .then((res) => {
        setSublist(res.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };

  const getItem = (id) => {
    setLoading(true);
    AxiosConfigs.get(`/items/${id}`)
      .then((res) => {
        decryptData(res.data.data).then(() => {
          setItemData({ ...itemData, ...res.data.data });
          let size = res.data.data.files.reduce((acc, currentValue) => acc + currentValue.size, 0);
          setFilesSize(size / 1024 / 1024);
          setOpenModal(!openModal);
          setLoading(false);
        });
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  const getItems = (id) => {
    setItems([]);
    resetData().then(() => {
      setLoading(true);

      AxiosConfigs.get(`/items/${postData}/${id}`)
        .then((res) => {
          if (res.data.data.length !== 0) {
            setItems(res.data.data);
            setHiddenResults(false);
            setEmptyResults(false);
          } else {
            setHiddenResults(false);
            setEmptyResults(true);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
          setItemData({
            ...itemData,
            id: null,
            name: '',
            credentials: [{ user: '', password: '' }],
            ips: [''],
            itemType: '',
            sublist_id: parseInt(id),
            internal_port: '',
            external_port: '',
            password: '',
            info: '',
            files: [],
            newFiles: [],
            removedFiles: [],
          });

          // setItemData({ ...itemData, sublist_id: parseInt(id) })
        });
    });
  };

  const getClients = () => {
    setLoadingClients(true);
    AxiosConfigs.get('/clients')
      .then((response) => {
        if (response.status === 200) {
          setClients(response.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.statusText, {
          id: err.response.status.toString(),
        });
      })
      .finally(() => setLoadingClients(false));
  };

  useEffect(() => {
    getClients();
    if (postData) {
      getSublist();
      setSearch('');
    }
  }, [postData, refresh]);

  Modal.setAppElement('#root');

  return (
    <Layout active="clients">
      {/*{loadingClients ? (*/}
      {/*    <div className="size-full grid place-content-center place-items-center">{loadSpinner('text-blue-700', 'size-20')}</div>*/}
      {/*) : (*/}
      <div className="flex h-full border-blue-500 tracking-tighter duration-300 transition-all" id="main">
        <Tab
          key="clients"
          items={clients}
          loading={loading}
          refresh={setRefresh}
          request={getSublist}
          requestData={setPostData}
          reset={setHiddenResults}
          setClientToCheckPermissions={setClientToCheckPermissions}
          setLoad={setLoading}
          title="Clientes"
          type="clients"
        />
        {!hidden ? (
          <Tab key="sublist" items={sublist} refresh={setRefresh} request={getItems} requestData={postData} reset={setHiddenResults} title="Categorias" type="sublist" />
        ) : null}
        <div className="size-full flex flex-col gap-5 p-6">
          <div className="flex gap-5">
            <div className="relative w-6/12 my-auto">
              <div className="absolute top-0 end-7 py-3.5">
                <MagnifyingGlassIcon className="size-6 text-neutral-400" />
              </div>
              <div className={`absolute top-0 end-14 py-3 ${isLoading ? '' : 'hidden'}`}>{loadSpinner('text-blue-500', 'size-6')}</div>
              <input
                className="p-3 border border-blue-500/50 shadow-md rounded-lg w-full focus:outline-none focus:border-1 focus:border-blue-900 focus:ring-0 disabled:opacity-50"
                disabled={!postData}
                placeholder="Pesquisar items"
                type="search"
                onInput={(e) => {
                  if (e.target.value.length >= 2) {
                    setSearch(e.target.value);
                  }
                }}
              />
              <div
                ref={tabRef}
                className={`absolute w-full h-96 mt-2 rounded-lg shadow-lg bg-white overflow-auto py-2 duration-200 transition-opacity ${
                  hiddenItems ? 'opacity-0 hidden' : 'opacity-1'
                }`}
              >
                {searchResults.map((item) => (
                  <div key={item.id} className="w-full p-3 hover:bg-blue-50 hover:cursor-pointer even:border-y even:border-blue-200" onClick={() => getItem(item.id)}>
                    <b className="font-medium">{item.name}</b> {'->'} {item.sublist}
                  </div>
                ))}
              </div>
            </div>

            {isAdmin ||
            (permissoes.clients?.some((userpermissions) => userpermissions.client === clientToCheckPermissions.id) &&
              permissoes.clients.find((userpermissions) => userpermissions.client === clientToCheckPermissions.id).permissions.create === true) ? (
              <div className="w-6/12 relative">
                <div className="flex w-full">
                  <button
                    className={`${!isAdmin ? 'w-full rounded-lg' : 'w-10/12'} font-medium h-14 bg-[#35208f] text-lg tracking-tight text-white ${
                      !splitOpen ? (isAdmin ? 'rounded-r-none rounded-lg' : 'rounded-tl-lg') : 'rounded-tl-lg'
                    } hover:shadow-lg hover:shadow-blue-900/30 shadow-lg disabled:bg-[#35208f]/50 disabled:shadow-none duration-300`}
                    disabled={!postData}
                    type="button"
                    onClick={() => {
                      setOpenModal(!openModal);
                    }}
                  >
                    Adicionar
                  </button>
                  <button
                    className={`border-l ${
                      splitOpen ? 'rounded-tr-lg' : 'rounded-l-none rounded-lg'
                    } border-blue-800/90 bg-[#35208f] w-2/12 hover:shadow-lg hover:shadow-blue-900/30 disabled:bg-[#35208f]/50 shadow-lg disabled:shadow-none duration-300`}
                    disabled={!postData}
                    hidden={!isAdmin}
                    type="button"
                    onClick={() => setSplitOpen(!splitOpen)}
                  >
                    <TableCellsIcon className="size-6 mx-auto text-white" />
                  </button>
                </div>
                <div className={`absolute w-full duration-300 transition-opacity ${!splitOpen ? 'opacity-0 invisible' : 'opacity-1'}`} hidden={!isAdmin}>
                  <div className="flex rounded-lg">
                    <button
                      className={`w-2/4 border-y border-blue-800/90 ${
                        splitOpen ? 'rounded-bl-lg' : 'rounded-r-none rounded-lg'
                      } h-12 bg-[#35208f] text-lg text-white hover:shadow-lg hover:shadow-blue-900/30 shadow-lg disabled:bg-[#35208f]/50 duration-300`}
                      disabled={!postData}
                      type="button"
                      onClick={() => {
                        downloadSheet(3);
                      }}
                    >
                      Servidores
                    </button>
                    <button
                      className={`w-2/4 border border-blue-800/90 ${
                        splitOpen ? 'rounded-br-lg' : 'rounded-r-none rounded-lg'
                      } h-12 bg-[#35208f] text-lg text-white hover:shadow-lg hover:shadow-blue-900/30 shadow-lg disabled:bg-[#35208f]/50 duration-300`}
                      disabled={!postData}
                      type="button"
                      onClick={() => {
                        downloadSheet(4);
                      }}
                    >
                      Redes
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {!hiddenResults ? (
            emptyResults ? (
              <div className="h-[calc(100vh-8rem)] flex flex-col place-items-center place-content-center">
                <div className="block">
                  <div className="text-4xl">🙁</div>
                </div>
                <p className="font-bold text-lg text-center">Nenhuma informação cadastrada</p>
              </div>
            ) : (
              <div className="h-full overflow-auto">
                <div className="overflow-auto px-2">
                  {items.map((item, idx) => (
                    <div
                      key={`item${idx}`}
                      className="border-x odd:bg-blue-50 px-2 py-0.5 cursor-pointer hover:bg-blue-100 duration-300 odd:border-y last:border-b flex justify-between"
                      title={item.name}
                      onClick={() => {
                        getItem(item.id);
                        setIsUploading(false);
                        setProgress(0);
                        decryptData(item).then((data) => {
                          setItemData({ ...itemData, ...data });
                          setOpenModal(!openModal);
                          let size = data.files.reduce((acc, currentValue) => acc + currentValue.size, 0);
                          setFilesSize(size / 1024 / 1024);
                        });
                      }}
                    >
                      <p className="font-medium">{item.name}</p>
                      <p className="font-medium">{item.ips[0]}</p>
                    </div>
                  ))}
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
      {/*)}*/}
      <Modal
        isOpen={openModal}
        shouldCloseOnOverlayClick={true}
        style={modalStyles}
        onRequestClose={() => {
          setOpenModal(!openModal);
          resetData().then();
          // setPwdLength(6)
        }}
      >
        <div className="grid content-between size-full">
          <div className="flex justify-between h-fit p-2">
            <div className="my-auto">
              <p className="font-medium text-lg">{itemData.id ? 'Atualizar' : 'Adicionar'} item</p>
            </div>
            <div>
              <IconButton
                color="error"
                title="Fechar modal"
                onClick={() => {
                  resetData().then(() => {
                    setOpenModal(!openModal);
                  });
                }}
              >
                <XMarkIcon className="size-6" />
              </IconButton>
            </div>
          </div>
          <form
            ref={formRef}
            className="h-[39rem] overflow-auto"
            onSubmit={() => {
              itemData.id ? updateItem(itemData.id).then() : createItem().then();
            }}
          >
            <div className="flex p-2">
              <div className="w-6/12 grid gap-2 px-3">
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="itemCategory">Categoria</InputLabel>
                  <Select
                    id="itemCategory"
                    label="Categoria"
                    value={itemData.sublist_id}
                    onChange={(e) => {
                      setItemData({
                        ...itemData,
                        sublist_id: parseInt(e.target.value),
                      });
                    }}
                  >
                    {sublist.map((item, index) => {
                      return (
                        <MenuItem key={index} value={parseInt(item.id)}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <div className="flex gap-4">
                  <div className={itemData.sublist_id !== 1 ? 'w-6/12' : 'w-full'}>
                    <TextField
                      className="w-full"
                      id="itemName"
                      label="Nome"
                      required={true}
                      size="small"
                      value={itemData.name}
                      variant="outlined"
                      onInput={(e) => {
                        setItemData({ ...itemData, name: e.target.value });
                      }}
                    />
                  </div>
                  <div className={itemData.sublist_id === 5 ? 'w-6/12' : 'hidden'}>
                    <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs}>
                      {/*<label*/}
                      {/*    className="font-medium"*/}
                      {/*    htmlFor="lastInspectionDate">*/}
                      {/*    Data ultima inspeção*/}
                      {/*</label>*/}
                      <DatePicker
                        className="w-full"
                        disableFuture={true}
                        dayOfWeekFormatter={(weekday) => `${weekday.format('ddd')}`}
                        id="lastInspectionDate"
                        label="Data ultima inspeção"
                        showDaysOutsideCurrentMonth={true}
                        slotProps={{ textField: { size: 'small' } }}
                        value={dayjs(itemData.last_inspection_date)}
                        onChange={(date) => {
                          setItemData({ ...itemData, last_inspection_date: date });
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className={!(itemData.sublist_id === 4 || itemData.sublist_id === 3) ? 'hidden' : 'w-6/12 flex gap-2'}>
                    <div className={itemData.sublist_id === 4 ? 'w-full' : 'w-6/12'}>
                      <TextField
                        className="w-full"
                        id="internalPort"
                        label="Porta interna"
                        required={true}
                        size="small"
                        value={itemData.internal_port}
                        onInput={(e) => {
                          setItemData({
                            ...itemData,
                            internal_port: e.target.value.replace(/[^0-9]+/g, ''),
                          });
                        }}
                      />
                    </div>
                    <div className={itemData.sublist_id === 3 ? 'w-6/12' : 'hidden'}>
                      <TextField
                        id="externalPort"
                        label="Porta externa"
                        size="small"
                        value={itemData.external_port}
                        onInput={(e) => {
                          setItemData({
                            ...itemData,
                            external_port: e.target.value.replace(/[^0-9]+/g, ''),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full" hidden={!(itemData.sublist_id === 3 || itemData.sublist_id === 4 || itemData.sublist_id === 5)}>
                  <FormControl fullWidth required={true} size="small">
                    <InputLabel htmlFor="itemType">Tipo</InputLabel>
                    <Select
                      id="itemType"
                      label="Tipo"
                      value={itemData.itemType}
                      onChange={(e) => {
                        setItemData({ ...itemData, itemType: e.target.value });
                      }}
                    >
                      {itemData.sublist_id === 3 &&
                        Object.keys(serversTypes).map((key) => (
                          <MenuItem key={key} value={key}>
                            {serversTypes[key]}
                          </MenuItem>
                        ))}
                      {itemData.sublist_id === 4 &&
                        Object.keys(networkTypes).map((key) => (
                          <MenuItem key={key} value={key}>
                            {networkTypes[key]}
                          </MenuItem>
                        ))}
                      {itemData.sublist_id === 5 &&
                        Object.keys(eletricalTypes).map((key) => (
                          <MenuItem key={key} value={key}>
                            {eletricalTypes[key]}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="w-full">
                  <TextField
                    multiline
                    className="w-full"
                    id="itemInfo"
                    label="Observações"
                    maxRows={4}
                    minRows={4}
                    required={true}
                    rows={4}
                    value={itemData.info}
                    onInput={(e) => {
                      setItemData({
                        ...itemData,
                        info: e.target.value.length > 500 ? e.target.value.slice(0, 500) : e.target.value,
                      }); //limita o tamanho do input em 500 caracteres
                    }}
                  />
                </div>
                <Typography className="w-full grid grid-flow-col items-end" variant="caption">
                  <span className="w-fit">
                    Arquivos <em className="text-xs">- Tamanho máximo de 20MB</em>
                  </span>
                  <span className="justify-self-end">
                    <small className={`text-xs ${filesSize > 20 ? 'text-rose-600' : ''}`}>{filesSize.toFixed(1)} MB</small>
                  </span>
                </Typography>
                <div className="w-full h-48 rounded-lg border border-gray-300 p-2 flex gap-3">
                  <div className="relative h-full size-1/2">
                    <div className="rounded-lg h-full bg-blue-100 border-2 border-blue-900 border-dashed p-2 text-blue-900">
                      <label className="block size-full place-content-center font-medium" htmlFor="itemFile">
                        <CloudArrowUpIcon className="size-8 mx-auto" />
                        <p className="text-center tracking-tight">Clique ou arraste arquivos para adicionar</p>
                      </label>
                    </div>
                    <input
                      className="absolute size-full top-0 border border-red-500 opacity-0"
                      id="itemFile"
                      multiple={true}
                      type="file"
                      value={[]}
                      onInput={(e) => {
                        const newFiles = Array.from(e.target.files);
                        let updatedFiles, size;

                        if (itemData.id) {
                          updatedFiles = [...itemData.newFiles, ...newFiles];
                          size = [...itemData.files, ...updatedFiles].reduce((acc, cur) => acc + cur.size, 0);
                          setItemData((prevData) => ({
                            ...prevData,
                            newFiles: updatedFiles,
                          }));
                        } else {
                          updatedFiles = [...itemData.files, ...newFiles];
                          size = updatedFiles.reduce((acc, cur) => acc + cur.size, 0);
                          setItemData((prevData) => ({ ...prevData, files: updatedFiles }));
                        }
                        setFilesSize(size / 1024 / 1024);
                      }}
                    />
                  </div>
                  <div className="fileList size-full overflow-auto">
                    <ul className="grid gap-3 px-2">
                      {itemData.files
                        ? Array.from(itemData.id ? [...itemData.files, ...itemData.newFiles].filter((value, index, array) => array.indexOf(value) === index) : itemData.files).map(
                            (value, index) => {
                              return (
                                <li key={index} className="flex font-medium place-items-center place-content-center gap-2 border border-blue-500 rounded-lg p-3 tracking-tighter">
                                  <div className="size-full flex gap-2 items-center">
                                    {value.type === 'image/jpeg' || value.type === 'image/png' ? <PhotoIcon className="size-6" /> : <DocumentIcon className="size-6" />}
                                    <a className="cursor-pointer" onClick={() => downloadFile(value.name)}>
                                      {value.name}
                                    </a>
                                  </div>
                                  <button
                                    className="p-1 h-8 rounded-lg bg-rose-600 shadow-md shadow-rose-500 text-white hover:bg-rose-700 duration-300"
                                    title="Remover arquivo"
                                    type="button"
                                    onClick={() => {
                                      let size;
                                      if (itemData.id) {
                                        const removed = [...itemData.removedFiles, itemData.newFiles[index].name || itemData.files[index].name];
                                        const updatedFiles = Array.from(itemData.files).filter((_, i) => i !== index);
                                        size = updatedFiles.reduce((acc, cur) => acc + cur.size, 0);
                                        setItemData({
                                          ...itemData,
                                          removedFiles: removed,
                                          files: updatedFiles,
                                        });
                                        setFilesSize(size / 1024 / 1024);
                                      } else {
                                        const updatedFiles = Array.from(itemData.files).filter((_, i) => i !== index);
                                        size = updatedFiles.reduce((acc, cur) => acc + cur.size, 0);
                                        setItemData({
                                          ...itemData,
                                          files: updatedFiles,
                                        });
                                        setFilesSize(size / 1024 / 1024);
                                      }
                                    }}
                                  >
                                    <XMarkIcon className="size-5" />
                                  </button>
                                </li>
                              );
                            }
                          )
                        : null}
                    </ul>
                  </div>
                </div>
                <div hidden={!(itemData.sublist_id === 5)}>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={itemData.send_email === true}
                        onChange={(e) =>
                          setItemData({
                            ...itemData,
                            send_email: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Enviar notificações via e-mail"
                  />
                </div>
              </div>
              <div className={!(itemData.sublist_id === 5 && itemData.send_email === true) ? 'hidden' : 'w-6/12 grid place-items-between h-1/2'}>
                <div>
                  <p className="font-medium">Emails</p>
                  <div className="h-48 overflow-auto p-5 space-y-2">
                    {itemData.emails.map((value, index) => {
                      return itemDataEmails(index);
                    })}
                  </div>
                  <div className="w-full self-end p-2">
                    <Button
                      className="size-full"
                      type="button"
                      variant="outlined"
                      onClick={() => {
                        const updatedEmails = [...itemData.emails, ''];
                        setItemData({ ...itemData, emails: updatedEmails });
                      }}
                    >
                      <PlusIcon className="size-6 mx-auto" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className={!(itemData.sublist_id === 4 || itemData.sublist_id === 3) ? 'hidden' : 'w-6/12 grid place-items-between h-1/2'}>
                <div>
                  <p className="font-medium">Usuários e senhas</p>
                  <div className="h-48 overflow-auto p-5 space-y-2">
                    {itemData.credentials.map((value, index) => {
                      return itemDataCredentials(index);
                    })}
                  </div>
                  <div className="w-full self-end p-2">
                    <Button
                      className="size-full"
                      type="button"
                      variant="outlined"
                      onClick={() => {
                        const updatedCredentials = [
                          ...itemData.credentials,
                          {
                            user: '',
                            password: '',
                          },
                        ];
                        setItemData({ ...itemData, credentials: updatedCredentials });
                        setPasswordVisibility([...passwordVisibility, false]); // Ensure new passwords start hidden
                      }}
                    >
                      <PlusIcon className="size-6 mx-auto" />
                    </Button>
                  </div>
                </div>
                <div className={!(itemData.sublist_id === 4 || itemData.sublist_id === 3) ? 'hidden' : null}>
                  <p className="font-medium">IPs</p>
                  <div className="h-48 overflow-auto p-5 space-y-2">
                    {itemData.ips.map((value, index) => {
                      return itemDataIps(index);
                    })}
                  </div>
                  <div className="w-full self-end p-2">
                    <Button
                      className="size-full bg-[#35208f] text-white rounded-lg p-2 h-10"
                      type="button"
                      variant="outlined"
                      onClick={() => {
                        const updatedIps = [...itemData.ips, ['']];
                        setItemData({ ...itemData, ips: updatedIps });
                      }}
                    >
                      <PlusIcon className="size-6 mx-auto" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="h-fit">
            <div className="relative rounded-lg group">
              <Button
                className="w-full"
                color="success"
                disabled={filesSize > 20}
                size="large"
                type="submit"
                variant="contained"
                onClick={() => {
                  formRef.current.dispatchEvent(
                    new Event('submit', {
                      cancelable: true,
                      bubbles: true,
                    })
                  );
                }}
              >
                {itemData.id ? 'Atualizar' : 'Adicionar'}
                <small className={`text-xs ${isUploading ? '' : 'hidden'}`}>&nbsp;&nbsp;{uploadRate}Mbps</small>
              </Button>
              <div hidden={!isUploading}>
                <LinearProgress className="absolute" color="success" hidden={filesSize > 20} max={100} value={progress > 1 ? progress : null} variant="determinate" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Vault;
