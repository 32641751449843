import React, { createContext, useState } from 'react'

export const InventoryClient         = createContext({})
export const InventoryClientProvider = ({ children }) => {
  const [inventoryClient, setInventoryClient] = useState({})

  return (
    <InventoryClient.Provider value={{ inventoryClient, setInventoryClient }}>
      {children}
    </InventoryClient.Provider>
  )
}
