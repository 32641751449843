import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
// import AxiosConfig from '../config.js'
//
// AxiosConfigs

ReactDOM.createRoot(document.getElementById('root')).render(
    // <StrictMode>
    <App />
    // </StrictMode>
)
